import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty, uniqBy } from 'lodash';
import {
  Typography,
  IconButton,
  Grid,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  green,
  navy,
  orange,
  bgGray,
  tiffany,
  orangeAccent,
} from '../../css/style';
import {
  IMG_STORAGE_TALENT_LINK,
  IMG_STORAGE_LINK,
  VET_TYPES,
} from '../../data/Constants';
import { getTimeCategory } from '../../utils/Functions';
import PracticeAutocomplete from './PracticeAutocomplete';
import SingleSelect from './SingleSelect';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: 1,
    top: 1,
    color: theme && theme.palette.grey[500],
  },
  tag: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
  },
  blockedTag: {
    width: '12px',
    height: '12px',
    borderRadius: '2px',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tagContainer: {
    fontFamily: 'Asap',
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: 24,
    padding: '8px 0px',
  },
  submitButtonContainer: {
    width: '30%',
    maxWidth: '100px',
  },
  shiftCalendarTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleTextDiv: {
    display: 'flex',
    alignItems: 'center',

    '& .MuiTypography-root': {
      fontSize: '25px',
      fontWeight: '700',
      color: navy,
      padding: '0px 8px',

      [theme && theme.breakpoints.down('md')]: {
        fontSize: '18px',
        fontWeight: '500',
      },
    },
  },
  shiftCalendarTitle: {
    color: navy,
    padding: '0px 8px',
    fontSize: '20px',
    fontWeight: '700',
  },
  calendarTitle: {
    color: navy,
    fontSize: '16px',
    fontWeight: '600',
  },
  shiftCalendarToolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px',

    '& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
      padding: '3px',
    },

    '& .MuiOutlinedInput-input': {
      padding: '9.5px',
    },

    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  shiftCalendarMonthBtn: {
    border: `1px solid ${tiffany}`,
    borderRadius: '50%',
    cursor: 'pointer!important',
    backgroundColor: 'white',
    boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
    justifyContent: 'center',
    alignItems: 'center',
    color: navy,
    display: 'flex',
    width: '20px',
    height: '20px',
    marginRight: '8px',
  },
  shiftInfo: {
    fontFamily: 'ASAP',
    fontSize: 12,
    color: 'white',
  },
  fullCalendarContainer: {
    '& .fc-header-toolbar': {
      display: 'none',
    },
    '& .fc-col-header-cell-cushion': {
      fontFamily: 'ASAP',
    },
    '& .fc-daygrid-day-frame': {
      fontFamily: 'ASAP',
    },
    '& .fc-daygrid-day-top': {
      fontFamily: 'ASAP',
      fontSize: '12px',
    },
    '& .fc .fc-toolbar.fc-header-toolbar': {
      color: navy,
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: '2',
    },
    '& .fc-toolbar-chunk': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .fc .fc-toolbar-title': {
      color: navy,
      fontSize: '20px',
      fontWeight: '700',
    },
    '& .fc .fc-button-primary': {
      backgroundColor: 'white',
      color: navy,
      borderColor: tiffany,
      borderRadius: '50%',
      padding: '4px',
      fontSize: '10px',
      fontWeight: '700',

      '&:hover': {
        backgroundColor: 'white',
        color: tiffany,
      },
      '&:disabled': {
        backgroundColor: 'white',
        color: tiffany,
      },
    },
    '& .fc td, .fc th': {
      minWidth: '156px',
    },
    '& .fc .fc-daygrid-day-number': {
      fontSize: '20px',
      color: navy,

      [theme && theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
    '& .fc .fc-daygrid-day': {
      '&:selected': {
        backgroundColor: tiffany,
      },
    },

    '& .fc .fc-daygrid-day.fc-day-today': {
      backgroundColor: bgGray,
      boxShadow: '0px 0px 0px 3px #A6B0BF inset',
      border: '1px solid #DCE0E5',
      fontWeight: '700',
    },
    '& .fc-daygrid-dot-event': {
      padding: 0,
      borderRadius: '20px',
    },
    '& .fc-daygrid-day-events': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:focus': {
        backgroundColor: 'white',
      },
    },
  },
  error: {
    color: 'red',
    paddingTop: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerDogImage: {
    height: '189px',
    zIndex: 9999,
    marginBottom: '54px',
    // [theme && theme.breakpoints.down('md')]: {
    //   height: '150px',
    //   marginBottom: '-37px',
    // },
    // [theme && theme.breakpoints.down('sm')]: {
    //   height: '110px',
    //   marginBottom: '-30px',
    // },
  },
  headerToolbarContainer: {
    backgroundColor: bgGray,
    padding: '12px 20px',
    display: 'flex',
    alignItems: 'center',
    height: '48px',
  },
}));

const sxStyles = {
  filterGridContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  filterGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '16px',
  },
  boxIcon: {
    border: `1px solid ${tiffany}`,
    width: '16px',
    height: '16px',
    borderRadius: '2px',
    marginLeft: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  eventDiv: {
    width: '100%',
    textAlign: 'center',
    padding: '2px 6px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    cursor: 'pointer',
  },
};

const PracticeCalendar = (props) => {
  const {
    shiftCalendarItems = [],
    handleEventSelect = () => {},
    isMobile,
    dateClickCallback = () => {},
    setSideDrawerOpen = () => {},
    sideDrawerOpen = false,
    requestedDates = {},
    selectedDate,
  } = props;
  const [events, setEvents] = useState([]);
  const [monthTitle, setMonthTitle] = useState('');
  const [selected, setSelected] = useState({});
  const [initialDate, setInitialDate] = useState(() => {
    if (selectedDate) {
      return new Date(selectedDate);
    } else {
      const sortedDateList = avaiDates?.length
        ? avaiDates.sort((date1, date2) => date1 - date2)
        : [new Date()];
      return sortedDateList[0];
    }
  });

  const classes = useStyles();
  const fullCalendarRef = useRef(null);

  const countComparingStatusRows = (date, jobPostingId, status) => {
    let countMultiple = 0;
    const shiftCalendarItem = shiftCalendarItems.find(item => item.id === jobPostingId);
    if (shiftCalendarItem) {
      shiftCalendarItem?.jobPostingCalendar.forEach((item) => {
        if (item.status === status && item.date === date) {
          countMultiple += 1;
        }
      });
    }
    return countMultiple;
  };

  useEffect(() => {
    handleEvents();
  }, [shiftCalendarItems, requestedDates, selectedDate]);

  const handleEvents = () => {
    let events = [];
    let avaiDates = [];

    // Count rows status with the same date and same job posting id in shiftCalendarItems
    shiftCalendarItems.forEach((item) => {
      const {
        startTime,
        endTime,
        title,
        practiceId,
      } = item;

      if (!isEmpty(item.jobPostingCalendar)) {
        events = events.concat(
          item.jobPostingCalendar.map((jobPostingCalendarRow) => {
            const {
              id,
              date,
              status,
              talent_user_id,
              job_posting_id,
            } = jobPostingCalendarRow;

            const st = startTime
              ? moment(`${date} ${startTime}`, 'YYYY-MM-DD h:mm(A)')
              : date;
            let et = endTime
              ? moment(`${date} ${endTime}`, 'YYYY-MM-DD h:mm(A)')
              : null;
            let endTimeStr;

            if (et && et < st) {
              et = moment(`${date} 11:59(PM)`, 'YYYY-MM-DD h:mm(A)');
              endTimeStr = moment(`${date} ${endTime}`, 'YYYY-MM-DD h:mm(A)');
            }
            if (status === 'available') {
              avaiDates.push(new Date(date));
            }

            const isRequested = !isEmpty(requestedDates[job_posting_id]) && requestedDates[job_posting_id].includes(date);
            const requestedCountOnDate = isRequested ? requestedDates[job_posting_id].filter(d => d === date).length : 0;

            return {
              start: new Date(st),
              startStr: st,
              end: et ? new Date(et) : null,
              backgroundColor: status === 'taken' ? navy : (status === 'available' && !isRequested) ? green : orangeAccent,
              textColor: 'white',
              jobPostingCalendarId: id,
              practiceId,
              status,
              isRequested,
              requestedCountOnDate,
              talentUserId: status === 'taken' ? talent_user_id : null,
              shiftDate: date,
              jobId: job_posting_id,
              endTimeStr,
              itemKey: `${job_posting_id}_${id}`,
              countMultiple: countComparingStatusRows(
                date,
                job_posting_id,
                status,
              ),
              jobDateStatus: `${job_posting_id}-${date}-${status}`,
            };
          }),
        );
      }
    });
    events = uniqBy(events, 'jobDateStatus');

    setEvents(events);
    if (selectedDate) {
      setInitialDate(new Date(selectedDate));
    } else {
      const sortedDateList = avaiDates?.length
        ? avaiDates.sort((date1, date2) => date1 - date2)
        : [new Date()];
      setInitialDate(sortedDateList[0]);
    }
  };

  const getFilters = () => {
    const {
      calendarTalentType,
      activeSortedAdminPractices,
      calendarPractice,
      isMobile,
      onPracticeChange = () => {},
      onTalentTypeChange = () => {},
    } = props;

    return (
      <Grid container sx={sxStyles.filterGridContainer}>
        <Grid item xs={12} md={6} sx={sxStyles.filterGrid}>
          {activeSortedAdminPractices && (
            <PracticeAutocomplete
              handleStateChange={val => onPracticeChange(val)}
              title='All Practices'
              items={activeSortedAdminPractices}
              value={calendarPractice}
              isSingle
              defaultValue={activeSortedAdminPractices[0]}
              form
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} sx={sxStyles.filterGrid}>
          <SingleSelect
            value={calendarTalentType}
            title='Category'
            items={VET_TYPES}
            fullItems
            onChange={val => onTalentTypeChange(val)}
            filter
          />
        </Grid>
      </Grid>
    );
  };

  const renderFullCalendar = () => (
    <div
      style={{ minWidth: 'unset' }}
      className={classes.fullCalendarContainer}
    >
      <FullCalendar
        ref={fullCalendarRef}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        initialDate={initialDate}
        weekends
        events={events}
        eventContent={eventInfo => renderEventContent(eventInfo)}
        eventMinHeight={32}
        contentHeight={700}
        headerToolbar={{ left: '', center: '', right: '' }}
        viewDidMount={(info) => {
          setMonthTitle(info?.view?.title);
        }}
        dateClick={(e) => {
          const date = e?.dateStr || '';
          setSelected(date);
          dateClickCallback(date);
        }}
        eventClick={(e) => {
          const moreEventsPopup = document.getElementsByClassName('fc-popover');
          if (moreEventsPopup && moreEventsPopup[0]) {
            moreEventsPopup[0].style.display = 'none';
          }

          const eventExtenedData = e?.event?._def?.extendedProps || {};
          const shiftDate = eventExtenedData?.shiftDate || '';
          setSelected(shiftDate);
          dateClickCallback(shiftDate);
        }}
        eventLimit
        views={{
          dayGridMonth: {
            dayMaxEventRows: 4,
          },
        }}
        editable={false}
        selectable
        fixedWeekCount={false}
        showNonCurrentDates={false}
        titleFormat={{ year: 'numeric', month: 'short' }}
      />
    </div>
  );

  const renderEventContent = (eventInfo) => {
    const eventData = eventInfo?.event || {};
    const eventDataExtended = eventData.extendedProps || {};
    const isSelected = eventDataExtended?.status === 'available' && selected?.itemKey === eventDataExtended?.itemKey
      && selected?.date === eventDataExtended?.shiftDate;
    const shiftCat = eventData.start && eventData.end ? getTimeCategory(moment(eventData.start)) : null;

    return (
      <div
        style={{
          ...sxStyles.eventDiv,
          backgroundColor: eventData.backgroundColor || 'black',
          border: `1px solid ${eventData.backgroundColor || 'black'
          }`,
          borderRadius: eventDataExtended.status === 'taken' ? '0px' : isMobile ? '10px' : '20px',
          justifyContent: !eventData.end || isMobile ? 'center' : 'space-between',
        }}
      >
        <div>
          <span className={classes.shiftInfo}>
            {!eventData.end
              ? 'Shift'
              : `${moment(eventData.start).format('h:mm a')}`.replace(':00', '')}
          </span>
          {eventData.end && <span className={classes.shiftInfo}> - </span>}
          {eventData.end && (
            <span className={classes.shiftInfo}>
              {`${moment(eventDataExtended.endTimeStr || eventData.end).format('h:mm a')}`.replace(':00', '')}
            </span>
          )}
        </div>

        <div>
          {eventDataExtended.countMultiple > 1 && (
            <span className={classes.shiftInfo}>
              &nbsp;
              { eventDataExtended.isRequested ? `(${eventDataExtended.requestedCountOnDate}/${eventDataExtended.countMultiple})` : `(${eventDataExtended.countMultiple})`}
            </span>
          )}
        </div>

        <div>
          {shiftCat && (
            <img
              src={
                shiftCat === 'MORNING'
                  ? `${IMG_STORAGE_TALENT_LINK}shift_morning.svg`
                  : shiftCat === 'AFTERNOON'
                    ? `${IMG_STORAGE_TALENT_LINK}shift_afternoon.svg`
                    : `${IMG_STORAGE_TALENT_LINK}shift_evening.svg`
              }
              style={{ width: '16px', height: '16px' }}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.headerToolbarContainer}>
        <Grid container className={classes.shiftCalendarTitleContainer}>
          <Grid item xs={2} className={classes.shiftCalendarToolbar}>
            <div className={classes.titleTextDiv}>
              <Box className={classes.shiftCalendarMonthBtn}>
                <IconButton
                  onClick={() => {
                    fullCalendarRef?.current?.getApi()?.prev();
                    setMonthTitle(fullCalendarRef?.current?.getApi()?.currentData?.viewTitle);
                  }}
                  size='small'
                >
                  <ChevronLeftIcon style={{ fontSize: '16px' }} />
                </IconButton>
              </Box>
              <Box className={classes.shiftCalendarMonthBtn}>
                <IconButton
                  onClick={() => {
                    fullCalendarRef?.current?.getApi()?.next();
                    setMonthTitle(fullCalendarRef?.current?.getApi()?.currentData?.viewTitle);
                  }}
                  size='small'
                >
                  <ChevronRightIcon style={{ fontSize: '16px' }} />
                </IconButton>
              </Box>
              <Typography className={classes.shiftCalendarTitle}>
                {monthTitle}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={!sideDrawerOpen ? 6 : 7} className={classes.shiftCalendarToolbar}>
            {getFilters()}
          </Grid>
          {!sideDrawerOpen && (
          <Grid item xs={2} className={classes.tagContainer}>
            <img
              src={`${IMG_STORAGE_LINK}image-dog.png`}
              alt='Dog'
              className={classes.headerDogImage}
              style={{ zIndex: 999999, position: 'absolute', top: '24px' }}
            />
          </Grid>
          )}
          <Grid item xs={!sideDrawerOpen ? 2 : 3} className={classes.tagContainer}>
            <div className={classes.item}>
              <div
                className={classes.tag}
                style={{ backgroundColor: green }}
              />
              <span>Available</span>
            </div>
            <div className={classes.item}>
              <div
                className={classes.tag}
                style={{ backgroundColor: orangeAccent }}
              />
              <span>Requested</span>
            </div>
            <div className={classes.item}>
              <div
                className={classes.blockedTag}
                style={{ backgroundColor: navy }}
              />
              <span>Taken</span>
            </div>
            {!sideDrawerOpen && (
            <Box sx={sxStyles.boxIcon}>
              <IconButton
                onClick={(e) => {
                  setSideDrawerOpen();
                  e.preventDefault();
                }}
                size='small'
              >
                <ChevronLeftIcon style={{ fontSize: '16px', color: navy }} />
              </IconButton>
            </Box>
            )}
          </Grid>
        </Grid>
      </div>
      <div>{renderFullCalendar()}</div>
    </div>
  );
};

export default PracticeCalendar;
