import {
  isEmpty,
  isNil,
  orderBy,
} from 'lodash';
import {formatISODateOnly} from './dateUtils';

const DateUtilities = {
  pad(value, length) {
    while (value.length < length) value = `0${value}`;
    return value;
  },

  clone(date) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    );
  },

  toString(date) {
    return `${date.getFullYear()}-${DateUtilities.pad(
      (date.getMonth() + 1).toString(),
      2,
    )}-${DateUtilities.pad(date.getDate().toString(), 2)}`;
  },

  toDayOfMonthString(date) {
    return DateUtilities.pad(date.getDate().toString());
  },

  toMonthAndYearString(date) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return `${months[date.getMonth()]} ${date.getFullYear()}`;
  },

  moveToDayOfWeek(date, dayOfWeek) {
    while (date.getDay() !== dayOfWeek) date.setDate(date.getDate() - 1);
    return date;
  },

  isSameDay(first, second) {
    return (
      first.getUTCFullYear() === second.getUTCFullYear()
      && first.getUTCMonth() === second.getUTCMonth()
      && first.getUTCDate() === second.getUTCDate()
    );
  },

  isSameMonth(first, second) {
    return (first.getUTCFullYear() === second.getUTCFullYear() && first.getUTCMonth() === second.getUTCMonth());
  },

  dateIn(datesArray, date) {
    return (
      datesArray.filter(day => !DateUtilities.isSameDay(day, date)).length !== datesArray.length
    );
  },

  isBefore(first, second) {
    if (this.isSameDay(first, second)) return;
    return first.getTime() < second.getTime();
  },

  isAfter(first, second) {
    return first.getTime() > second.getTime();
  },

  midDayDate(dateOnlyStr) { // param: date only string, eg: 'YYYY-MM-DD'
    return dateOnlyStr ? new Date(`${dateOnlyStr}T12:00:00.000+00:00`) : new Date(`${new Date().toISOString().substring(0, 10)}T12:00:00.000+00:00`);
  },
};

export default DateUtilities;

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const setDataFromMultipleNeeds = (dataObj, status, calendarData, multipleNeedsList, addedCalendarData, unpendingIds) => {
  const needs = dataObj[status];
  const existingRows = calendarData.filter(c => DateUtilities.isSameDay(c.date, dataObj.date) && c.status === status);
  let remainingUnpendingIds = unpendingIds ? [...unpendingIds] : [];

  for (let i = 0; i < needs; i++) {
    multipleNeedsList.push({
      date: dataObj.date,
      status,
    });
  }

  if (needs > existingRows.length) {
    const unpendingKey = status === 'taken' ? 'pendingToTaken' : 'pendingToAvai';
    // Update pending rows with new status 'available' or 'taken'
    if (unpendingIds && unpendingIds.length && dataObj[unpendingKey]) {
      for (let i = 0; i < dataObj[unpendingKey]; i++) {
        const unpendingId = unpendingIds[i];
        const unpendingRow = calendarData.find(c => unpendingId === c.id);
        if (unpendingRow) {
          unpendingRow.status = status; // pending --> taken/available
          remainingUnpendingIds = unpendingIds.filter(id => id !== unpendingId);
        }
      }
    }

    for (let i = 0; i < (needs - existingRows.length - dataObj[unpendingKey]); i++) {
      addedCalendarData.push({
        date: dataObj.date,
        status,
      });
    }
  } else {
    for (let i = 0; i < (existingRows.length - needs); i++) {
      const unavailableRow = calendarData.find(c => existingRows[i].id === c.id);
      if (unavailableRow) {
        unavailableRow.status = 'unavailable';
      }
    }
  }
  return remainingUnpendingIds;
};

export function setBoostSurgeryDetails({
                                         availableDates,
                                         initBoostSurgeryData,
                                         multipleNeeds,
                                         boostDetails,
                                         isChild = false,
                                       }) {
  let boostDataArr = [];
  const singleAvaiDates = availableDates || [];

  !isEmpty(singleAvaiDates) && singleAvaiDates.forEach((date) => {
    let existingData = [];
    if (isEmpty(boostDetails)) {
      existingData = !isEmpty(initBoostSurgeryData)
        && initBoostSurgeryData.find(r => r.status === 'available' && DateUtilities.isSameDay(DateUtilities.midDayDate(r.date), date));
    } else {
      existingData = boostDetails.find(r => DateUtilities.isSameDay(r.date, date));
    }

    if (existingData) {
      boostDataArr.push({
        id: existingData.id,
        date,
        dateText: formatISODateOnly(date),
        isBoosted: isNil(existingData.is_boosted) ? existingData.isBoosted : existingData.is_boosted,
        boostAmount: isNil(existingData.boost_amount) ? existingData.boostAmount : existingData.boost_amount,
        surgeryNeeded: isNil(existingData.surgery_needed) ? existingData.surgeryNeeded : existingData.surgery_needed,
        status: 'available',
      });
    } else {
      boostDataArr.push({
        date,
        dateText: formatISODateOnly(date),
        status: 'available',
        isBoosted: false,
        boostAmount: 0,
        surgeryNeeded: false,
      });
    }
  });

  !isEmpty(multipleNeeds) && Object.keys(multipleNeeds).forEach((dateKey) => {
    if (multipleNeeds[dateKey].available > 0) {
      let existingData = [];
      if (isEmpty(boostDetails)) {
        existingData = !isEmpty(initBoostSurgeryData) && initBoostSurgeryData.find(r => r.status === 'available' && r.date === dateKey);
      } else {
        existingData = boostDetails.find(r => r.dateText === dateKey);
      }
      if (existingData) {
        boostDataArr.push({
          id: existingData.id,
          date: DateUtilities.midDayDate(dateKey),
          dateText: dateKey,
          isBoosted: isNil(existingData.is_boosted) ? existingData.isBoosted : existingData.is_boosted,
          boostAmount: isNil(existingData.boost_amount) ? existingData.boostAmount : existingData.boost_amount,
          surgeryNeeded: isNil(existingData.surgery_needed) ? existingData.surgeryNeeded : existingData.surgery_needed,
          status: 'available',
        });
      } else {
        boostDataArr.push({
          date: DateUtilities.midDayDate(dateKey),
          dateText: dateKey,
          status: 'available',
          isBoosted: false,
          boostAmount: 0,
          surgeryNeeded: false,
        });
      }
    }
  });
  // Sort by date
  boostDataArr = orderBy(boostDataArr, ['date'], ['asc']);
  return boostDataArr;
}

export function setCalendarData({
                                  jobPostingCalendar,
                                  availableDates,
                                  takenDates,
                                  pendingDates,
                                  multipleNeeds,
                                  tempUntakenConfirmedData,
                                  boostDetails,
                                }) {
  const otherStatus = {
    available: 'taken',
    taken: 'available',
  };
  // Set multiple-needs as a list of dates with status, cloning rows as the available/taken numbers in multipleNeeds Obj
  const availableList = availableDates ? availableDates.map((date) => {
    const boostDetailsRow = boostDetails && boostDetails.find(r => DateUtilities.isSameDay(date, r.date));
    return {
      date,
      status: 'available',
      is_boosted: boostDetailsRow?.isBoosted || false,
      boost_amount: boostDetailsRow?.boostAmount || 0,
      surgery_needed: boostDetailsRow?.surgeryNeeded || false,
    };
  }) : [];
  const takenList = takenDates ? takenDates.map(date => ({
    date,
    status: 'taken',
  })) : [];
  const pendingList = pendingDates ? pendingDates.map(date => ({
    date,
    status: 'pending',
  })) : [];

  const untakenList = tempUntakenConfirmedData ? tempUntakenConfirmedData.map(item => ({
    date: item.date,
    status: 'untaken',
    untakenRowId: item.untakenRowId,
  })) : [];

  let calendarData = jobPostingCalendar ? jobPostingCalendar.map(item => ({
    ...item,
    date: DateUtilities.midDayDate(item.date)
  })) : [];

  let singleNeedList = [...untakenList, ...availableList, ...takenList, ...pendingList];

  // Update existing data for single need
  let addedCalendarData = [];
  let toSetUnavailable = [];
  singleNeedList.length && singleNeedList.forEach((dataRow) => {
    if (dataRow.status === 'untaken') {
      const existingTakenDataRow = calendarData.find(item => item.id === dataRow.untakenRowId);
      if (existingTakenDataRow) {
        existingTakenDataRow.status = dataRow.status;
      }
    } else {
      const existingDataRows = calendarData.filter(item => DateUtilities.isSameDay(item.date, dataRow.date));
      if (!existingDataRows.length) {
        addedCalendarData.push(dataRow);
      } else if (existingDataRows.length === 1) { // single --> just change status
        const singleNeedRow = calendarData.find(item => DateUtilities.isSameDay(item.date, dataRow.date));
        if (singleNeedRow) {
          // set extra fields for untaken confirmation
          if (singleNeedRow.status === 'taken' && dataRow.status === 'available') {
            const untakenConfirmRow = tempUntakenConfirmedData.find(c => c.untakenRowId === singleNeedRow.id);
            if (untakenConfirmRow) {
              singleNeedRow.untaken_confirmation_category = untakenConfirmRow.confirmationCategory;
              singleNeedRow.untaken_confirmation = untakenConfirmRow.confirmation;
              singleNeedRow.untaken_talent_user_id = untakenConfirmRow.talentUserId;
            }
          }

          singleNeedRow.status = dataRow.status;
          if (dataRow.status === 'available') {
            singleNeedRow.is_boosted = dataRow.is_boosted;
            singleNeedRow.boost_amount = dataRow.boost_amount;
            singleNeedRow.surgery_needed = dataRow.surgery_needed;
          }
        }
      } else { // multiple turns into single -->  find sufficient row to change status & set unavailable for others
        const singleNeedRow = calendarData.find(item => DateUtilities.isSameDay(item.date, dataRow.date)
          && (item.status === dataRow.status || item.status === otherStatus[dataRow.status] || item.status === 'unavailable'));

        if (singleNeedRow) {
          singleNeedRow.status = dataRow.status;
          // The other rows on the date should be unavailable, b/c it becomes a single need now
          toSetUnavailable = [...toSetUnavailable, ...existingDataRows.filter(item => item.id !== singleNeedRow.id && item.status !== 'untaken')];
        }
      }
    }
  });

  // Set unavailable the multiple-need rows that become a single need
  if (toSetUnavailable.length) {
    calendarData = calendarData.map((c) => {
      if (c.status !== 'untaken' && toSetUnavailable.find(r => r.id === c.id)) return { ...c, status: 'unavailable' };
      return c;
    });
  }

  // Multiple needs setting
  let multipleNeedsList = [];
  for (const dayKey in multipleNeeds) {
    const dataObj = multipleNeeds[dayKey];
    const pending = dataObj.pending || 0;
    if (dataObj.available + dataObj.taken + pending > 1) { // ignore the single need rows
      const existingPendingRows = calendarData.filter(c => DateUtilities.isSameDay(c.date, dataObj.date) && c.status === 'pending');
      let unpendingIds = [];
      // Pending rows are reduced --> get unPending IDs
      if (existingPendingRows.length && pending < existingPendingRows.length) {
        unpendingIds = existingPendingRows.slice(0, existingPendingRows.length - pending).map(c => c.id);
      }
      const remainingUnpendingIds = setDataFromMultipleNeeds(dataObj, 'taken', calendarData, multipleNeedsList, addedCalendarData, unpendingIds);
      setDataFromMultipleNeeds(dataObj, 'available', calendarData, multipleNeedsList, addedCalendarData, remainingUnpendingIds);
      setDataFromMultipleNeeds(dataObj, 'pending', calendarData, multipleNeedsList, addedCalendarData);

      // set boost details
      if (dataObj.available > 0) {
        const boostDetailsRow = boostDetails && boostDetails.find(r => DateUtilities.isSameDay(dataObj.date, r.date));
        if (boostDetailsRow) {
          calendarData = calendarData.map((c) => {
            let row = { ...c };
            if (DateUtilities.isSameDay(c.date, boostDetailsRow.date) && c.status === 'available') {
              row.is_boosted = boostDetailsRow.isBoosted;
              row.boost_amount = boostDetailsRow.boostAmount;
              row.surgery_needed = boostDetailsRow.surgeryNeeded;
            }
            return row;
          });
          addedCalendarData = addedCalendarData.map((c) => {
            let row = { ...c };
            if (DateUtilities.isSameDay(c.date, boostDetailsRow.date) && c.status === 'available') {
              row.is_boosted = boostDetailsRow.isBoosted;
              row.boost_amount = boostDetailsRow.boostAmount;
              row.surgery_needed = boostDetailsRow.surgeryNeeded;
            }
            return row;
          });
        }
      }
    }
  }

  // Set redundant data rows to unavailable
  const needsList = singleNeedList.concat(multipleNeedsList);
  calendarData.forEach((calendarDataRow) => {
    if (calendarDataRow.status !== 'untaken' && !needsList.find(item => DateUtilities.isSameDay(item.date, calendarDataRow.date))) {
      calendarDataRow.status = 'unavailable';
    }
    if (calendarDataRow.status === 'available') {
      calendarDataRow.confirmation_category = null;
      calendarDataRow.confirmation = null;
      calendarDataRow.talent_user_id = null;
    }
  });
  return [...calendarData, ...addedCalendarData];
}
