import { commonResourceStyle } from '../../../css/style';

export const intervalReportStyles = {
  container: commonResourceStyle().greenContainer,
  header: commonResourceStyle().resourceHeader,
  title: commonResourceStyle().resourceNameLarge,
  resourceImage: {
    ...commonResourceStyle().resourceImage,
    right: 150,
  },
  paper: {
    ...commonResourceStyle().paper,
    marginTop: '30px',
  },
  searchContainer: {
    padding: '20px',
    background: 'white',
    borderRadius: '8px 8px 0 0',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
  },
  searchInput: {
    backgroundColor: 'white',
    borderRadius: 4,
    width: '100%',
  },
  tableHead: {
    background: '#4bbdad',
  },
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    padding: '12px 8px',
  },
  expandIconCell: {
    width: '24px',
    padding: '8px',
    color: 'white',
  },
  intervalRow: {
    backgroundColor: '#f1fffd',
    '&:hover': { backgroundColor: '#a6e6dd' },
    cursor: 'pointer',
    height: '70px',
  },
  expandButtonCell: {
    width: '24px',
    padding: '0 8px',
  },
  intervalDataCell: {
    padding: '16px 12px',
  },
  intervalDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
  },
  intervalTitle: {
    fontWeight: 'bold',
    color: '#1a5276',
    fontSize: '1.1rem',
  },
  intervalStats: {
    color: '#555',
    letterSpacing: '0.3px',
  },
  detailsCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  detailRow: {
    '&:hover': { backgroundColor: '#f9f9f9' },
  },
  emptyMessage: {
    color: '#757575',
    padding: '20px',
  },
};
