import moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEqual, uniqBy, isEmpty } from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Paper,
  Typography,
  Grid,
  IconButton, Icon,
  MenuList, MenuItem,
  Popper, Box, Tooltip,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { withStyles } from '@mui/styles';
import DateFormat from '../subcomponents/DateFormat';
import {
  connectionsPaginationCount,
  BASE_URLS,
  IMG_STORAGE_LINK,
  IMG_STORAGE_TALENT_LINK,
} from '../../data/Constants';
import {
  commonResourceStyle,
  navy,
  orangeAccent,
  bgGray,
  green,
  orange,
  errorRed,
  ErCalendarStyles as sxStyles,
} from '../../css/style';
import types from '../../actions/ActionTypes';
import LoadingBar from '../subcomponents/LoadingBar';
import {utils, formatPostTitle, getTimeCategory, getPriorityPointsInfo} from '../../utils/Functions';
import PracticeERCalendar from '../subcomponents/PracticeERCalendar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ERCalendarDrawer from './ERCalendarDrawer';

const styles = commonResourceStyle();


const defaultTalentType = [{
  key: 'dvm-doctor-of-veterinary-medicine',
  title: 'DVM - Doctor of Veterinary Medicine',
}];

const countComparingStatusRows = (date, jobPostingId, status, shiftCalendarItems) => {
  let count = 0;
  const shiftCalendarItem = shiftCalendarItems.find(item => item.id === jobPostingId);
  if (shiftCalendarItem) {
    shiftCalendarItem?.jobPostingCalendar.forEach((item) => {
      if (item.status === status && item.date === date) {
        count += 1;
      }
    });
  }
  return count;
};

class ERCalendar extends Component {
  constructor(props) {
    super(props);
    const filterTalentType = localStorage.getItem('filterTalentType');
    const filterPractice = !isEmpty(localStorage.getItem('filterPractice')) ? JSON.parse(localStorage.getItem('filterPractice')) : null;

    this.state = {
      totalUnreadMessagesCount: 0,
      startPosition: 0,
      calendarPractice: filterPractice || {},
      calendarTalentType: filterTalentType || defaultTalentType[0].key,
      selectedDate: moment().format('YYYY-MM-DD'),
      openSnackbar: false,
      practices: [],
      talents: [],
    };
  }

  componentWillUnmount() {
    const { calendarPractice, calendarTalentType } = this.state;
    localStorage.setItem('filterPractice', JSON.stringify(calendarPractice));
    localStorage.setItem('filterTalentType', calendarTalentType);
  }

  componentDidMount() {
    const {
      actions, apiToken, adminPractices,
    } = this.props;

    if (isEmpty(adminPractices)) {
      actions.getAllResources(apiToken, BASE_URLS.getPractices).then((resp) => {
        const activeSortedAdminPractices = resp.response && resp.response.filter(a => a.active)?.sort((a, b) => (a.activePostingsCount > b.activePostingsCount ? -1 : 1));
        this.setState({ activeSortedAdminPractices }, () => this.loadCalendarData());
      });
    } else {
      const activeSortedAdminPractices = adminPractices && adminPractices.filter(a => a.active)?.sort((a, b) => (a.activePostingsCount > b.activePostingsCount ? -1 : 1));
      this.setState({ activeSortedAdminPractices }, () => this.loadCalendarData());
    }
    let queryParams = `connections?start=0&count=${connectionsPaginationCount}`;
    actions.getAllResources(apiToken, queryParams).then((res) => {
      if (!res.error) {
        const connections = res.response.list;
        const totalUnreadMessagesCount = connections.map(connect => connect.unreadMessagesCount).reduce((a, b) => a + b, 0);
        this.setState({ totalUnreadMessagesCount });
      }
    });
    actions.getAllResources(apiToken, 'connections/statistics');
  }

  // load data for calendar view
  loadCalendarData = () => {
    const {
      actions, apiToken,
    } = this.props;
    const { calendarPractice, calendarTalentType, activeSortedAdminPractices } = this.state;

    const practice = !isEmpty(calendarPractice)
      ? { ...calendarPractice } : !isEmpty(activeSortedAdminPractices)
        ? activeSortedAdminPractices[0] : {};
    const talentType = calendarTalentType || '';

    if (!isEmpty(practice) && talentType) {
      const resourceName = `practices/er-calendar?practiceId=${practice.id}&talentType=${talentType}`;
      actions.getAllResources(apiToken, resourceName).then((resp) => {
        this.setState({ shiftCalendarItems: resp.response.list, calendarPractice: practice, calendarTalentType: talentType });
        localStorage.setItem('filterPractice', '');
        localStorage.setItem('filterTalentType', '');
      });
      this.loadDateTrackingItems(practice.id);
    }
  };

  loadDateTrackingItems = (practiceId) => {
    const {
      actions, apiToken,
    } = this.props;

    if (practiceId) {
      actions.getAllResources(apiToken, `date-request-trackings/practice/${practiceId}`).then((resp) => {
        const dateTrackingItems = resp?.response || [];
        const requestedDates = !isEmpty(dateTrackingItems)
          ? dateTrackingItems.reduce((acc, item) => {
            if (item.status === 'requested') {
              const key = item.job_posting_id;
              acc[key] = acc[key] || [];
              acc[key].push(item.date);
            }
            return acc;
          }, {})
          : {};
        this.setState({ dateTrackingItems, requestedDates });
      });
    }
  };

  renderItem = (item, dateItem, index, dateIndex, isRequested, requestedCountOnDate) => {
    const {
      classes, history,
    } = this.props;
    const {
      selectedDate,
      selectedJobPostingId,
      calendarPractice,
      calendarTalentType,
      requestedDates,
      practices,
      talents
    } = this.state;
    if (practices?.length && !practices.filter(p => p.id === item.practiceId).length) {
      return;
    }
    if (talents?.length && !talents.filter(p => p.id === dateItem.talent_user_id).length) {
      return;
    }
    const jobPostingId = item.parentJobPostingId || item.id;
    const priorityPointsInfo = getPriorityPointsInfo(moment(`${dateItem.date} ${item.startTime}`, 'YYYY-MM-DD h:mm(A)'), item.is_priority_hospital, dateItem.status);
    const itemName = dateItem.confirmation ? dateItem.confirmation.split('_')[0] : dateItem.name;

    return (
      <>
      <div
        key={`shift${index}`}
        onClick={(event) => {
          this.setState({ [`menuAnchor${dateIndex}`]: event.target, selectedJobPostingId: jobPostingId });
        }}
        style={{
          ...sxStyles.eventDiv,
          backgroundColor: isRequested ? orangeAccent : dateItem.status === 'available' ? green : navy,
          border: isRequested ? `1px solid ${orangeAccent}` : dateItem.status === 'available' ? `1px solid ${green}` : `1px solid ${navy}`,
          borderRadius: dateItem.status === 'available' ? '20px' : '0px',
          cursor: 'pointer',
          color: '#fff',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: !isRequested && dateItem.status === 'taken' && dateItem.date === selectedDate ? '0px' : '16px',
        }}
      >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <span className={classes.shiftInfo} key={`${index}_date`}>
            {selectedDate ? moment(selectedDate, 'YYYY-MM-DD').format('ddd, MMM D') : ''}
          </span>
          {dateItem.status === 'available' && item.avaiCount > 1 && (
            <span className={classes.shiftInfo} key={`${index}_count`}>
              {isRequested ? `(${requestedCountOnDate}/${item.avaiCount})` : `(${item.avaiCount})`}
            </span>
          )}
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {priorityPointsInfo?.redAlert && (<NotificationsIcon style={{ width: 16, height: 16, color: orange }}/>) || ''}
            {item.shiftCat && (
              <img
                key={`${index}_icon`}
                src={item.shiftCat === 'MORNING'
                  ? `${IMG_STORAGE_TALENT_LINK}shift_morning.svg`
                  : item.shiftCat === 'AFTERNOON' ? `${IMG_STORAGE_TALENT_LINK}shift_afternoon.svg` : `${IMG_STORAGE_TALENT_LINK}shift_evening.svg`}
                style={{ width: '20px', height: '20px' }}
              />
            )}
          </span>
        </div>
        <Typography key={`${index}_practice`}>{item.practiceName}</Typography>
        <Typography key={`${index}_title`}>{formatPostTitle(item.title)}</Typography>
        {priorityPointsInfo && (<Typography key={`${index}_priority_points`}>Priority Points: {priorityPointsInfo?.points}</Typography>) || ''}
      </div>
      <Popper
        key={`${dateIndex}_popper`}
        open={!!this.state[`menuAnchor${dateIndex}`]}
        anchorEl={this.state[`menuAnchor${dateIndex}`]}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{ zIndex: '2000' }}
      >
        <ClickAwayListener onClickAway={() => this.setState({ [`menuAnchor${dateIndex}`]: null })}>
          <Paper>
          <MenuList
            key={`${index}_popover_menu`}
            aria-labelledby="composition-button"
          >
            <MenuItem
              key={`${index}_app_link`}
              onClick={() => {
                let link = '/applications';
                if (dateItem.status === 'available') {
                  const practiceId = calendarPractice?.id || '';
                  const talentType = calendarTalentType || '';
                  link += `?practices=${practiceId}&talentTypes=${talentType}`;
                }
                if (dateItem.status === 'taken') {
                  link += `?jobpostings=${item.id}`;
                }
                history.push(link);
              }}
            >
              View Applications
            </MenuItem>
            <MenuItem key={`${index}_post_link`} onClick={() => history.push(`/editposition/${selectedJobPostingId}`)}>
              View/Edit Job Post
            </MenuItem>
          </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
      {!isRequested && dateItem.date === selectedDate && dateItem.status === 'taken' && (
        <Box
          sx={{
            margin: '0px 0px 10px 0px',
            backgroundColor: '#fff',
            display: 'flex',
            alignItems: 'center',
            padding: '12px 20px',
            border: `1px solid ${navy}`,
            color: navy,
            justifyContent: 'space-between',
            '& .MuiButtonBase-root': { padding: '0 4px' },
          }}
          key={`${index}_req_div`}
        >
          {dateItem.match_id?(<Tooltip title="Message" placement='top'>
          <IconButton
            onClick={() => history.push(`/messages/${dateItem.match_id}`)}
            key={`req-chat-icon-${index}`}
            disableRipple
          >
            {item.unreadMessagesCount > 0 && (<><Icon sx={sxStyles.msgLensIcon}>lens</Icon></>)}
            <Icon sx={sxStyles.icon}>chat_bubble</Icon>
          </IconButton>
          </Tooltip>):''}
          <Typography key={`${index}_name_confirmed`}>{itemName}</Typography>
        </Box>
      )}
      </>
    );
  };

  handleDateMsgConfirm = async (editedRow, action, item) => {
    const { actions, apiToken, admin } = this.props;
    const {
      dateTrackingItems = [],
      selectedDate,
      calendarPractice,
      calendarTalentType,
      shiftCalendarItems,
    } = this.state;

    if (isEmpty(editedRow)) return;

    if (action === 'approved') { // Approve the shift
      const requestedShiftsRows = dateTrackingItems.filter(row => row.job_posting_id === editedRow.job_posting_id
        && row.status === 'requested'
        && row.date === editedRow.date);
      const totalDeclinedCount = requestedShiftsRows?.length - item?.avaiCount;

      // Check if the date is still available --> assign shift successfully, then we save change into database
      if (item?.avaiCount > 0) {
        // Save job posting calendar, request date tracking
        let takenShift = 0;
        const shiftCalendarItem = shiftCalendarItems.find(r => r.id === editedRow.job_posting_id);
        const jobPostingCalendar = shiftCalendarItem.jobPostingCalendar || [];
        // Save shift
        const calendarData = [...jobPostingCalendar].map((row) => {
          if (takenShift === 0 && row.date === editedRow.date
              && row.job_posting_id === editedRow.job_posting_id
              && row.status === 'available') {
            takenShift += 1;
            return {
              ...row,
              status: 'taken',
              talent_user_id: editedRow.user_id,
              confirmation_category: 'known_taken',
              confirmation: `${editedRow.name} (${editedRow.email})`,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              confirmation_date: new Date(),
            };
          }
          return row;
        });
        let dataRequestTrackingDates = [];

        // If we multiple avaiable shifts --> do not decline
        if (item?.avaiCount > 1) {
          dataRequestTrackingDates = [...dateTrackingItems].map((row) => {
            if (row.id === editedRow.id) {
              return {
                ...row,
                status: 'approved',
                isSentMessage: true,
              };
            }
            return row;
          });
        } else {
          // If we run out of shifts --> decline other requests
          let declineCount = 0;
          dataRequestTrackingDates = [...dateTrackingItems].map((row) => {
            if (row.id === editedRow.id) {
              return {
                ...row,
                status: 'approved',
                isSentMessage: true,
              };
            } else if (totalDeclinedCount > 0 && declineCount < totalDeclinedCount && row.status === 'requested'
              && row.date === editedRow.date && row.job_posting_id === editedRow.job_posting_id) {
              declineCount += 1;
              return {
                ...row,
                status: 'declined',
                isSentMessage: false,
              };
            }
            return row;
          });
        }

        actions.addResource(apiToken, dataRequestTrackingDates, 'date-request-trackings', 'update-trackings').then((resp) => {
          const dateTrackingItems = resp?.response || [];
          const requestedDates = !isEmpty(dateTrackingItems)
            ? dateTrackingItems.reduce((acc, item) => {
              if (item.status === 'requested') {
                const key = item.job_posting_id;
                acc[key] = acc[key] || [];
                acc[key].push(item.date);
              }
              return acc;
            }, {})
            : {};
          actions.updateResource(apiToken, { jobPostingCalendar: calendarData, isMessagePage: true }, 'jobpostings', editedRow.job_posting_id).then(() => {
            actions.addResource(apiToken, { action: 'admin_calendar' }, 'users', 'log-event');
            // Save message
            const date = moment(editedRow.date).format('MM/DD');
            this.saveNewMessage(editedRow, `${admin.name} has confirmed you for ${date}. A summary email will follow at the end of the day.`);

            setTimeout(() => {
              // refresh shift calendar
              const resourceName = `practices/er-calendar?practiceId=${calendarPractice?.id}&talentType=${calendarTalentType}`;
              actions.getAllResources(apiToken, resourceName).then((resp) => {
                this.setState({ shiftCalendarItems: resp?.response?.list || [] });
              });
            }, 2000);
          });

          if (!resp?.response) {
            this.setState({
              errorMessage: resp?.error?.message || 'Something went wrong. Please try again.',
              sucessMessage: '',
              openSnackbar: true,
            });
          } else {
            let sucessMessage = `${editedRow.name} was approved for ${formatPostTitle(item?.title)}.`;
            if (item?.avaiCount === 1 && totalDeclinedCount > 0) {
              sucessMessage = sucessMessage.concat(' All other applicants were declined.');
            }

            this.setState({
              dateTrackingItems: [...dataRequestTrackingDates],
              requestedDates,
              openSnackbar: true,
              sucessMessage,
              errorMessage: '',
            });
          }
        });
      }
    }

    if (action === 'declined' && editedRow.status === 'requested') {
      const data = [...dateTrackingItems].map((row) => {
        if (row.id === editedRow.id) {
          return {
            ...row,
            status: 'declined',
            isSentMessage: true,
          };
        }
        return row;
      });

      // Call API to decline the requested date
      actions.addResource(apiToken, data, 'date-request-trackings', 'update-trackings').then((resp) => {
        const dateTrackingItems = resp?.response || [];
        const requestedDates = !isEmpty(dateTrackingItems)
          ? dateTrackingItems.reduce((acc, item) => {
            if (item.status === 'requested') {
              const key = item.job_posting_id;
              acc[key] = acc[key] || [];
              acc[key].push(item.date);
            }
            return acc;
          }, {})
          : {};

        // Save message
        const date = moment(editedRow.date).format('MM/DD');
        this.saveNewMessage(editedRow, `We have decided to pursue another worker for ${date}.`);

        // refresh shift calendar
        const resourceName = `practices/er-calendar?practiceId=${calendarPractice?.id}&talentType=${calendarTalentType}`;
        actions.getAllResources(apiToken, resourceName).then((resp) => {
          this.setState({ shiftCalendarItems: resp?.response?.list || [], dateTrackingItems: [...data], requestedDates });
        });
      });
    }
  };

  saveNewMessage = (editedRow, newMessage) => {
    const { actions, apiToken, admin } = this.props;

    const payload = {
      body: newMessage,
      match_id: parseInt(editedRow.match_id),
      isUnread: true,
    };
    actions.addResource(apiToken, payload, 'messages').then((res) => {
      actions.addResource(apiToken, { action: 'admin_message' }, 'users', 'log-event');
    });
  };

  handleFilterChange = (fieldName, val) => {
    const { actions, apiToken } = this.props;

    if (!isEmpty(val) && !isEqual(this.state[fieldName], val)) {
      this.setState({ [fieldName]: val }, () => {
        const resourceName = fieldName === 'calendarPractice'
          ? `practices/er-calendar?practiceId=${val.id}&talentType=${this.state.calendarTalentType}`
          : `practices/er-calendar?practiceId=${this.state.calendarPractice.id}&talentType=${val}`;
        actions.getAllResources(apiToken, resourceName).then((resp) => {
          const shiftCalendarItems = resp?.response?.list || [];
          this.setState({ shiftCalendarItems });
        });
      });
      if (fieldName === 'calendarPractice') {
        this.loadDateTrackingItems(val.id);
      }
    }
  };

  render() {
    const {
      classes, actions, loading, apiToken, viewMoreLoading, history, resources, admin, adminPractices, theme, isDesktop,
    } = this.props;
    const { badges } = resources;
    const {
      jobpostings,
      selectedDate,
      shiftCalendarItems,
      sideDrawerOpen,
      selectedJobPostingId,
      calendarPractice,
      calendarTalentType,
      requestedDates,
      dateTrackingItems,
    } = this.state;

    let badgeTitles = {};
    if (badges) {
      badges.forEach(x => badgeTitles[x.id] = x.title);
    }
    let jobPostingList = jobpostings ? [...jobpostings].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
    jobPostingList = uniqBy(jobPostingList, 'id');

    const activeSortedAdminPractices = adminPractices && adminPractices.filter(a => a.active)?.sort((a, b) => (a.activePostingsCount > b.activePostingsCount ? -1 : 1));
    const stylesWithTheme = commonResourceStyle(theme);

    // Filter the shiftCalendarItems by selectedDate
    let filteredShiftItemsByDate = selectedDate && shiftCalendarItems ? shiftCalendarItems.filter(item => item.jobPostingCalendar && item.jobPostingCalendar.find(x => x.date === selectedDate)) : [];
    filteredShiftItemsByDate = filteredShiftItemsByDate.map((item) => {
      const copiedItem = { ...item };
      const avaiCount = copiedItem.jobPostingCalendar && countComparingStatusRows(selectedDate, item.id, 'available', shiftCalendarItems);
      copiedItem.avaiCount = avaiCount;
      const shiftCat = selectedDate && copiedItem.startTime ? getTimeCategory(moment(moment(`${selectedDate} ${copiedItem.startTime}`, 'YYYY-MM-DD h:mm(A)'))) : null;
      copiedItem.shiftCat = shiftCat;
      copiedItem.jobPostingCalendar = copiedItem.jobPostingCalendar.map((jobPostingCalendarRow) => {
        let copiedRow = { ...jobPostingCalendarRow };
        if (copiedRow.status === 'available') {
          copiedRow.uniqueKey = `${copiedRow.job_posting_id}_${copiedRow.date}_${copiedRow.status}`;
        } else {
          copiedRow.uniqueKey = copiedRow.id;
        }
        return copiedRow;
      });
      return copiedItem;
    });
    filteredShiftItemsByDate.forEach((item) => {
      item.jobPostingCalendar = uniqBy(item.jobPostingCalendar, 'uniqueKey');
    });

    const dateText = selectedDate ? moment(selectedDate, 'YYYY-MM-DD').format('ddd, MMM D') : moment().format('ddd, MMM D');


    return (
      (loading === true && viewMoreLoading === 0)
        ? <LoadingBar />
        : (
          <div style={styles.greenContainer} id='postFilter'>
            <div style={sxStyles.mainCalendarOuter}>
              <div
                id='mainDivCalendar'
                style={
                  sideDrawerOpen ? {
                    ...stylesWithTheme.calendarDiv,
                    ...stylesWithTheme.contentShiftRight,
                  } : stylesWithTheme.calendarDiv}
              >
                  <PracticeERCalendar
                    handleEventSelect={event => this.setState({ selectedEvent: event, openDetailsDialog: true })}
                    shiftCalendarPractice={this.state.selectedPractices?.length ? this.state.selectedPractices[0] : {}}
                    shiftCalendarItems={this.state.shiftCalendarItems || []}
                    isMobile={!isDesktop}
                    onPracticeChange={(practices) => this.setState({ practices })}
                    onTalentChange={(talents) => this.setState({ talents })}
                    dateClickCallback={date => this.setState({ sideDrawerOpen: true, selectedDate: date })}
                    sideDrawerOpen={sideDrawerOpen}
                    setSideDrawerOpen={() => {
                      this.setState({ sideDrawerOpen: !sideDrawerOpen });
                      filteredShiftItemsByDate?.forEach((item) => {
                        const dateItems = item.jobPostingCalendar.filter(x => x.date === selectedDate);
                        dateItems.forEach((dateItem, dateIndex) => {
                          this.setState({ [`menuAnchor${dateIndex}`]: null });
                        });
                      });
                    }}
                    activeSortedAdminPractices={activeSortedAdminPractices}
                    calendarPractice={this.state.calendarPractice}
                    calendarTalentType={this.state.calendarTalentType}
                    onTalentTypeChange={(val) => {
                      this.handleFilterChange('calendarTalentType', val);
                    }}
                    requestedDates={this.state.requestedDates || {}}
                    selectedDate={selectedDate}
                  />
              </div>
              {sideDrawerOpen && (
                <ERCalendarDrawer
                  sideDrawerOpen={sideDrawerOpen}
                  classes={classes}
                  sxStyles={sxStyles}
                  selectedDate={selectedDate}
                  dateText={selectedDate ? moment(selectedDate, 'YYYY-MM-DD').format('ddd, MMM D') : moment().format('ddd, MMM D')}
                  filteredShiftItemsByDate={filteredShiftItemsByDate}
                  dateTrackingItems={dateTrackingItems}
                  IMG_STORAGE_LINK={IMG_STORAGE_LINK}
                  history={history}
                  setSideDrawerOpen={() => this.setState({ sideDrawerOpen: false })}
                  renderItem={this.renderItem}
                  handleDateMsgConfirm={this.handleDateMsgConfirm}
                  admin={admin}
                  openSnackbar={this.state.openSnackbar}
                  sucessMessage={this.state.sucessMessage}
                  errorMessage={this.state.errorMessage}
                />
              )}
            </div>
          </div>
        )
    );
  }
}

ERCalendar.propTypes = {
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
};

ERCalendar.defaultProps = {};

function ERCalendarWrapper({ theme, ...rest }) {
  const isDesktop = theme ? useMediaQuery(theme.breakpoints.up('md')) : true;
  return <ERCalendar {...rest} theme={theme} isDesktop={isDesktop} />;
}

export default withStyles(commonResourceStyle, { withTheme: true })(withRouter(ERCalendarWrapper));