import { checkboxClasses } from '@mui/material/Checkbox';
import { radioClasses } from '@mui/material/Radio';
import { display, fontWeight, height, minWidth, width } from '@mui/system';

export const appPageNavBarWidth = '10%';
export const appPageTabsWidth = '90%';

// Colors
export const navy = '#243060';
export const navyDark = '#000736';
export const tiffany = '#4bbdad';
export const orange = '#fc4a1a';
export const orangeAccent = '#FC4319';
export const darkOrange = '#e0592a';
export const darkOrange2 = '#c10000';
export const darkRed = '#f44336';
export const green = '#5aac44';
export const gray = '#9e9e9e';
export const lightGray = '#f7f7f7';
export const errorRed = '#CC0202';
export const bgGray = '#FBFBFE';
export const lightBlue = '#556377';
export const bgLightGray = '#F9F9F9';

const homeDrawerWidth = '20%';
const mobileHomeDrawerWidth = '25%';

export const autocompleteOptionStyle = {
  '&:hover': { backgroundColor: `${tiffany} !important`, opacity: 0.75 },
  '&[aria-selected="true"]': {
    backgroundColor: '#ebebeb !important',
    '&:hover': { backgroundColor: `${tiffany} !important`, opacity: 0.75 },
  },
};

export const headerButtonStyle = {
  width: '186px',
  height: '40px',
  borderRadius: '5px',
  position: 'absolute',
  border: 'unset',
  fontFamily: 'Asap',
  fontSize: '12px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  right: '0',
  top: '5%',
  boxShadow: 'none',
};

export const textOverflowProtection = {
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  wordBreak: 'break-all',
  hyphens: 'auto',
};

export const hoverEffectOne = {
  '&:hover': {
    backgroundColor: `${navy}`,
    color: 'white',
  },
};

export const hoverEffectTwo = {
  '&:hover': {
    backgroundColor: `${tiffany}`,
    color: 'white',
  },
};

export const dialogCloseButtonStyle = {
  position: 'absolute',
  right: '1px',
  top: '1px',
};

export const dialogTitleContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const dialogFormRowStyle = {
  marginTop: '10px',
  display: 'flex',
};

export const customizedThemes = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1636,
    },
  },
  palette: {
    primary: {
      main: `${tiffany}`,
      contrastText: '#fff',
    },
    secondary: {
      main: `${navy}`,
      dark: '#000736',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Asap',
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          position: 'relative',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${navy}`,
          },
          '&:hover:not($disabled):not($focused):not($error) .MuiOutlinedInput-notchedOutline': {
            borderColor: 'black',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              borderColor: 'black',
            },
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${tiffany}`,
            borderWidth: 2,
          },
        },
        input: {
          padding: '14px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: `${tiffany}`,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '& .Mui-selected': {
            backgroundColor: '#fff',
            color: 'black',
            '&:hover': {
              backgroundColor: `${tiffany}`,
              opacity: 0.75,
            },
          },
          '&:hover': {
            backgroundColor: `${tiffany}`,
            color: 'white',
            opacity: 0.75,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        icon: {
          fontSize: '20px',
          fontWeight: 'bold',
          color: 'black',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&[data-focus="true"]': {
            backgroundColor: `${tiffany}`,
            opacity: 0.75,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        contained: {
          color: '#ffffff',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        textSecondary: {
          color: navy,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          [`&.${checkboxClasses.checked}`]: {
            color: navy,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          [`&.${radioClasses.checked}`]: {
            color: navy,
          },
        },
      },
    },
  }, // components
};

export const appPageStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '700px',
  },
  tabs: {
    background: '#ebebeb',
    backgroundColor: '#ebebeb',
    width: appPageTabsWidth,
    flexGrow: '1',
    minHeight: '100vh',
  },
  gradientTabs: {
    background: `linear-gradient(to right, ${tiffany} 0%, #8cd0e1 100%), #ebebeb`,
    backgroundSize: '100% 10rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundColor: '#ebebeb',
    width: appPageTabsWidth,
    flexGrow: '1',
    minHeight: '100vh',
  },
});

export const commonResourceStyle = theme => ({
  dateSelectorText: {
    margin: '10px 0  15px 0',
    position: 'relative',
    fontFamily: 'Asap',
    fontSize: '14px',
    cursor: 'pointer',
  },
  dateSelectedIcon: {
    position: 'absolute',
    top: 1,
  },
  dateSelectedText: {
    textDecoration: 'underline',
    color: `${navy}`,
    marginLeft: '30px',
  },
  dateSelectedList: {
    color: `${navy}`,
    display: 'inline-block',
    overflow: 'hidden',
    maxWidth: '160px',
    marginLeft: '30px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    paddingTop: '5px',
  },
  dateSelectedEdit: {
    textDecoration: 'underline',
    color: `${orange}`,
    marginLeft: '10px',
    display: 'inline-block',
    verticalAlign: 'super',
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '85px',
    position: 'relative',
  },
  paginationButton: {
    ...headerButtonStyle,
    color: '#fff',
    backgroundColor: `${orange}`,
    cursor: 'pointer',
    textAlign: 'center',
    position: 'relative',
    width: 'auto',
    height: '34px',
    margin: '0 16px',
    ...hoverEffectOne,
  },
  pageNumberSpan: {
    fontSize: '12px',
    width: '32px',
    height: '32px',
    display: 'inlineBlock',
    lineHeight: '32px',
    marginRight: '4px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  pageDotSpan: {
    fontSize: '18px',
    width: '32px',
    height: '40px',
    display: 'inlineBlock',
    lineHeight: '32px',
    marginRight: '4px',
    textAlign: 'center',
  },
  searchableSingleSelect: {
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.87) !important',
      opacity: '100%',
    },
    padding: '5px',
  },
  applyFilter: {
    marginLeft: '20px',
    maxHeight: '46px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: `${tiffany}!important`,
    },
  },
  searchImage: {
    position: 'absolute',
    right: '0px',
    top: '8px',
    [theme && theme.breakpoints.down('sm')]: {
      width: '97px',
    },
  },
  chipRoot: {
    maxWidth: 118,
    marginLeft: '5px',
    marginBottom: '1px',
    [theme && theme.breakpoints.down('sm')]: {
      maxWidth: 82,
    },
  },
  chipRootFull: {
    marginLeft: '5px',
    marginBottom: '1px',
  },
  badgeFilter: {
    color: '#fff',
    marginRight: '5px',
    marginTop: '5px',
    height: '25px',
    fontSize: '12px',
    padding: '0px 8px',
  },
  badgeCheckBox: { paddingRight: 0 },
  chipRootLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  chipContainer: {
    display: 'flex',
    position: 'absolute',
    left: 0,
    borderRadius: '5px',
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    scrollDirection: 'horizontal',
    WebkitTransition: 'all .5s',
    MozTransition: 'all .5s',
    OTransition: 'all .5s',
    transition: 'all .5s',
    maxWidth: '100%',
  },
  chipContainerOpen: {
    transform: 'translate(0px, 50px)',
    WebkitTransform: 'translate(0px, 50px)',
  },
  chipContainerClosed: {
    display: 'inline-flex',
    width: 'calc(100% - 20px)',
    overflow: 'hidden',
    '& > *': {
      marginLeft: '2px',
    },
  },
  filterExpandIcon: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'black',
  },
  scrollMenuInner: {
    width: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginLeft: '2px',
    },
  },
  inputMarginBottom: {
    marginBottom: '50px',
    // transition: 'margin .01s',
  },
  inputMarginNone: {
    // transition: 'margin .01s',
  },
  practiceSelectEndAdornment: {
    position: 'absolute',
    right: '50px',
  },
  popper: {
    backgroundColor: 'white',
    zIndex: 1300,
    maxHeight: 'calc(50vh)',
    overflow: 'auto',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    maxWidth: '400px',
    [theme && theme.breakpoints.down('sm')]: {
      maxWidth: '300px',
    },
  },
  selectedItem: {
    outline: '4px solid orange',
  },
  selectableMenuItem: {
    paddingRight: '40px',
  },
  selectedText: {
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'black',
  },
  menuListText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  greenContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    background: `linear-gradient(to right, ${tiffany} 0%, #8cd0e1 100%), #ebebeb`,
    backgroundSize: '100% 10rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
  },
  resourceImage: {
    position: 'absolute',
    zIndex: '0',
    right: '266px',
    top: '4px',
    [theme && theme.breakpoints.down('sm')]: {
      right: '10px',
      width: '100px',
    },
  },
  resourceHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '94%',
    alignItems: 'left',
    justifyContent: 'space-between',
    margin: '3% 3% 0 3%',
    position: 'relative',
    [theme && theme.breakpoints.down('sm')]: {
      paddingTop: '40px',
      width: '89%',
    },
  },
  homeResourceHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '94%',
    alignItems: 'left',
    justifyContent: 'space-between',
    padding: '3% 3% 0',
    backgroundImage: `linear-gradient(to left, #8cd0e1 15%, ${tiffany})`,
    [theme && theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  calendarHomeHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'left',
    padding: '30px 3% 0',
    backgroundImage: `linear-gradient(to left, #8cd0e1 15%, ${tiffany})`,

    [theme && theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  externshipBadgeDropdown: {
    marginTop: '5px',
  },
  buttonIcon: {
    verticalAlign: 'sub',
    width: '16px',
    marginRight: '5px',
  },
  jobPostingResourceNameLarge: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  jobPositionResourceHeader: {
    display: 'flex',
    flexDirection: 'row',
    width: '92%',
    alignItems: 'left',
    justifyContent: 'space-between',
    padding: '3% 4% 5% 4%',
    backgroundImage: `linear-gradient(to left, #8cd0e1 15%, ${tiffany})`,
    [theme && theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: '5% 3%',
      paddingTop: '50px',
    },
  },
  dashboard: {
    position: 'relative',
    [theme && theme.breakpoints.up('md')]: {
      marginTop: '-30px',
      marginBottom: '-45px',
    },
  },
  prevCircle: {
    borderRadius: '50%',
    width: '45px',
    height: '45px',
    backgroundColor: `${orange}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    left: '-15px',
    cursor: 'pointer',
    zIndex: 1,
    position: 'absolute',
    transition: '0.25s',
    ...hoverEffectOne,
    [theme && theme.breakpoints.down('sm')]: {
      width: '35px',
      height: '35px',
      top: '50px',
      left: '-10px',
    },
  },
  nextCircle: {
    borderRadius: '50%',
    width: '45px',
    height: '45px',
    backgroundColor: `${orange}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginLeft: '-20px',
    cursor: 'pointer',
    transition: '0.25s',
    ...hoverEffectOne,
    [theme && theme.breakpoints.down('sm')]: {
      width: '35px',
      height: '35px',
      position: 'absolute',
      bottom: '50px',
      zIndex: '99',
    },
  },
  navIcon: {
    color: '#fff',
    fontSize: '35px',
  },
  dashletContainer: {
    display: 'flex',
    width: '60%',
    overflow: 'hidden',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dashlet: {
    width: 190,
    height: 120,
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontFamily: 'Asap',
    borderRadius: '5px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
    backgroundColor: '#fff',
    transition: '0.5s',
    minWidth: 'calc(100% / 3 - 24px)',
    padding: '5px',
    margin: '12px',
    '&:hover': {
      transition: '0.5s',
    },
    [theme && theme.breakpoints.down('sm')]: {
      minWidth: 'calc(100% / 3 - 24px)',
    },
  },
  dashletIconValue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '5px',
    width: '100%',
    position: 'relative',
  },
  dashletValue: {
    color: `${orange}`,
    fontSize: 40,
  },
  dashletIcon: {
    color: `${navy}`,
    marginRight: '5px',
    fontSize: 40,
  },
  dashletTitle: {
    fontSize: '15px',
    marginBottom: '5px',
    fontStyle: 'italic',
    textTransform: 'uppercase',
    color: `${navy}`,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  dashletCaption: {
    height: '12px',
    fontSize: '10px',
    fontStyle: 'italic',
    color: `${tiffany}`,
  },
  dashletToolTip: {
    position: 'absolute',
    right: 0,
    top: 0,
    fontSize: '18px',
  },
  dashletToolTipPopper: {
    fontSize: '14px !important',
    fontStyle: 'italic',
    maxWidth: '300px',
    padding: 0,
  },
  toolTipContent: {
    padding: '5px 12px 12px 12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  toolTipContentCloseIcon: {
    fontSize: '15px',
  },
  resourceName: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: `${navy}`,
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    [theme && theme.breakpoints.down('sm')]: {
      paddingTop: '30px',
      width: '94%',
    },
  },
  homeResourceName: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#ffffff',
    textTransform: 'capitalize',
    fontFamily: 'circe-rounded',
    textAlign: 'center',
    display: 'flex',
  },
  headerImageContainer: {
    [theme && theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
    },
    [theme && theme.breakpoints.down('sm')]: {
      position: 'absolute',
      bottom: '0',
      marginLeft: '15px',
    },
  },
  headerDogImage: {
    height: '250px',
    [theme && theme.breakpoints.down('md')]: {
      height: '200px',
      marginBottom: '-37px',
    },
    [theme && theme.breakpoints.down('sm')]: {
      height: '150px',
      marginBottom: '-30px',
    },
  },
  jobPositionResourceName: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#ffffff',
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    [theme && theme.breakpoints.down('sm')]: {
      width: '92%',
    },
  },
  resourceNameLarge: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  resourceLogo: {
    verticalAlign: 'middle',
    marginRight: '10px',
    [theme && theme.breakpoints.down('sm')]: {
      width: '25px',
    },
  },
  resourceLogoSub: {
    verticalAlign: 'sub',
    marginRight: '10px',
    width: '38px',
  },
  resourceLogoApp: {
    verticalAlign: 'sub',
    marginRight: '10px',
    width: '38px',
    fontSize: '36px',
    [theme && theme.breakpoints.down('sm')]: {
      width: '25px',
    },
  },
  headerButton: {
    ...headerButtonStyle,
    color: '#ffffff',
    backgroundColor: `${orange}`,
    right: '0',
    cursor: 'pointer',
    ...hoverEffectOne,
  },
  headerButtonCancel: {
    ...headerButtonStyle,
    color: '#ffffff',
    backgroundColor: `${navy}`,
    right: 200,
    cursor: 'pointer',
    ...hoverEffectTwo,
  },
  jobPostingHeaderButton: {
    ...headerButtonStyle,
    color: '#ffffff',
    backgroundColor: `${orange}`,
    right: '0',
    cursor: 'pointer',
    margin: '0 8px',
    ...hoverEffectOne,
  },
  headerSecondButton: {
    ...headerButtonStyle,
    color: '#ffffff',
    backgroundColor: `${navy}`,
    right: '208px',
    cursor: 'pointer',
  },
  headerDisabledButton: {
    ...headerButtonStyle,
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    right: '0',
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },
  headerDisabledSecondButton: {
    ...headerButtonStyle,
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    right: '208px',
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 5,
  },
  postTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: `${navy}`,
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    marginBottom: '10px',
  },
  resourceSub: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: `${navy}`,
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    marginBottom: '10px',
  },
  resourceAlert: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: `${navy}`,
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    marginBottom: '10px',
  },
  msg: {
    fontSize: '1rem',
    color: 'rgba(0,0,0,0.54)',
    fontFamily: 'circe-rounded',
    marginTop: '30px',
  },
  link: {
    fontSize: '1rem',
    color: `${navy}`,
    padding: '0px 4px',
    paddingBottom: '4px',
    textDecoration: 'underline',
  },
  redButton: {
    backgroundColor: `${orange}`,
    color: '#fff',
    marginLeft: '5px',
  },
  multiSelectContainer: {
    marginTop: '20px',
  },
  breadcrumbContainer: {
    width: '25%',
    minWidth: '175px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  breadcrumb: {
    marginLeft: 0,
    marginRight: 0,
  },
  breadcrumbItem: {
    textDecoration: 'underline',
    fontSize: 14,
    fontFamily: 'Asap',
    color: 'black',
  },
  breadcrumbItemActive: {
    fontSize: 14,
    fontFamily: 'Asap',
    color: 'black',
  },
  fabButton: {
    position: 'fixed',
    width: '63px',
    height: '63px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    fontFamily: 'Asap',
    fontSize: '9px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    right: '20px',
    bottom: '100px',
    backgroundColor: `${orange}`,
    color: '#fff',
    borderRadius: '50%',
    transition: '0.25s',
    ...hoverEffectOne,
  },
  addNewPositionButton: {
    backgroundColor: `${orange}`,
    color: '#fff',
    width: '100%',
    padding: '8px 8px',
  },
  addNewPositionIcon: {
    marginRight: '10px',
  },
  paperContainer: {
    padding: '10px',
  },
  dialog: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
  },
  userDialog: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    padding: '20px 30px 20px 30px',
  },
  dialogClose: {
    width: '100%',
    textAlign: 'right',
  },
  dialogTitle: {
    fontSize: 20,
    textAlign: 'center',
    color: `${tiffany}`,
    marginBottom: '40px',
  },
  userDialogTitle: {
    fontSize: 20,
    textAlign: 'center',
    color: `${tiffany}`,
    marginBottom: '40px',
    fontWeight: 'bold',
  },
  bold: {
    fontWeight: 'bold',
  },
  boldWidth: {
    fontWeight: 'bold',
    width: '300px',
    overflow: 'hidden',
  },
  innerPaper: {
    padding: '10px',
    position: 'relative',
    border: `1px solid ${navy}`,
  },
  innerPaper2: {
    padding: '10px',
    backgroundColor: '#f1f1f1',
    position: 'relative',
  },
  paper: {
    margin: '1% 2% 1% 2%',
    width: '94%',
    height: '95%',
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0',
      backgroundColor: '#ebebeb',
    },
  },
  drawer: {
    width: homeDrawerWidth,
    flexShrink: 0,

    '& .MuiDrawer-paper': {
      height: 'calc(85vh - 40px)',
      overflowX: 'hidden',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },

    [theme && theme.breakpoints.down('md')]: {
      width: mobileHomeDrawerWidth,
    },
  },
  drawerPaper: {
    width: homeDrawerWidth,
    zIndex: 100,
    overflowY: 'hidden',
    marginTop: '153px',
    border: '1px solid #E1E5EA',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px 0px 0px 8px',
    backgroundColor: 'white',

    [theme && theme.breakpoints.down('md')]: {
      width: mobileHomeDrawerWidth,
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
    minHeight: '40px',

    '& .MuiTypography-root': {
      fontSize: '16px',
      fontWeight: '700',
      color: lightBlue,
      padding: '0px 8px',
    },

    [theme && theme.breakpoints.down('md')]: {
      minHeight: '56px',
    },
  },
  drawerContainer: {
    padding: '16px',
    overflowY: 'auto',
  },
  calendarDiv: {
    flexGrow: 1,
    padding: '0px',

    width: '100%',
    border: '1px solid #E1E5EA',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    backgroundColor: 'white',
    display: 'flex',

    transition: theme && theme.transitions.create('margin', {
      easing: theme && theme.transitions.easing.sharp,
      duration: theme && theme.transitions.duration.leavingScreen,
    }),

    [theme && theme.breakpoints.down('md')]: {
      marginLeft: `-${mobileHomeDrawerWidth}`,
      marginRight: `-${mobileHomeDrawerWidth}`,
      minWidth: 'unset',
    },
  },
  contentShiftRight: {
    transition: theme && theme.transitions.create('margin', {
      easing: theme && theme.transitions.easing.easeOut,
      duration: theme && theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  jobPositionPaperNoEdit: {
    margin: '1% 2% 1% 2%',
    width: '94%',
    height: '95%',
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
  },
  jobPositionPaper: {
    margin: '-3% 2% 1% 2%',
    width: '94%',
    height: '95%',
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
    [theme && theme.breakpoints.down('sm')]: {
      width: '88%',
    },
  },
  addMoreButtonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme && theme.breakpoints.down('sm')]: {
      marginTop: '20px !important',
    },
  },
  addMoreButton: {
    color: '#ffffff',
    backgroundColor: `${navy}`,
    padding: '12px 15px',
    fontSize: '12px',
    borderRadius: '5px',
    ...hoverEffectTwo,
  },
  addMoreButtonIcon: {
    marginRight: '5px',
    fontSize: '18px',
  },
  paperConnections: {
    margin: '1% 2% 100px 2%',
    width: '94%',
    height: '95%',
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
  },
  paperApplications: {
    margin: '1% 2% 24px 2%',
    width: '94%',
    height: '95%',
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
  },
  connectionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '50px',
  },
  altConnectionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '50px',
  },
  recentAppsContainer: {
    padding: '0 0 0 2%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  altAppsContainer: {
    padding: '3%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  recentPostingsContainer: {
    padding: '0 2% 0 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  connectionsTitle: {
    fontSize: '17px',
    fontWeight: 'bold',
    alignSelf: 'flex-start',
    marginLeft: '3%',
    color: `${navy}`,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  chevronsIndicator: {
    color: 'orange',
    margin: '0 0 3px 5px',
  },
  altConnectionsTitle: {
    fontSize: '17px',
    fontWeight: 'bold',
    alignSelf: 'flex-start',
    color: `${navy}`,
    marginBottom: '10px',
  },
  text: {
    fontSize: '14px',
  },
  right: {
    textAlign: 'right',
  },
  deleteIcon: {
    fontSize: '18px',
    color: `${tiffany}`,
    border: `3px solid ${tiffany}`,
    borderRadius: '50%',
    display: 'flex',
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  },
  paperJobPostings: {
    margin: '1% 2% 2% 2%',
    width: '95% !important',
    minHeight: '320px',
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'hidden',
    overflowX: 'hidden',
    [theme && theme.breakpoints.down('sm')]: {
      width: '93% !important',
    },
  },
  connectionsfilterContainer: {
    margin: '16px 2%',
    width: '94%',
  },
  filterContainer: {
    margin: '30px 2% 0 2%',
    width: '94%',
  },
  filterFrontContainer: {
    margin: '30px 2% 0 2%',
    width: '94%',
    zIndex: '1',
    [theme && theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: '30px',
      width: '88%',
    },
  },
  mobileFilterFrontContainer: {
    margin: '30px 2% 30px 2%',
    zIndex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '88%',
  },
  searchButton: {
    width: '300px !important',
    height: '45px',
    fontFamily: 'Asap',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff',
    margin: '20px 20px 20px 20px',
    borderRadius: '5px',
    backgroundColor: `${navy}`,
    [theme && theme.breakpoints.down('sm')]: {
      margin: '10px 0 40px 0',
      width: '100% !important',
    },
    ...hoverEffectTwo,
  },
  clearFilterText: {
    color: `${navy}`,
    textDecoration: 'underline',
    float: 'right',
    fontStyle: 'italic',
    textTransform: 'lowercase',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'unset',
    },
    [theme && theme.breakpoints.down('sm')]: {
      marginTop: '-40px',
    },
  },
  paperFilters: {
    display: 'flex',
    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '0 10px',
    },
  },
  filter: {
    margin: '20px 0px 20px 20px',
    [theme && theme.breakpoints.down('sm')]: {
      margin: '10px 0',
    },
  },
  filterButton: {
    marginTop: '20px',
    marginRight: '20px',
    marginBottom: '20px',
    minWidth: 120,
    fontSize: '12px',
    fontWeight: 'bold',
    ...hoverEffectTwo,
  },
  nameSearch: {
    margin: '20px 0px 20px 20px',
    width: '100%',
  },
  loadingContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingText: {
    position: 'absolute',
    margin: 'auto',
    fontFamily: 'Asap',
  },
  editButton: {
    width: 168,
    height: 40,
    fontFamily: 'Asap',
    fontSize: 12,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    borderRadius: 5,
    backgroundColor: `${orange}`,
    color: '#ffffff',
  },
  button: {
    border: '2px solid #e0592a',
    color: 'black',
    fontSize: '11px',
    width: '100px',
    minHeight: '35px',
    fontFamily: 'Asap',
    borderRadius: '0px',
    boxShadow: 'none',
    alignSelf: 'center',
    margin: '5px 0px 15px 0px',
    padding: '0',
  },
  formActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '30px',
  },
  simpleBtn: {
    fontFamily: 'Asap',
    fontSize: '14px',
    fontWeight: 'bold',
    color: `${orange}`,
  },
  saveButton: {
    fontFamily: 'Asap',
    color: 'white',
    fontSize: '11px',
    backgroundColor: `${orange}`,
    minWidth: '150px',
    '&:disabled': {
      backgroundColor: '#f0f0f0',
    },
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '40px',
      marginTop: '-80px',
      marginBottom: '50px',
    },
    ...hoverEffectOne,
  },
  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  formBtn: {
    fontFamily: 'Asap',
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: `${orange}`,
    color: '#fff',
    minWidth: '200px',
    marginLeft: '20px',
    boxShadow: 'none',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '10px 0',
      height: '40px',
    },
    ...hoverEffectOne,
  },
  formBtnDisabled: {
    fontFamily: 'Asap',
    fontSize: '12px',
    fontWeight: 'bold',
    marginLeft: '20px',
    minWidth: '200px',
  },
  formBtnCancel: {
    fontFamily: 'Asap',
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: `${navy}`,
    color: '#fff',
    minWidth: '200px',
    marginRight: '10px',
  },
  formBtn2: {
    fontFamily: 'Asap',
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: `${navy}`,
    color: '#fff',
    minWidth: '230px',
    marginLeft: '10px',
    boxShadow: 'none',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
      height: '40px',
    },
    ...hoverEffectTwo,
  },
  jobPostingActionBtns: {
    [theme && theme.breakpoints.down('sm')]: {
      width: '88%',
      marginTop: '20px',
    },
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  formControlLabel: {
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  placeholder: {
    fontFamily: 'Asap',
    fontStyle: 'normal',
    fontSize: '16px',
    color: 'rgba(0,0,0,.4)',
  },
  formControlLabelWarnText: {
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    color: 'red !important',
    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  formControl: {
    marginTop: '20px',
  },
  notchedOutline: {
    borderColor: 'black !important',
  },
  filterInput: {
    '&::placeholder': {
      color: 'black !important',
      opacity: '100%',
    },
  },
  searchableInput: {
    '&::placeholder': {
      color: 'black !important',
      opacity: '100%',
    },
    cursor: 'pointer',
  },
  marginTop: {
    marginTop: '10px',
  },
  formControlLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  talentTypeIconCard: {
    maxWidth: '45px',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  newTalentTypeIconCard: {
    maxWidth: '45px',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginLeft: '10px',
  },
  fullNameColumn: {
    marginLeft: '30px',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  fullNameColumnConnections: {
    marginLeft: 0,
    display: 'inline-block',
    position: 'relative',
  },
  fullNameColumnImage: {
    height: '40px',
    width: '30px',
    maxHeight: '35px',
    marginRight: '15px',
  },
  fullNameColumnImageConnection: {
    height: '40px',
    width: '30px',
    maxHeight: '35px',
  },
  talentTypeIconImage: {
    height: '50px',
    maxHeight: '50px',
  },
  cardNewItem: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12,
    bottom: '-15px',
    left: '-8px',
    color: `${orange}`,
  },
  newItem: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 8,
    bottom: '-15px',
    left: '-8px',
    color: `${orange}`,
  },
  newItemIcon: {
    fontSize: 16,
  },
  editPracticeContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  editPracticeRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  editPracticeColumn1: {
    position: 'relative',
    width: '50%',
    flexDirection: 'column',
    marginRight: '20px',
  },
  editPracticeColumn2: {
    width: '50%',
    flexDirection: 'column',
  },
  hidden: {
    textIndent: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  subTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  invalidInput: {
    border: '1px solid red !important',
    borderRadius: '4px',
  },
  addNewSocialMedia: {
    color: `${navy}`,
    alignSelf: 'flex-end',
  },
  phoneNumberContainer: {
    flexDirection: 'column',
    width: '50%',
    marginRight: '20px',
  },
  faxNumberContainer: {
    flexDirection: 'column',
    width: '50%',
  },
  addressContainer: {
    flexDirection: 'column',
    width: '50%',
    marginRight: '20px',
  },
  cityContainer: {
    flexDirection: 'column',
    width: '100%',
  },
  connectionHeader: {
    display: 'flex',
    alignItems: 'center',
    backgroundImage: `linear-gradient(to right, #82e0d6, ${tiffany})`,
    padding: '20px',
    color: '#fff',
    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  connectionsHeaderImageContainer: {
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme && theme.breakpoints.down('sm')]: {
      marginTop: '40px',
    },
  },
  connectionHeaderText: {
    width: '45%',
    padding: '40px',
    ...textOverflowProtection,
    [theme && theme.breakpoints.down('sm')]: {
      width: '80%',
      textAlign: 'center',
    },
  },
  connectionHeaderName: {
    fontSize: 40,
    color: '#fff',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '30px',
    },
  },
  contactInfoContainer: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    [theme && theme.breakpoints.down('sm')]: {
      width: '80%',
      marginBottom: '100px',
    },
  },
  connectionHeaderTalentType: {
    fontSize: 20,
    color: '#fff',
    fontStyle: 'italic',
  },
  connectionHeaderContactItem: {
    fontFamily: 'ASAP',
    color: 'white',
    marginLeft: '10px',
  },
  connectionHeaderContact: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    margin: '10px 0',
  },
  connectionMessageButton: {
    backgroundColor: '#ffffff',
    width: '85px',
    height: '85px',
    '@media print': {
      opacity: 0,
    },
    [theme && theme.breakpoints.down('sm')]: {
      width: '65px',
      height: '65px',
      position: 'absolute',
      top: '15px',
      right: '15px',
    },
  },
  msgBtnContentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  chatIconContainer: {
    height: '35px',
    [theme && theme.breakpoints.down('sm')]: {
      height: '25px',
    },
  },
  unreadMsgCount: {
    fontSize: '9px',
    position: 'absolute',
    bottom: '62px',
    left: '57px',
    zIndex: '5',
    color: '#ffffff',
    [theme && theme.breakpoints.down('sm')]: {
      bottom: '48px',
      left: '42px',
    },
  },
  unreadMsgIcon: {
    color: `${navy}`,
    fontSize: '13px',
    padding: '0',
    position: 'absolute',
    bottom: '63px',
    left: '53px',
    [theme && theme.breakpoints.down('sm')]: {
      bottom: '48px',
      left: '38px',
    },
  },
  chatIcon: {
    fontSize: '38px',
    color: `${orange}`,
    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  newMsgTextContainer: {
    height: '25px',
  },
  newMsgText: {
    fontSize: '10px',
    color: `${orange}`,
    height: '8px',
    fontWeight: 'bold',
    fontStyle: 'italic',
    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '8px',
    },
  },
  emailhyperLink: {
    fontFamily: 'ASAP',
    color: '#ffffff',
  },
  connectionDetails: {
    display: 'flex',
    padding: '40px',
    fontFamily: 'Asap',
    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      margin: '0 20px',
      backgroundColor: '#ffffff',
      padding: '15px',
    },
  },
  connectionDetailsHeader: {
    fontSize: 20,
    textTransform: 'uppercase',
    color: `${navy}`,
    fontWeight: 'bold',
    overflowWrap: 'break-word',
  },
  connectionTopTxt: {
    textAlign: 'center',
    padding: '30px',
    fontFamily: 'Asap',
    color: `${navy}`,
    fontSize: 24,
    lineHeight: 1.5,
  },
  connectionDetailsFullNameArchived: {
    fontSize: 24,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    color: `${navy}`,
  },
  connectionDetailsFullName: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  connectionDetailsText: {
    paddingTop: '20px',
    paddingBottom: '40px',
    paddingRight: '40px',
  },
  connectionDetailsLeft: {
    width: '70%',
  },
  experienceDetails: {
    width: '30%',
    backgroundColor: '#f7f7f7',
    borderRadius: 5,
    padding: '10px',
    paddingTop: '50px',
  },
  experienceImageContainer: {
    display: 'flex',
    marginTop: '8px',
    marginBottom: '8px',
    justifyContent: 'center',
  },
  experienceImage: {
    width: '70px',
  },
  experienceItem: {
    display: 'flex',
    marginBottom: '20px',
    fontFamily: 'Asap',
  },
  experienceItemTitle: {
    width: '40%',
    textAlign: 'right',
    fontStyle: 'italic',
    color: '#878787',
    paddingRight: '10px',
  },
  experienceItemValue: {
    width: '60%',
    textTransform: 'capitalize',
  },
  connectionDetailsActions: {
    borderTop: '1px solid #cecece',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '40px',
    paddingRight: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    '@media print': {
      opacity: 0,
    },
    [theme && theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: '10px 20px',
      borderTop: 'none',
      flexDirection: 'column',
    },
  },
  connectedOn: {
    fontSize: '14px',
    fontStyle: 'italic',
    color: `${orange}`,
    [theme && theme.breakpoints.down('sm')]: {
      marginTop: '80px',
    },
  },
  addNewResButton: {
    backgroundColor: '#e0592a',
    color: '#fff',
    padding: '0',
    fontSize: '11px',
    height: '40px',
    width: '170px',
    fontFamily: 'Asap',
    boxShadow: 'none',
    marginRight: '20px',
    marginLeft: 'auto',
  },
  connectionDetailsActionsItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '350px',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  logo: {
    maxWidth: '400px',
    maxHeight: '150px',
  },
  closeButtonContainer: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    zIndex: '100',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeImageButton: {
    backgroundColor: `${tiffany}`,
    color: '#fff',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
  },
  editorCustomStyles: {
    formControl: {
      marginTop: 0,
    },
  },
  resultsContainer: {
    display: 'flex',
    width: '94%',
    marginTop: '1%',
  },
  resultShownText: {
    margin: '0 8px',
  },
  helpOutlineIcon: {
    marginLeft: '2px',
    height: '18px',
    cursor: 'pointer',
  },
  helpContents: {
    padding: '10px',
    maxWidth: '400px',
  },
  expandMoreIcon: {
    marginRight: '14px',
    marginTop: '3px',
  },
  helpPopoverText: {
    whiteSpace: 'pre-line',
  },
  helpPopover: {
    padding: '10px',
    maxWidth: '400px',
  },
  badgeText: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: `${navy}`,
    fontFamily: 'circe-rounded',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
  },
  badgeTextContainer: {
    display: 'flex',
  },
  customFormControlStyle: {
    marginTop: '20px',
    height: '100%',
  },
  editorGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme && theme.breakpoints.down('sm')]: {
      marginTop: '30px !important',
    },
  },
  mobileRichTextEditorStyle: {
    marginTop: '20px',
    height: '500px',
  },
  warningText: {
    color: 'red',
  },
  viewMoreApplicationsButton: {
    fontFamily: 'Asap',
    fontWeight: 'bold',
    color: `${orange}`,
  },
  navigateNextIcon: {
    width: '17px',
  },
  hover: {
    height: '77px',
    '&:hover': {
      backgroundColor: '#a6e6dd',
      transition: '0.2s',
    },
  },
  connectionsRowStyle: {
    height: '77px',
    backgroundColor: '#f1fffd',
    '&:hover': {
      backgroundColor: '#a6e6dd',
      transition: '0.2s',
    },
  },
  overflowEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  newConnectionDetailsLeft: {
    width: '60%',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  newExperienceDetails: {
    width: '40%',
    backgroundColor: '#f7f7f7',
    borderRadius: 5,
    padding: '10px',
    paddingTop: '50px',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0',
      marginTop: '15px',
    },
  },
  newExperienceItemTitle: {
    width: '40%',
    textAlign: 'right',
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: '#878787',
    paddingRight: '10px',
    [theme && theme.breakpoints.down('sm')]: {
      border: '5px solid #ffffff',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      fontWeight: 'normal',
      height: '40px',
      padding: '5px',
    },
  },
  newExperienceItemValue: {
    width: '60%',
    textTransform: 'capitalize',
    [theme && theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
      border: '5px solid #ffffff',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      fontWeight: 'normal',
      height: '40px',
      padding: '5px',
    },
  },
  jobPositionCardContainer: {
    paddingTop: '20px',
    width: '50%',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  badgesContainerView: {
    paddingBottom: '40px',
    paddingTop: '20px',
  },
  badgesContainer: {
    paddingTop: '20px',
  },
  badgesNarrowContainer: {
    paddingBottom: '20px',
    paddingTop: '20px',
  },
  printButton: {
    color: '#ffffff',
    backgroundColor: `${navy}`,
    [theme && theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: tiffany,
      color: 'white',
      borderColor: tiffany,
    },
  },
  printButtonText: {
    fontSize: '12px',
    paddingLeft: '5px',
  },
  appActionContainer: { display: 'flex', flexDirection: 'row' },
  makeUnreadButton: {
    marginLeft: '13px',
    color: '#ffffff',
    backgroundColor: `${tiffany}`,
    [theme && theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: `${navy}`,
    },
  },
  experienceItem: {
    display: 'flex',
    marginBottom: '20px',
    fontFamily: 'Asap',
    [theme && theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  '@media print': {
    nonPrint: {
      opacity: 0,
    },
    hidePrint: {
      display: 'none',
    },
    hideBoxShadows: {
      boxShadow: 'none',
    },
    newExperienceDetails: {
      width: '50%',
      fontSize: 12,
      padding: '5px',
    },
    newConnectionDetailsLeft: {
      width: '50%',
    },
    newExperienceItemTitle: {
      width: '50%',
    },
    newExperienceItemValue: {
      width: '50%',
    },
    textOverflowProtection: {
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      hyphens: 'auto',
    },
  },
  applicationMobileCardsContainer: {
    width: '88%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  practiceUploadLogoText: {
    display: 'flex',
    alignItems: 'center',
    color: `${navy}`,
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  editPracticeLogoBtn: {
    height: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    borderColor: `${navy}`,
  },
  deleteUserBtn: {
    fontSize: '12px',
    fontWeight: 'bold',
    margin: '20px',
    height: '40px',
    backgroundColor: `${navy}`,
    ...hoverEffectTwo,
  },
  archiveFormBtn: {
    fontFamily: 'Asap',
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: '#ffffff',
    color: `${navy}`,
    minWidth: 200,
    marginLeft: '20px',
    boxShadow: 'none',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '10px 0',
      height: '40px',
    },
    ...hoverEffectOne,
  },
  archiveFormBtnIcon: {
    fontSize: '16px',
    marginRight: '5px',
  },
  jobFormTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '50%',
    fontSize: '16px',
    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      alignItems: 'flex-start',
    },
  },
  boostDescriptionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  boostLeftDescriptionWrapper: {
    width: '50%',
    marginRight: '10px',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
    },
  },
  boostRightDescriptionWrapper: {
    width: '50%',
    marginLeft: '10px',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
    },
  },
  boostCharCounterText: {
    fontSize: '12px',
  },
  contactedAtContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  moodIcon: {
    marginLeft: '5px',
  },
  jobStatus: {
    backgroundColor: 'transparent',
    overflow: 'hidden',
    padding: 0,
  },
  rightDrawerCloseBtn: {
    position: 'fixed',
    border: `1px solid ${tiffany}`,
    borderRadius: '2px',
    cursor: 'pointer!important',
    backgroundColor: 'white',
    boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
    justifyContent: 'center',
    alignItems: 'center',
    color: navy,
    marginLeft: '-10px',
    marginTop: '25px',
    width: '16px',
    height: '16px',
    display: 'flex',
  },
});

export const switchStyles = theme => ({
  switchRoot: {
    width: 40,
    height: 21,
    padding: 0,
    margin: theme && theme.spacing(1),

    '& .MuiSwitch-switchBase': {
      padding: '1px',
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        color: theme.palette.common.white,
        '& + .MuiSwitch-track': {
          opacity: 1,
          height: '20px',
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    '& .MuiSwitch-thumb': {
      width: '18px',
      height: '18px',
    },
    '& .MuiSwitch-track': {
      borderRadius: 20 / 2,
      border: `1px solid ${tiffany}`,
      backgroundColor: '#f0f0f0',
      height: '19px',
      opacity: 1,
      transition: theme && theme.transitions.create(['background-color', 'border']),
    },
  },
});
