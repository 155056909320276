import React, { useState, useEffect } from 'react';
import { isEmpty, groupBy } from 'lodash';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  IconButton,
  Collapse,
} from '@mui/material';
import moment from 'moment/moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UserAutocomplete from '../subcomponents/UserAutocomplete';
import {
  formatPostTitleNoTime,
  getPriorityPointsInfo,
  intervalInfo,
} from '../../utils/Functions';
import { intervalReportStyles } from './styles/IntervalReport.styles';
import { IMG_STORAGE_LINK } from '../../data/Constants';

const IntervalReport = ({ actions, apiToken }) => {
  const [userList, setUserList] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [expandedIntervals, setExpandedIntervals] = useState({});
  const currentYear = moment().year();

  useEffect(() => {
    fetchUserList();
  }, []);

  const fetchUserList = async () => {
    try {
      const response = await actions.getAllResources(apiToken, 'reports/travel-doctor-list');
      const data = response.response || [];

      setUserList(data.map(user => ({
        ...user,
        name: `${user.name} (${user.email})`,
      })));
    } catch (error) {
      console.error('Error fetching user list:', error);
    }
  };

  const handleUserChange = async (user) => {
    if (!user?.id) return;

    try {
      const response = await actions.getAllResources(
        apiToken,
        `reports/travel-doctor-interval-report/${user.id}`,
      );
      const data = response.response || [];

      const processedData = processReportData(data, user);
      setReportData(processedData);

      const grouped = groupBy(processedData, 'interval');
      const groupedWithStats = calculateIntervalStats(grouped);
      setGroupedData(groupedWithStats);

      setInitialExpandedState(groupedWithStats);
    } catch (error) {
      console.error('Error fetching interval report:', error);
    }
  };

  const processReportData = (data, user) => data.map((item) => {
    const dateTimeStr = `${item.date} ${item.startTime}`;
    const dateTime = moment(dateTimeStr, 'YYYY-MM-DD h:mm(A)');

    return {
      ...intervalInfo(user.intervalDate, item.date),
      ...getPriorityPointsInfo(dateTime, item.is_priority_hospital, item.status),
      ...item,
    };
  });

  const setInitialExpandedState = (groupedWithStats) => {
    const defaultExpanded = {};
    Object.keys(groupedWithStats).forEach((intervalKey) => {
      const intervalYear = getIntervalYear(intervalKey, groupedWithStats[intervalKey].stats);
      defaultExpanded[intervalKey] = intervalYear >= currentYear;
    });
    setExpandedIntervals(defaultExpanded);
  };

  const getIntervalYear = (intervalKey, stats) => {
    if (stats.intervalStartDate) {
      const year = moment(stats.intervalStartDate).year();
      if (!isNaN(year)) return year;
    }
    return 0;
  };

  const calculateIntervalStats = grouped => Object.keys(grouped).reduce((acc, intervalKey) => {
    const intervalData = grouped[intervalKey];
    const sortedData = [...intervalData].sort(
      (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf(),
    );

    const shiftCount = intervalData.length;
    const totalPoints = intervalData.reduce(
      (sum, item) => sum + (item.points || 0),
      0,
    );
    const avgPoints = shiftCount > 0
      ? (totalPoints / shiftCount).toFixed(2)
      : 0;

    acc[intervalKey] = {
      data: sortedData,
      stats: {
        intervalStartDate: intervalData[0].intervalStartDate,
        intervalEndDate: intervalData[0].intervalEndDate,
        intervalNumber: intervalData[0].intervalNumber,
        shiftCount,
        totalPoints,
        avgPoints,
      },
    };

    return acc;
  }, {});

  const toggleInterval = (intervalKey) => {
    setExpandedIntervals(prev => ({
      ...prev,
      [intervalKey]: !prev[intervalKey],
    }));
  };

  const renderIntervalHeader = (intervalKey, stats, isExpanded) => {
    const displayIntervalName = intervalKey === 'undefined' ? 'No Interval' : (intervalKey || 'No Interval');
    const hasDateRange = stats.intervalStartDate && stats.intervalEndDate;

    let intervalTitle = displayIntervalName;
    if (hasDateRange) {
      intervalTitle += ` (${stats.intervalStartDate} - ${stats.intervalEndDate})`;
    }
    const shiftLabel = stats.shiftCount > 1 ? 'shifts' : 'shift';
    const statsInfo = `${stats.shiftCount} ${shiftLabel} • ${stats.totalPoints} total priority points • ${stats.avgPoints} avg priority points`;

    return (
      <TableRow
        sx={intervalReportStyles.intervalRow}
        onClick={() => toggleInterval(intervalKey)}
      >
        <TableCell sx={intervalReportStyles.expandButtonCell}>
          <IconButton size="small" aria-label="expand row">
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell colSpan={5} sx={intervalReportStyles.intervalDataCell}>
          <Box sx={intervalReportStyles.intervalDataContainer}>
            <Typography variant="h6" sx={intervalReportStyles.intervalTitle}>
              {intervalTitle}
            </Typography>
            <Typography variant="body2" sx={intervalReportStyles.intervalStats}>
              {statsInfo}
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  const renderIntervalDetails = (data, isExpanded) => (
    <TableRow>
      <TableCell style={intervalReportStyles.detailsCell} colSpan={6}>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <Table size="small" aria-label="interval details">
            <TableBody>
              {data.map(row => (
                <TableRow key={row.id} sx={intervalReportStyles.detailRow}>
                  <TableCell width="10%">{row.date}</TableCell>
                  <TableCell width="15%">{`${row.start_time} - ${row.end_time}`}</TableCell>
                  <TableCell width="35%">{row.practiceName || ''}</TableCell>
                  <TableCell width="31%">{formatPostTitleNoTime(row.jobPostingTitle || '')}</TableCell>
                  <TableCell width="9%">{row.points || 0}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  );

  const renderGroupedData = () => {
    const sortedIntervalKeys = Object.keys(groupedData).sort((a, b) => {
      const aNum = groupedData[a]?.stats?.intervalNumber;
      const bNum = groupedData[b]?.stats?.intervalNumber;
      return bNum - aNum;
    });

    return sortedIntervalKeys.map((intervalKey) => {
      const { data, stats } = groupedData[intervalKey];
      const isExpanded = !!expandedIntervals[intervalKey];

      return (
        <React.Fragment key={intervalKey}>
          {renderIntervalHeader(intervalKey, stats, isExpanded)}
          {renderIntervalDetails(data, isExpanded)}
        </React.Fragment>
      );
    });
  };

  const renderTableHeaders = () => (
    <TableHead>
      <TableRow sx={intervalReportStyles.tableHead}>
        <TableCell sx={intervalReportStyles.expandIconCell} />
        {[
          { width: '10%', label: 'Date' },
          { width: '15%', label: 'Time' },
          { width: '35%', label: 'Location' },
          { width: '30%', label: 'Job Title' },
          { width: '10%', label: 'Priority Points' },
        ].map((column, index) => (
          <TableCell
            key={`header-${index}`}
            width={column.width}
            sx={intervalReportStyles.headerCell}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderEmptyState = () => (
    <TableRow>
      <TableCell
        colSpan={6}
        align="center"
        sx={intervalReportStyles.emptyMessage}
      >
        Select a user to view their interval report
      </TableCell>
    </TableRow>
  );

  return (
    <div style={intervalReportStyles.container} id="postFilter">
      <img style={intervalReportStyles.resourceImage} src={`${IMG_STORAGE_LINK}search-dog-s.png`} />
      <div className="resource-header" style={intervalReportStyles.header}>
        <Typography variant="h6" className="resource-name" style={intervalReportStyles.title}>
          Contract Interval Report
        </Typography>
      </div>

      <Paper style={intervalReportStyles.paper}>
        <Box sx={intervalReportStyles.searchContainer}>
          <UserAutocomplete
            handleStateChange={handleUserChange}
            title="Search User"
            items={userList}
            style={intervalReportStyles.searchInput}
          />
        </Box>

        <TableContainer>
          <Table aria-label="interval report table">
            {renderTableHeaders()}
            <TableBody>
              {Object.keys(groupedData).length > 0
                ? renderGroupedData()
                : renderEmptyState()
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default IntervalReport;
