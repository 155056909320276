import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {cloneDeep, difference, differenceWith, intersectionWith, isEmpty, isNil, sortBy, uniq,} from 'lodash';
import moment from 'moment';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Icon,
  Paper,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import {withStyles} from '@mui/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  AGENCY_NAMES,
  BASE_URLS,
  customLabelConfig,
  EMPLOYMENT_TYPES_OBJ,
  EXPERIENCE_CATEGORY_SPECIAL_FIELD_CONFIG,
  EXPERIENCE_TYPE_CONFIG,
  EXTERNSHIP,
  filterJobTypes,
  HQ_ADMIN_KEY,
  IMG_STORAGE_LINK,
  ROTATION_TYPES,
  VET_TYPES,
} from '../../data/Constants';
import {commonResourceStyle, greenBoosted, yellowCoronado} from '../../css/style';
import LoadingBar from '../subcomponents/LoadingBar';
import TextInput from '../subcomponents/TextInput';
import EditorInput from '../subcomponents/EditorInput';
import TalentExperienceItem from '../subcomponents/TalentExperienceItem';
import {
  badgeColor,
  formatPostTitle,
  isEnterpriseUser,
  isReliefOrExternshipType,
  isTPHCUser,
  utils,
} from '../../utils/Functions';
import SingleSearchableSelect from '../subcomponents/SingleSearchableSelect';
import ArchiveDialog from '../subcomponents/ArchiveDialog';
import CalendarConfirmationDialog from '../subcomponents/CalendarConfirmationDialog';
import TalentExperience from '../subcomponents/TalentExperience';
import {formatISODateOnly} from '../Datepicker/dateUtils';
import DateUtilities, {setBoostSurgeryDetails, setCalendarData} from '../Datepicker/utils';
import FormAutocomplete from '../subcomponents/FormAutocomplete';
import MultipleSelection from '../subcomponents/MultipleSelection';
import TimePicker from '../subcomponents/TimePicker';
import JobpostingCalendar from '../subcomponents/JobpostingCalendar';
import GreenSwitch from '../subcomponents/GreenSwitch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MultipleNeedsDialog from "../subcomponents/MultipleNeedsDialog";

const INFO_TEXT = 'To send a Bulletin Board message to staff within a practice, choose "Bulletin Board" as the type.';
const SUPERHERO_TEXT = 'Superhero matching allows direct selection of any internal candidate, or any external candidate that has previously connected.';
const BADGE_TEXT = 'Badges allow you to post the job to the general Public, to your own Private Network (your'
  + ' staff as defined on the Private Network tab), or to a subsection of the public which have been Badged with'
  + ' in the Badge tab (such as a [Gold] badge you might give to past relief workers you liked).';
const BOOST_DESCRIPTION_HELP_TEXT = 'Only 50 characters can be added.';
const BOOST_DESCRIPTION_MAX_LENGTH = 50;
const BOOST_DESCRIPTION_MAX_ROW = 2;

const positionTypeText = {
  Relief: 'Relief',
  'Bulletin Board': 'BBoard',
  Shadowing: 'Shadowing',
  Externship: 'Extern',
};

const categoryText = {
  'dvm-doctor-of-veterinary-medicine': 'GP / ER DVM',
  'gp-veterinarian': 'GP DVM',
  'er-veterinarian': 'ER DVM',
  'vet-tech-or-assistant': 'Vet Tech / VA',
  'certified-vet-tech': 'Vet Tech',
  'vet-assistant': 'VA',
  'specialist-veterinarian': 'Specialist',
  'non-clinical': 'Non-Clinical',
  'customer-service': 'CSR',
  grooming: 'Grooming',
  'kennel-tech': 'Kennel Tech',
};

class JobPostingsFormCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      badges: {},
      isPrivate: true,
      isPublic: false,
      help: null,
      helpMsg: null,
      experienceLevel: 'Junior',
      selectedCustomPrivateUser: [],
      calendarDialog: false,
      availableDates: [],
      takenDates: [],
      confirmedTakenDates: [],
      multipleNeeds: {},
      confirmedMultipleNeeds: {},
      calendarInitDisplayDate: null,
      archiveDialog: null,
      isBoosted: false,
      isEnterprise: false,
      staffDescription: '',
      contractDescription: '',
      openConfirmation: false,
      openChildConfirmation: false,
      tempTitle: {
        positionType: 'Position Type',
        category: 'Category',
        shift: 'Shift',
        extraText: '',
        startTime: '',
        endTime: '',
      },
      agencyList: AGENCY_NAMES,
      showCoronadoErDates: false,
      calendarCoronadoErDialog: false,
      childPost: {},
      childAvailableDates: [],
      childPendingDates: [],
      childTakenDates: [],
      childConfirmedTakenDates: [],
      childMultipleNeeds: {},
      childConfirmedMultipleNeeds: {},
      rotationTypes: [],
      initBoostSurgeryData: [],
      startTime: null,
      endTime: null,
      enabledMultipleNeeds: false,
    };
    this.filteredJobPosting = undefined;
    try {
      window.scrollTo(0, 0);
    } catch (e) {
      console.log(e);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      match,
      resources,
      admin,
      edit,
    } = this.props;
    const { badges, adminPractices } = resources;
    const { jobpostingId } = match.params;
    const { typeOfEmployment, aggregatorId, practice_id } = this.state;
    const isHQAdmin = admin && admin.adminRole === HQ_ADMIN_KEY;

    if (jobpostingId && this.filteredJobPosting && this.filteredJobPosting.title !== prevState.prevTitle) {
      this.setState({ prevTitle: this.filteredJobPosting.title });
    }

    if (!edit && jobpostingId && this.props !== prevProps) {
      const rotations = this.filteredJobPosting && this.filteredJobPosting.rotationTypes && JSON.parse(this.filteredJobPosting.rotationTypes);

      this.setState({
        rotationTypes: !isEmpty(rotations) ? rotations : [],
      });
    }


    if (!edit || !jobpostingId) {
      if (isHQAdmin && practice_id !== prevState.practice_id || typeOfEmployment !== prevState.typeOfEmployment) {
        if (badges) {
          let badgeSeleted = this.state.badges;

          // Clear extern badges before updating
          Object.keys(badgeSeleted).forEach((b) => {
            const badgeId = +b;
            if (badgeId in badgeSeleted) {
              const badge = badges.find(x => x.id === badgeId);
              if (badge && (badge.title === 'Student' || (badge.config && badge.config.defaultHidden))) {
                delete badgeSeleted[badgeId];
              }
            }
          });

          badges.forEach((badge) => {
            if (badge.aggregator_id === aggregatorId) {
              if (badge.title === 'Student') {
                if (admin.isExternshipAdmin && typeOfEmployment === EXTERNSHIP) {
                  badgeSeleted[badge.id] = true;
                }
              }

              if (badge.config && badge.config.defaultHidden) {
                const practice = adminPractices && adminPractices.find(x => x.id === practice_id);
                const checkTypeOfEmployment = typeOfEmployment && badge.config.forceForJobTypes && badge.config.forceForJobTypes.includes(typeOfEmployment);
                const practiceBadgeConfig = practice && practice.badgeConfig;
                const visibleBadges = practiceBadgeConfig && practiceBadgeConfig.visible_badges;
                const practiceBadge = visibleBadges && visibleBadges.find(x => x.id === badge.id);
                if (checkTypeOfEmployment && practiceBadge) {
                  badgeSeleted[badge.id] = true;
                }
              }
            }
          });

          this.setState({ badges: badgeSeleted });
        }
      }
    }
  }

  fetchAndSetCustomPrivateUser = () => {
    const { actions, apiToken } = this.props;
    actions.getAllResources(apiToken, 'jobpostings/custom-match-candidates').then((resp) => {
      if (!resp.errors) {
        let staffList = resp.response.map(it => ({
          id: it.id,
          name: `${it.name} (${it.email})`,
        }));
        this.setState({
          staffList,
          selectedCustomPrivateUser: this.state.customPrivateUsers
            ? staffList.filter(s => this.state.customPrivateUsers.filter(scp => scp.id === s.id)[0])
            : [],
        });
      }
    });
  };

  fetchAndSetTalentList = () => {
    const { actions, apiToken } = this.props;
    actions.getAllResources(apiToken, 'jobpostings/candidates').then((resp) => {
      if (resp.response) {
        let talentList = resp.response.map(it => ({
          id: it.id,
          name: `${it.name} (${it.email})`,
        }));
        this.setState({ talentList });
      }
    });
  };

  multipleNeedsCount = (dateObj, status, isChild) => {
    const { jobPostingCalendar, childPost } = this.state;
    const data = isChild ? childPost?.calendar || [] : jobPostingCalendar;
    return !isEmpty(data)
      ? data.filter(c => DateUtilities.isSameDay(DateUtilities.midDayDate(c.date), dateObj) && c.status === status).length
      : 0;
  };

  initiateSelectedDates = (isChild) => {
    const { jobPostingCalendar, childPost } = this.state;

    const calendar = isChild ? childPost?.calendar || [] : jobPostingCalendar;

    const clearDate = location.pathname.includes('newposition'); // used when duplicating posting
    if (calendar) {
      let availableDates = [];
      let takenDates = [];
      let initPendingDates = [];
      let pendingDates = [];
      const multipleNeeds = {};
      let initBoostSurgeryData = [];

      let multipleNeedsDates = [];
      if (!clearDate) {
        calendar.forEach((item) => {
          const dateRow = item || {};
          const dateObj = DateUtilities.midDayDate(dateRow.date);
          const avaiRows = this.multipleNeedsCount(dateObj, 'available', isChild);
          const takenRows = this.multipleNeedsCount(dateObj, 'taken', isChild);
          const pendingRows = this.multipleNeedsCount(dateObj, 'pending', isChild);
          if (dateRow.status === 'pending') {
            initPendingDates.push(dateObj);
          }

          if (avaiRows + takenRows + pendingRows > 1) {
            const key = formatISODateOnly(dateObj);
            multipleNeeds[key] = {
              available: avaiRows,
              taken: takenRows,
              pending: pendingRows,
              date: dateObj,
            };
            if (!multipleNeedsDates.find(d => DateUtilities.isSameDay(d, dateObj))) {
              multipleNeedsDates.push(dateObj);
            }
            if (avaiRows > 0 && dateRow.status === 'available' && !initBoostSurgeryData.find(r => r.date === key)) {
              initBoostSurgeryData.push(dateRow);
            }
          } else {
            if (dateRow.status === 'available') {
              availableDates.push(dateObj);
              initBoostSurgeryData.push(dateRow);
            }
            if (dateRow.status === 'taken') {
              takenDates.push(dateObj);
            }
            if (dateRow.status === 'pending') {
              pendingDates.push(dateObj);
            }
          }
        });
      }
      const boostDetails = setBoostSurgeryDetails({
        availableDates,
        initBoostSurgeryData,
        multipleNeeds,
        boostDetails: [],
      });

      if (!isChild) {
        this.setState({
          availableDates,
          takenDates,
          pendingDates,
          initPendingDates,
          confirmedTakenDates: [...takenDates],
          calendarInitDisplayDate: this.getEarliestSelectedDate(availableDates, takenDates, pendingDates, multipleNeedsDates),
          multipleNeeds,
          boostDetails,
          initMultipleNeeds: { ...multipleNeeds },
          confirmedMultipleNeeds: { ...multipleNeeds },
          initBoostSurgeryData: [...initBoostSurgeryData],
        });
      } else {
        this.setState({
          childAvailableDates: availableDates,
          childTakenDates: takenDates,
          childPendingDates: pendingDates,
          childInitPendingDates: initPendingDates,
          childConfirmedTakenDates: [...takenDates],
          childCalendarInitDisplayDate: this.getEarliestSelectedDate(availableDates, takenDates, pendingDates, multipleNeedsDates),
          childMultipleNeeds: multipleNeeds,
          childBoostDetails: boostDetails,
          childInitMultipleNeeds: { ...multipleNeeds },
          childConfirmedMultipleNeeds: { ...multipleNeeds },
        });
      }
    }
  };

  getEarliestSelectedDate = (availableDates, takenDates, pendingDates, multipleNeedsDates) => {
    const sortedDateList = [...availableDates, ...takenDates, ...pendingDates, ...multipleNeedsDates].sort((date1, date2) => (date1 - date2));
    return sortedDateList.length ? sortedDateList[0] : null;
  };

  componentDidMount = async () => {
    const {
      actions, apiToken, resources, match, edit, admin,
    } = this.props;
    const { pagination, adminPractices } = resources;
    const { accessList } = admin;
    const aggregatorId = accessList[0].type === 'aggregator' ? accessList[0].id : accessList[0].aggregator_id;
    const { jobpostingId } = match.params;

    if (pagination && pagination.jobpostings) {
      sessionStorage.setItem('jobPostingOffset', pagination.jobpostings.start);
    }

    if (isEmpty(adminPractices)) {
      await actions.getAllResources(apiToken, BASE_URLS.getPractices);
    }

    actions.getAllResources(apiToken, 'badges', 'all').then((res) => {
      if (res.response) {
        if (!edit) {
          const badgeSt = {};
          const lockedBadgeMapping = {};
          const lockedBadges = res.response.filter(badge => badge.isLocked);
          lockedBadges.forEach(badge => lockedBadgeMapping[badge.title] = badge.id);
          const isPrivate = !lockedBadges.length;
          this.setState({ isPrivate });

          const bg = {};
          adminPractices?.filter(pr => pr.org).forEach((pr) => {
            bg[lockedBadgeMapping[pr.org]] = true;
          });
          if (Object.keys(bg).filter(k => bg[k]).length === 1) {
            // Object.assign(badgeSt, st);
            this.setState({ badges: badgeSt });
          }
          this.setState({ aggregatorId });
        }
        const manualAddedStudentBadgeIds = res.response.filter(badge => badge.title === 'Student' && !badge.config).map(b => b.id);
        this.setState({ manualAddedStudentBadgeIds });
        const conoradoBadges = res.response.filter(badge => badge.config?.lifeCycle);
        if (conoradoBadges.length) {
          this.setState({
            lifeCycleBadgeId: conoradoBadges[0].id,
            lifeCycleBadgeTitle: conoradoBadges[0].title,
            disabledRange: conoradoBadges[0].config?.lifeCycle,
          });
        }
      }
    });

    const isEnterprise = isEnterpriseUser(admin, actions);
    const queryParams = utils.queryParamsToObject(location.search);
    this.setState({ isEnterprise, aggregatorId });

    if (jobpostingId) {
      const { jobpostings } = resources;
      const keepDuplicate = !location.pathname.includes('newposition');
      this.filteredJobPosting = jobpostings && jobpostings.find(item => item.id === Number(jobpostingId));
      if (!jobpostings || !this.filteredJobPosting) {
        const data = await actions.getOneResource(apiToken, `jobpostings/${jobpostingId}`);
        this.filteredJobPosting = data?.response;
      }

      // Get 'Coronado ER' child post for this post
      if (this.filteredJobPosting?.lifecycle_badge_id && !this.filteredJobPosting?.parent_job_posting_id) {
        const childResp = await actions.getOneResource(apiToken, `jobpostings/${jobpostingId}/child`);
        const childPostData = childResp?.response;
        if (childPostData) {
          this.setState({
              childPost: childPostData,
              showCoronadoErDates: !!(childPostData?.calendar),
              coronadoErDayColor: badgeColor('Coronado ER'),
            },
            () => this.initiateSelectedDates(true));
        }
      }

      // Get JP canlendar data
      const calendarRes = await actions.getAllResources(apiToken, `jobpostings/${jobpostingId}/calendar`);

      const rotations = this.filteredJobPosting && this.filteredJobPosting.rotationTypes && JSON.parse(this.filteredJobPosting.rotationTypes);

      this.setState({
          ...this.filteredJobPosting,
          startTime: this.filteredJobPosting.startTime,
          endTime: this.filteredJobPosting.endTime,
          rotationTypes: !isEmpty(rotations) ? rotations : [],
          jobPostingCalendar: calendarRes && calendarRes.response ? calendarRes.response : [],
        },
        () => {
          this.initiateSelectedDates();
          const titleArr = this.state.title ? this.state.title.split('^') : [];
          const titleTxt = titleArr.length > 1 ? titleArr[1] : '';
          const extraText = titleTxt.indexOf('$T') === -1 ? titleTxt : titleTxt.split('$T')[0];
          const titleExceptTime = titleTxt.indexOf('$T') > -1 ? titleTxt.split('$T') : '';

          this.setState({
            isCustom: this.state.customPrivateUsers && this.state.customPrivateUsers.length > 0,
            isBoosted: keepDuplicate && (this.state.isBoosted || !!queryParams.boost),
            staffDescription: keepDuplicate ? this.state.staffDescription : '',
            contractDescription: keepDuplicate ? this.state.contractDescription : '',
            tempTitle: {
              ...this.state.tempTitle,
              positionType: positionTypeText[this.state.typeOfEmployment] || 'Position Type',
              category: categoryText[this.state.talentType] || 'Category',
              extraText,
              startTime: titleExceptTime[1] && titleExceptTime[1].length > 1 ? (titleExceptTime[1].split('-'))[0] : '',
              endTime: titleExceptTime[1] && titleExceptTime[1].length > 1 ? (titleExceptTime[1].split('-'))[1] : '',
            },
            manualTitle: this.state.title && !this.state.title.includes('^') ? this.state.title : '',
            automatedTitle: this.state.title && this.state.title.includes('^') ? this.state.title : '',
          });

          this.fetchAndSetCustomPrivateUser();
        });

      actions.getAllResources(apiToken, `jobpostings/${jobpostingId}/items`)
        .then((data) => {
          let st = this.state;
          if (!edit) {
            delete st.id;
          }
          // TODO: Fix "Experience level" bug on edit page
          st.experienceLevel = '';
          Object.keys(data.response).forEach((itemKey) => {
            if (itemKey === 'job-type-sub-category' && st.talentType === 'specialist-veterinarian') {
              st[itemKey] = data.response[itemKey][0];
            } else if (itemKey === 'job-type-sub-category') {
              const subCategoryKey = this.getSubCategory(st.talentType);
              st[subCategoryKey] = EXPERIENCE_TYPE_CONFIG[subCategoryKey].items.filter(item => data.response[itemKey].includes(item.key));
            } else if (EXPERIENCE_TYPE_CONFIG[itemKey].items) {
              st[itemKey] = EXPERIENCE_TYPE_CONFIG[itemKey].items.filter(item => data.response[itemKey].includes(item.key));
              if (data.response[itemKey].length === 1) {
                st.tempTitle.category = categoryText[data.response[itemKey][0]] || st.tempTitle.category;
              }
            } else if (EXPERIENCE_TYPE_CONFIG[itemKey].max && EXPERIENCE_TYPE_CONFIG[itemKey].default !== null) {
              st[itemKey] = parseInt(data.response[itemKey][0]);
            }
          });
          this.setState(st);
        });
    } else {
      this.fetchAndSetCustomPrivateUser();
    }
  };

  getSubCategory = (talentType) => {
    if (talentType.includes('dvm')) {
      return 'sub-category';
    }
    return 'vet-sub-category';
  };

  handleClickDialog = (type) => {
    const { archiveDialog } = this.state;

    if (type === 'close') {
      return this.setState({ archiveDialog: null, isSavingAsNew: false, isSaving: false });
    }

    const {
      obj,
      asNew,
      childObj,
      coronadoErBadgeSelected,
    } = archiveDialog;

    if (type === 'confirm') {
      obj.deletedAt = new Date();

      if (childObj) {
        childObj.deletedAt = new Date();
      }
    }

    this.handleSaving(asNew, obj, childObj, coronadoErBadgeSelected);
    this.setState({ archiveDialog: null });
  };

  handleSaving = (asNew, obj, childObj, coronadoErBadgeSelected, calendarData, childCalendarData) => {
    const { actions, apiToken, edit } = this.props;
    const { id, childPost } = this.state;

    if (edit && !asNew) {
      actions.updateResource(apiToken, obj, 'jobpostings', id)
        .then((res) => {
          // update date request tracking
          !isEmpty(calendarData) && actions.addResource(apiToken, {
            jobPostingId: id,
            calendarData,
          }, 'date-request-trackings');

          if (!coronadoErBadgeSelected) return res;
          if (childPost?.id) {
            actions.updateResource(apiToken, childObj, 'jobpostings', childPost?.id).then(() => {
              // update date request tracking
              !isEmpty(childCalendarData) && actions.addResource(apiToken, {
                jobPostingId: childPost?.id,
                calendarData: childCalendarData,
              }, 'date-request-trackings');
            });
          } else {
            actions.addResource(apiToken, childObj, 'jobpostings');
          }
          return res;
        })
        .then((res) => {
          this.setState({ isSaving: false });
          !res.error && this.redirectAfterActions();
        })
        .then(() => actions.addResource(apiToken, { action: 'publish_save' }, 'users', 'log-event'));
    } else {
      actions.addResource(apiToken, obj, 'jobpostings')
        .then((res) => {
          if (!coronadoErBadgeSelected) return res;
          if (res.response) {
            childObj.parent_job_posting_id = res.response.id;
            actions.addResource(apiToken, childObj, 'jobpostings');
          }
          return res;
        })
        .then((res) => {
          if (asNew) {
            this.setState({ isSavingAsNew: false });
          } else {
            this.setState({ isSaving: false });
          }
          !res.error && this.redirectAfterActions();
        })
        .then(() => actions.addResource(apiToken, { action: asNew ? 'publish_as_new' : 'publish_new' }, 'users', 'log-event'));
    }
  };

  save = (asNew, isDuplicate) => {
    const {
      edit, resources,
    } = this.props;
    const {
      id,
      tempTitle,
      manualTitle,
      automatedTitle,
      practice_id,
      typeOfEmployment,
      talentType,
      description,
      isPrivate,
      isPublic,
      isBoosted,
      badges,
      availableDates,
      takenDates,
      pendingDates,
      deletedAt,
      isCustom,
      staffDescription,
      contractDescription,
      multipleNeeds,
      tempConfirmedData,
      initMultipleNeeds,
      childAvailableDates,
      childTakenDates,
      childPendingDates,
      childMultipleNeeds,
      childInitMultipleNeeds,
      childTempConfirmedData,
      childPost,
      lifeCycleBadgeId,
      rotationTypes,
      manualAddedStudentBadgeIds,
      boostDetails,
      initBoostSurgeryData,
      startTime,
      endTime,
      childInitBoostSurgeryData,
      childBoostDetails,
      canSave
    } = this.state;
    if (!canSave) {
      this.setDates(availableDates || [], takenDates || [], pendingDates || [], multipleNeeds || {}, false);
      return;
    }
    if (asNew) {
      this.setState({ isSavingAsNew: true });
    } else {
      this.setState({ isSaving: true });
    }
    const items = Object.keys(EXPERIENCE_TYPE_CONFIG).map((key) => {
      const val = this.state[key];
      const experienceTypeObj = {
        key,
        val: [],
      };

      if (val && Array.isArray(val)) {
        experienceTypeObj.val = val.map(item => item.key);
      } else if (val) {
        if (val === 'all') {
          experienceTypeObj.val = key == 'sub-category' ? ['gp-veterinarian', 'er-veterinarian'] : ['certified-vet-tech', 'vet-assistant'];
        } else {
          experienceTypeObj.val = [val];
        }
      }

      // Set post criteria to job-type-sub-category for telemedicine
      if (experienceTypeObj.val.includes('telemedicine-vet')) {
        experienceTypeObj.key = 'job-type-sub-category';
      }

      return experienceTypeObj;
    });

    let isPast = false;
    let calendarData = [];
    let childCalendarData = [];
    const coronadoErBadgeSelected = Object.keys(badges).filter(x => Number(x) === Number(lifeCycleBadgeId) && badges[x]).length === 1;

    if (isReliefOrExternshipType(typeOfEmployment)) {
      const { jobPostingCalendar } = this.state;
      const today = new Date();

      // Also count in the multipleNeeds to set isPast
      let availableDateValues = this.getAllDateValues('available');
      let takenDateValues = this.getAllDateValues('taken');
      const pendingDateValues = this.getAllDateValues('pending');

      if (coronadoErBadgeSelected) {
        availableDateValues = availableDateValues.concat(this.getAllDateValues('available', true));
        takenDateValues = takenDateValues.concat(this.getAllDateValues('taken'), true);
      }

      isPast = (availableDateValues.length > 0 && !availableDateValues.filter(date => date >= today).length)
        || (takenDateValues.length > 0 && availableDateValues.length === 0 && pendingDateValues.length === 0);

      const tempUntakenConfirmedData = !isEmpty(tempConfirmedData) ? tempConfirmedData.filter(c => c.isUntaken) : [];

      calendarData = setCalendarData({
        jobPostingCalendar,
        availableDates,
        takenDates,
        pendingDates,
        multipleNeeds,
        tempUntakenConfirmedData,
        boostDetails: !isEmpty(boostDetails) ? boostDetails : setBoostSurgeryDetails({
          availableDates,
          initBoostSurgeryData,
          multipleNeeds,
          boostDetails,
        }),
      });

      calendarData = this.setConfirmForCalendarData(id, calendarData, tempConfirmedData, tempUntakenConfirmedData, initMultipleNeeds, multipleNeeds, asNew, isDuplicate, availableDates);

      if (coronadoErBadgeSelected) {
        const childTempUntakenConfirmedData = !isEmpty(childTempConfirmedData) ? childTempConfirmedData.filter(c => c.isUntaken) : [];
        childCalendarData = setCalendarData({
          jobPostingCalendar: childPost?.calendar || [],
          availableDates: childAvailableDates,
          takenDates: childTakenDates,
          pendingDates: [],
          multipleNeeds: childMultipleNeeds,
          tempUntakenConfirmedData: childTempUntakenConfirmedData,
          boostDetails: !isEmpty(childBoostDetails) ? childBoostDetails : setBoostSurgeryDetails({
            childAvailableDates,
            childInitBoostSurgeryData,
            childMultipleNeeds,
            childBoostDetails,
          }),
        });

        childCalendarData = this.setConfirmForCalendarData(childPost.id, childCalendarData, childTempConfirmedData, childTempUntakenConfirmedData, childInitMultipleNeeds || {}, childMultipleNeeds, asNew, isDuplicate, childAvailableDates);
      }

      if (isDuplicate) {
        calendarData = calendarData.filter(c => c.status !== 'unavailable');
        childCalendarData = !isEmpty(childCalendarData) ? childCalendarData.filter(c => c.status !== 'unavailable') : childCalendarData;
      }
    }

    const postTime = !isEmpty(tempTitle.startTime) && !isEmpty(tempTitle.endTime) ? ` ${tempTitle.startTime}-${tempTitle.endTime}` : '';

    const isEdit = id && edit;
    let titleToSave;
    if (isEdit) {
      titleToSave = automatedTitle ? `${automatedTitle.split('^')[0]} ^${tempTitle.extraText} $T${postTime}` : manualTitle;
    } else {
      titleToSave = `${tempTitle.positionType} ${tempTitle.category} ${this.getPositionTypeText(tempTitle.positionType)} ^${tempTitle.extraText} $T${postTime}`;
    }

    const thrive = resources && resources.badges.find(x => x.title === 'Thrive');
    const isThrive = thrive && badges[thrive.id];

    const rotationTypeArr = !isEmpty(rotationTypes) ? rotationTypes : [];
    let badgeIds = Object.keys(badges).filter(x => badges[x]);

    if (!isEmpty(manualAddedStudentBadgeIds)) {
      badgeIds = badgeIds.filter(id => !manualAddedStudentBadgeIds.includes(Number(id)));
    }

    const obj = {
      id: asNew || isDuplicate ? null : id,
      title: titleToSave.replace(/\s+/g, ' '),
      practice_id,
      typeOfEmployment,
      talentType,
      description,
      isPrivate: isThrive || isPrivate,
      isPublic,
      isBoosted,
      badges: badgeIds.map(id => ({ id })),
      items,
      deletedAt: asNew ? null : deletedAt,
      customPrivateUsers: isCustom ? this.state.selectedCustomPrivateUser : [],
      staffDescription: isBoosted ? staffDescription : '',
      contractDescription: isBoosted ? contractDescription : '',
      jobPostingCalendar: calendarData,
      lifecycle_badge_id: coronadoErBadgeSelected ? lifeCycleBadgeId : null,
      rotationTypes: typeOfEmployment === EXTERNSHIP && !isEmpty(rotationTypeArr) ? JSON.stringify(rotationTypeArr) : null,
      startTime,
      endTime,
    };

    let childObj;
    if (coronadoErBadgeSelected) {
      childObj = {
        id: asNew || isDuplicate ? null : childPost?.id,
        title: titleToSave.replace(/\s+/g, ' '),
        practice_id,
        typeOfEmployment,
        talentType,
        description,
        isPrivate: false, // ??? --> To be checked
        isPublic: false,
        isBoosted,
        badges: [{ id: lifeCycleBadgeId }],
        items,
        deletedAt: asNew ? null : deletedAt,
        customPrivateUsers: [],
        staffDescription: isBoosted ? staffDescription : '',
        contractDescription: isBoosted ? contractDescription : '',
        jobPostingCalendar: childCalendarData,
        parent_job_posting_id: asNew || isDuplicate ? null : id,
        lifecycle_badge_id: lifeCycleBadgeId,
        startTime,
        endTime,
      };
    }

    this.handleSaving(asNew, obj, childObj, coronadoErBadgeSelected, calendarData, childCalendarData);
  };

  setConfirmForCalendarData = (
    jobPostingId,
    calendarData = [],
    tempConfirmedData = [],
    tempUntakenConfirmedData = [],
    initMultipleNeeds = {},
    multipleNeeds = {},
    asNew,
    isDuplicate,
    availableDates = [],
  ) => {
    const { admin } = this.props;

    const takenIndexOnDate = {};
    const tempTakenConfirmedData = !isEmpty(tempConfirmedData) ? tempConfirmedData.filter(c => !c.isUntaken) : [];

    let removedMultiDates = [];
    if (!isEmpty(initMultipleNeeds)) {
      const removedMultiDaykeys = difference(Object.keys(initMultipleNeeds), Object.keys(multipleNeeds));
      removedMultiDates = !isEmpty(removedMultiDaykeys) ? removedMultiDaykeys.map(daykey => initMultipleNeeds[daykey].date) : [];
    }

    calendarData = calendarData.map((item) => {
      let returnObj = {
        ...item,
        admin_id: admin.id,
        job_posting_id: asNew || isDuplicate ? null : jobPostingId,
        id: asNew || isDuplicate ? null : item.id,
      };
      // Set confirmation values for the newly added taken shitfs
      if (tempTakenConfirmedData.length && item.status === 'taken' && !item.confirmation_category) {
        const takenConfirmRows = tempTakenConfirmedData.filter(c => DateUtilities.isSameDay(c.date, item.date));
        if (takenConfirmRows.length) {
          takenIndexOnDate[item.date] = !isNil(takenIndexOnDate[item.date]) ? takenIndexOnDate[item.date] + 1 : 0;
          const takenConfirmRow = takenIndexOnDate[item.date] < takenConfirmRows.length ? takenConfirmRows[takenIndexOnDate[item.date]] : {};
          returnObj = {
            ...returnObj,
            talent_user_id: takenConfirmRow.talentUserId,
            confirmation_category: takenConfirmRow.confirmationCategory,
            confirmation: takenConfirmRow.confirmation,
          };

          if (takenConfirmRow.isConfirmed) {
            returnObj.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            returnObj.confirmation_date = new Date();
            returnObj.isConfirmed = true;
          }
        }
      }

      // Set confirmation values for the untaken shitfs
      if (tempUntakenConfirmedData.length && item.status === 'untaken') {
        const untakenConfirmRow = tempUntakenConfirmedData.find(c => c.untakenRowId === item.id);
        if (untakenConfirmRow) {
          returnObj = {
            ...returnObj,
            talent_user_id: untakenConfirmRow.talentUserId,
            confirmation_category: untakenConfirmRow.confirmationCategory,
            confirmation: untakenConfirmRow.confirmation,
          };
        }
      }

      // Set unavailable the rows used to be avai in a multineed, but now the multineed is removed
      if (!isEmpty(removedMultiDates)
        && !DateUtilities.dateIn(availableDates, item.date)
        && item.status === 'available'
        && DateUtilities.dateIn(removedMultiDates, item.date)) {
        returnObj = {
          ...returnObj,
          status: 'unavailable',
        };
      }

      return { ...returnObj, date: formatISODateOnly(item.date) };
    });
    return calendarData;
  };

  redirectAfterActions = () => {
    const { history } = this.props;
    if (this.checkQueryParam('signUp')) history?.push('/');
    else history?.push('/jobpostings');
  };

  getDashlet = (title, caption, style) => {
    const { theme } = this.props;
    const stylesWithTheme = commonResourceStyle(theme);

    return (
      <div style={style}>
        <div style={stylesWithTheme.dashlet}>
          <div style={stylesWithTheme.dashletTitle}>{title}</div>
          <div style={stylesWithTheme.dashletCaption}>{caption}</div>
        </div>
      </div>
    );
  };


  checkQueryParam(field) {
    let url = window.location.href;
    if (url.indexOf(`?${field}=`) != -1) {
      return true;
    } else if (url.indexOf(`&${field}=`) != -1) {
      return true;
    }
    return false;
  }

  badgeSelected(id, bgColor) {
    let {
      badges, isPrivate, isPublic, isCustom, showCoronadoErDates, lifeCycleBadgeId, childPost,
    } = this.state;
    if (id === 'Private-staff') {
      isPrivate = !isPrivate;
    } else if (id === 'Public') {
      isPublic = !isPublic;
      // if (isPublic) {
      //   badges = {};
      // }
    } else if (id === 'Superhero') {
      isCustom = !isCustom;
    } else if (badges[id]) {
      delete badges[id];
    } else {
      badges[id] = true;
    }

    if (lifeCycleBadgeId === id) {
      showCoronadoErDates = badges[id];
      this.setState({ coronadoErDayColor: bgColor });

      if (isEmpty(childPost)) {
        this.setState({ // Prepare data for child job_posting
          childAvailableDates: [],
          childTakenDates: [],
          childPendingDates: [],
          childMultipleNeeds: {},
          childConfirmedTakenDates: [],
          childConfirmedMultipleNeeds: {},
          childCalendarInitDisplayDate: null, // this.getEarliestSelectedDate(availableDates, takenDates, pendingDates, multipleNeedsDates),
        });
      }
    }

    // if (!isPublic && !isPrivate) {
    //   isPublic = true;
    // }
    // if (Object.keys(badges).length) {
    //   isPublic = false;
    // }
    this.setState({
      badges,
      isPublic,
      isCustom,
      isPrivate,
      showCoronadoErDates,
    });
  }

  getBadge(title, id, isLocked, isDisabled) {
    const { lifeCycleBadgeId } = this.state;
    const { badgeFilter } = commonResourceStyle(this.props.theme);
    const bgColor = badgeColor(isLocked || id === lifeCycleBadgeId ? title : (id || title));

    return (
      <Button
        onClick={() => !isDisabled && this.badgeSelected(id || title, bgColor)}
        key={title}
        sx={{
          backgroundColor: bgColor,
          ...badgeFilter,
          marginTop: 0,
          '&:hover': {
            backgroundColor: bgColor,
          },
        }}
      >
        {title}
      </Button>
    );
  }


  renderExtraTalentField = (extraTalentField, isEdit) => {
    const { tempTitle, talentType } = this.state;

    if (!extraTalentField) {
      return null;
    }
    let experienceTypeConfig = EXPERIENCE_TYPE_CONFIG[extraTalentField.key];

    if (['sub-category', 'vet-sub-category'].includes(extraTalentField.key)) {
      // Hide telemed option when it's not editing
      if (!isEdit) {
        experienceTypeConfig.items = experienceTypeConfig.items.filter(item => item.key !== 'telemedicine-vet');
      }
      let value = this.state[extraTalentField.key];

      if (Array.isArray(value) && value.length) {
        if (value.length > 1) {
          value = 'all';
        } else {
          value = value[0].key;
        }
      }

      return (
        <SingleSearchableSelect
          disabled={isEdit}
          value={value || ''}
          title={experienceTypeConfig.title}
          items={experienceTypeConfig.items}
          onChange={(value) => {
            this.setState({ [extraTalentField.key]: value });

            let category = categoryText[value] ? categoryText[value] : tempTitle.category;
            if (tempTitle.category && value === 'all') {
              if (['VA', 'Vet Tech'].includes(tempTitle.category)) {
                category = 'Vet Tech / VA'; // both
              } else if (tempTitle.category.includes('DVM')) {
                category = 'GP / ER DVM'; // both
              }
            }
            this.setTitle('category', category);
          }}
          form
          isRequired
        />
      );
    }

    return (
      <TalentExperienceItem
        disabled={isEdit}
        key={extraTalentField.key}
        type={extraTalentField.key}
        config={experienceTypeConfig}
        customWidth={12}
        state={this.state}
        setState={(valueObj) => {
          if (talentType === 'non-clinical') {
            let subCategory = '';
            const cats = valueObj && valueObj['job-category'];
            cats && cats.forEach(c => subCategory = categoryText[c.key] ? subCategory ? `${subCategory} ${categoryText[c.key]}` : categoryText[c.key] : '');
            this.setTitle('category', subCategory || 'Non-Clinical');
          }
          this.setState(valueObj);
        }}
        isRequired={this.isRequiredField(extraTalentField)}
      />
    );
  };

  handleArchiveAndActive = () => {
    const { actions, apiToken } = this.props;
    const {
      id,
      deletedAt,
      badges,
      lifeCycleBadgeId,
      childPost,
    } = this.state;

    const coronadoErBadgeSelected = Object.keys(badges).filter(x => Number(x) === Number(lifeCycleBadgeId) && badges[x]).length === 1;

    const fieldsToBeUpdated = { deletedAt: deletedAt ? null : new Date() };
    actions.updateResource(apiToken, fieldsToBeUpdated, 'jobpostings', id)
      .then((res) => {
        if (coronadoErBadgeSelected && childPost?.id) {
          const childFieldsToBeUpdated = { deletedAt: childPost.deletedAt ? null : new Date() };
          actions.updateResource(apiToken, childFieldsToBeUpdated, 'jobpostings', childPost?.id);
        }
        return res;
      })
      .then((res) => {
        if (!res.error) {
          this.setState({ deletedAt: res.response.deletedAt });
        }
      });
  };

  saveButtonTitle = (isEdit, isArchived) => {
    let title = '';
    if (isEdit && isArchived) {
      title = 'Save As An Archived Template';
    } else if (isEdit) {
      title = 'Save';
    } else {
      title = 'Publish';
    }
    return title;
  };

  isRequiredField = extraTalentField => (EXPERIENCE_TYPE_CONFIG[extraTalentField.key].title === 'Specialty'
    || EXPERIENCE_TYPE_CONFIG[extraTalentField.key].title === 'Job Category');

  isInactivePractice = () => {
    const { resources } = this.props;
    const { adminPractices } = resources;
    const { practice_id } = this.state;
    const activePractices = adminPractices && adminPractices.filter(practice => !practice.deletedAt && practice.active);
    return activePractices?.find(x => x.id === practice_id) ? false : true;
  };

  anyDateSelected = () => !isEmpty(this.getAllDateValues('available'))
    || !isEmpty(this.getAllDateValues('taken'))
    || !isEmpty(this.getAllDateValues('pending'))
    || !isEmpty(this.getAllDateValues('available', true))
    || !isEmpty(this.getAllDateValues('taken', true))
    || !isEmpty(this.getAllDateValues('pending', true));

  renderActionButtons = () => {
    const {
      edit,
      history,
      classes,
      admin,
      theme,
    } = this.props;
    const {
      prevTitle, id, practice_id, talentType, typeOfEmployment, description, deletedAt, childPost,
    } = this.state;
    const isHQAdmin = admin && admin.adminRole === HQ_ADMIN_KEY;
    const isDuplicate = prevTitle && !edit;
    const isEdit = id && edit;
    const archived = !!deletedAt && !isDuplicate;
    const extraTalentField = EXPERIENCE_CATEGORY_SPECIAL_FIELD_CONFIG[talentType];
    const extraTallentFieldHasValue = extraTalentField
      ? this.state[extraTalentField.key] && (this.state[extraTalentField.key].length > 0)
      : true;

    let isShiftDateValid = true;
    if (isReliefOrExternshipType(typeOfEmployment)) {
      isShiftDateValid = this.anyDateSelected();
    }

    let isPostTime = false;

    if (isEmpty(this.state.startTime) || isEmpty(this.state.endTime)) {
      isPostTime = true;
    } else {
      isPostTime = false;
    }

    const isDisabledSave = !(
      practice_id
      && typeOfEmployment
      && isShiftDateValid
      && talentType
      && description
      && extraTallentFieldHasValue
      && (!this.state.isCustom || this.state.selectedCustomPrivateUser.length > 0)
      && !isPostTime
    );

    const stylesWithTheme = commonResourceStyle(theme);

    return (
      <div className={classes.jobPostingActionBtns}>
        {isEdit && isHQAdmin && !archived && (
          <Button
            variant="contained"
            sx={stylesWithTheme.archiveFormBtn}
            onClick={() => this.handleArchiveAndActive()}
          >
            <Icon sx={stylesWithTheme.archiveFormBtnIcon}>move_to_inbox</Icon>
            Archive this Position
          </Button>
        )}
        {isEdit && isHQAdmin && (
          <Button
            variant="contained"
            sx={(practice_id && typeOfEmployment && talentType && description) && stylesWithTheme.formBtn2 || {}}
            onClick={() => {
              // if the practice is inactive/archived, then display it as a duplicate to input another practice
              if (this.isInactivePractice()) {
                this.setState({
                  practice_id: null,
                }, () => history.push(`/newposition/${id}`));
              } else {
                !this.state.isSavingAsNew && this.save(true, isDuplicate);
              }
            }}
            disabled={isDisabledSave}
            endIcon={this.state.isSavingAsNew ? <CircularProgress size={20}/> : null}
          >
            Publish as a New Position
          </Button>
        )}
        <Button
          variant="contained"
          sx={stylesWithTheme.formBtn}
          onClick={() => !this.state.isSaving && this.save(false, isDuplicate)}
          disabled={isDisabledSave}
          endIcon={this.state.isSaving ? <CircularProgress size={20}/> : null}
        >
          {this.saveButtonTitle(isEdit, archived)}
        </Button>
      </div>
    );
  };

  setDates = (availableDates, takenDates, pendingDates, multipleNeeds, isChild) => {
    const {
      childPost,
      childConfirmedMultipleNeeds,
      childConfirmedTakenDates,
      childTempConfirmedData,
    } = this.state;

    const multipleNeedsDates = !isEmpty(multipleNeeds) ? Object.keys(multipleNeeds).map(dayKey => DateUtilities.midDayDate(dayKey)) : [];
    const data = {
      availableDates,
      takenDates,
      multipleNeeds,
      pendingDates,
      calendarInitDisplayDate: this.getEarliestSelectedDate(availableDates, takenDates, pendingDates, multipleNeedsDates),
    };
    if (!isChild) {
      this.setState({
        ...data,
        calendarDialog: false,
      }, () => {
        const {
          confirmedMultipleNeeds,
          confirmedTakenDates,
          tempConfirmedData = [],
          jobPostingCalendar,
        } = this.state;

        const confirmTakenDates = this.setConfirmTakenDates({
          multipleNeeds: data.multipleNeeds,
          takenDates: data.takenDates,
          availableDates: data.availableDates,
          existingCalendar: jobPostingCalendar,
          confirmedMultipleNeeds,
          confirmedTakenDates,
          tempConfirmedData,
        });
        this.setState({ openConfirmation: confirmTakenDates.length > 0, confirmTakenDates });
        if(isEmpty(confirmTakenDates)) {
          this.setDates(this.state.childAvailableDates || [], this.state.childTakenDates || [], this.state.childPendingDates || [], this.state.childMultipleNeeds || {}, true);
        }
      });
    } else { // isChild
      this.setState({
        calendarCoronadoErDialog: false,
        childMultipleNeeds: data.multipleNeeds,
        childTakenDates: data.takenDates,
        childAvailableDates: data.availableDates,
        childCalendarInitDisplayDate: data.calendarInitDisplayDate,
      }, () => {
        const confirmTakenDates = this.setConfirmTakenDates({
          multipleNeeds: data.multipleNeeds,
          takenDates: data.takenDates,
          availableDates: data.availableDates,
          existingCalendar: childPost.calendar,
          confirmedMultipleNeeds: childConfirmedMultipleNeeds || {},
          confirmedTakenDates: childConfirmedTakenDates || [],
          tempConfirmedData: childTempConfirmedData || [],
        });
        this.setState({
          openChildConfirmation: confirmTakenDates.length > 0,
          childConfirmTakenDates: confirmTakenDates,
        });
        if (isEmpty(confirmTakenDates)) {
          this.setState({ canSave: true }, () => this.save(false, false));
        }
      });
    }
    const { actions, apiToken } = this.props;
    actions.addResource(apiToken, { action: 'admin_calendar' }, 'users', 'log-event');
  };

  setConfirmTakenDates = ({
                            multipleNeeds,
                            takenDates,
                            availableDates,
                            existingCalendar, // <== jobPostingCalendar

                            confirmedMultipleNeeds = {},
                            confirmedTakenDates = [],
                            tempConfirmedData = [],
                          }) => {
    let newTakenDates = takenDates.filter(takenDate => !DateUtilities.dateIn(confirmedTakenDates, takenDate));
    let untakenDates = confirmedTakenDates.filter(confirmedTakenDate => !DateUtilities.dateIn(takenDates, confirmedTakenDate));

    // Not ask again the taken in multiple, and turns to be single
    // --> check if the newTakenDates were in confirmedMultipleNeeds and taken > 0 --> filter out
    newTakenDates = newTakenDates.filter(date => !(confirmedMultipleNeeds[formatISODateOnly(date)]
      && confirmedMultipleNeeds[formatISODateOnly(date)].taken > 0));

    let confirmTakenDates = newTakenDates.map(date => ({ date }));

    // Set data for untaken
    const calendarData = existingCalendar ? existingCalendar.map(item => ({
      ...item,
      date: DateUtilities.midDayDate(item.date),
    })) : [];
    let confirmUntakenDates = [];

    untakenDates?.forEach((date) => {
      confirmUntakenDates.push({
        date,
        isUntaken: true,
        takenDataRows: calendarData.filter(row => row.status === 'taken' && DateUtilities.isSameDay(date, row.date)),
      });
    });


    confirmTakenDates = confirmTakenDates.concat(confirmUntakenDates);

    Object.keys(multipleNeeds).forEach((dayKey) => {
      const dateObj = multipleNeeds[dayKey];
      const confirmedDateObj = confirmedMultipleNeeds[dayKey];
      if (dateObj.taken > 0 && (!confirmedDateObj || (confirmedDateObj && confirmedDateObj.taken < dateObj.taken))) {
        let newTakenCount = confirmedDateObj ? dateObj.taken - confirmedDateObj.taken : dateObj.taken;

        // Not ask again the taken in confirmedTakenDates, turns from single to multiple
        // --> check if the dateObj.date were in confirmedTakenDates --> minus 1 taken shift
        const confirmTakenDate = confirmedTakenDates.filter(singleDate => DateUtilities.isSameDay(singleDate, dateObj.date));
        newTakenCount = !isEmpty(confirmTakenDate) ? newTakenCount - 1 : newTakenCount;

        if (newTakenCount > 0) {
          for (let i = 1; i <= newTakenCount; i++) {
            const confirmObj = {
              date: dateObj.date,
              shift: i,
              hasMultipleNeeds: true,
              hasMultipleTakensOnly: dateObj.available + (dateObj.pending || 0) > 0 ? false : true,
              available: dateObj.available,
              taken: dateObj.taken,
              pending: dateObj.pending,
            };
            confirmTakenDates.push(confirmObj);
          }
        }
        // Not ask again the untaken in confirmedTakenDates, turns from single to multiple
        confirmTakenDates = confirmTakenDates.filter(item => !(DateUtilities.isSameDay(item.date, dateObj.date) && item.isUntaken));
      }
    });

    Object.keys(confirmedMultipleNeeds).forEach((dayKey) => {
      const dateObj = multipleNeeds[dayKey] || {};
      const confirmedDateObj = confirmedMultipleNeeds[dayKey];

      // Calculation for untaken
      if (confirmedDateObj && confirmedDateObj.taken > (dateObj?.taken || 0)) { // taken decreases
        let untakenCount = confirmedDateObj.taken - (dateObj?.taken || 0);

        // Not ask again the taken in takenDates, turns from multiple to single
        // --> check if the date were in takenDates --> minus 1 untaken shift
        const takenDate = takenDates ? takenDates.filter(singleDate => DateUtilities.isSameDay(singleDate, confirmedDateObj.date)) : [];
        untakenCount = !isEmpty(takenDate) ? untakenCount - 1 : untakenCount;

        // The date turns from multiple to single avai
        const avaiDate = availableDates ? availableDates.filter(singleDate => DateUtilities.isSameDay(singleDate, confirmedDateObj.date)) : [];

        if (untakenCount > 0) {
          // Single untaken should be displayed as multiple
          if (takenDate.length && confirmTakenDates.length) {
            confirmTakenDates = confirmTakenDates.filter(obj => !DateUtilities.isSameDay(obj.date, takenDate[0]));
          }

          const takenDataRows = calendarData.filter(row => row.status === 'taken' && DateUtilities.isSameDay(confirmedDateObj.date, row.date));
          for (let i = 1; i <= untakenCount; i++) {
            const confirmedObj = {
              date: confirmedDateObj.date,
              shift: i,
              hasMultipleNeeds: true,
              hasMultipleTakensOnly: (dateObj.available || avaiDate.length) + (dateObj.pending || 0) > 0 ? false : true,
              available: dateObj.available || avaiDate.length,
              taken: dateObj.taken || takenDate.length,
              pending: dateObj.pending,
              isUntaken: true,
              takenDataRows,
            };
            confirmTakenDates.push(confirmedObj);
          }
        }
      }
    });

    confirmTakenDates = sortBy(confirmTakenDates, ['date', 'shift']);

    // Reset temp data to the new list
    if (!isEmpty(tempConfirmedData)) {
      confirmTakenDates.forEach((dateRow) => {
        const tempData = tempConfirmedData.find(tempObj => DateUtilities.isSameDay(dateRow.date, tempObj.date) && dateRow.shift === tempObj.shift) || {};
        dateRow.confirmationCategory = tempData.confirmationCategory || 'known_taken';
        dateRow.selectedTalentUser = tempData.selectedTalentUser;
        dateRow.selectedAgencyUser = tempData.selectedAgencyUser;
        dateRow.selectedOtherUser = tempData.selectedOtherUser;
        dateRow.confirmation = tempData.confirmation;
        dateRow.talentUserId = tempData.talentUserId;

        // Re-assign the confirmation info of untaken
        if (dateRow.isUntaken) {
          dateRow.untakenRowId = tempData.untakenRowId;
        }
      });
    }
    return confirmTakenDates;
  };

  isBadgeHidden = (badge, aggregatorId) => (
    badge.isShared && (!badge.nonSharedAggregators
      || !badge.nonSharedAggregators.some(id => Number(id) === aggregatorId))
  );

  renderBoostDescriptionBoxes = () => {
    const { classes, theme } = this.props;
    const { isBoosted, staffDescription, contractDescription } = this.state;
    const stylesWithTheme = commonResourceStyle(theme);

    return (
      isBoosted
      && (
        <div className={classes.boostDescriptionContainer}>
          <div className={classes.boostLeftDescriptionWrapper}>
            <TextInput
              value={staffDescription || ''}
              title="Private-Staff Boost:"
              onChange={staffDescription => this.setState({ staffDescription })}
              multiline
              rows={BOOST_DESCRIPTION_MAX_ROW}
              helpMessage={BOOST_DESCRIPTION_HELP_TEXT}
              maxlength={BOOST_DESCRIPTION_MAX_LENGTH}
            />
            <Typography
              sx={stylesWithTheme.boostCharCounterText}
            >
              {`Characters: ${staffDescription ? staffDescription.length : 0}`}
            </Typography>
          </div>
          <div className={classes.boostRightDescriptionWrapper}>
            <TextInput
              value={contractDescription || ''}
              title="Contract Boost:"
              onChange={contractDescription => this.setState({ contractDescription })}
              multiline
              rows={BOOST_DESCRIPTION_MAX_ROW}
              helpMessage={BOOST_DESCRIPTION_HELP_TEXT}
              maxlength={BOOST_DESCRIPTION_MAX_LENGTH}
            />
            <Typography
              sx={stylesWithTheme.boostCharCounterText}
            >
              {`Characters: ${contractDescription ? contractDescription.length : 0}`}
            </Typography>
          </div>
        </div>
      )
    );
  };

  // Returns an array of unique date objects
  getAllDateValues = (status, isChild) => {
    let availableDates;
    let takenDates;
    let pendingDates;
    let multipleNeeds;

    if (isChild) {
      const {
        childAvailableDates,
        childTakenDates,
        childMultipleNeeds,
      } = this.state;
      availableDates = childAvailableDates || [];
      takenDates = childTakenDates || [];
      pendingDates = [];
      multipleNeeds = childMultipleNeeds || {};
    } else {
      availableDates = this.state.availableDates || [];
      takenDates = this.state.takenDates || [];
      pendingDates = this.state.pendingDate || [];
      multipleNeeds = this.state.multipleNeeds || {};
    }

    const singleNeedDates = status === 'available' ? availableDates : status === 'taken' ? takenDates : pendingDates;
    const multipleNeedsDates = multipleNeeds
      ? Object.keys(multipleNeeds)
        .filter(dayKey => multipleNeeds[dayKey][status] > 0)
        .map(dayKey => DateUtilities.midDayDate(dayKey))
      : [];

    return uniq([...singleNeedDates, ...multipleNeedsDates]
      .sort((date1, date2) => (date1 - date2)));
  };

  checkDisableBadge = (badge) => {
    const { admin, edit } = this.props;
    const { lifeCycleBadgeId, childPost, typeOfEmployment } = this.state;

    const isHQAdmin = admin && admin.adminRole === 'HQ_ADMIN';
    const forceForJobTypesConfig = badge && badge.config && !isEmpty(badge.config.forceForJobTypes);

    if (forceForJobTypesConfig && !isHQAdmin) {
      return true;
    }

    if (edit && !isHQAdmin && typeOfEmployment === EXTERNSHIP) {
      return true;
    }

    if (edit && lifeCycleBadgeId === badge.id && childPost?.id) {
      return true;
    }

    return false;
  };

  setTitle = (key, val) => {
    const { tempTitle } = this.state;
    tempTitle[key] = val;
    this.setState({ tempTitle });
  };

  getPositionTypeText = positionType => (positionType === 'Relief' ? 'Shifts' : positionType == 'Externship' ? 'Dates' : 'Shift');

  setRefCalendar = () => {
    const {
      availableDates,
      childAvailableDates,
      takenDates,
      childTakenDates,
      multipleNeeds,
      childMultipleNeeds,
    } = this.state;

    let refMultipleNeeds = cloneDeep(multipleNeeds);
    const singleAvai = [...availableDates, ...childAvailableDates];
    let refAvailableDates = differenceWith(singleAvai, availableDates, DateUtilities.isSameDay);
    refAvailableDates = refAvailableDates.concat(differenceWith(singleAvai, childAvailableDates, DateUtilities.isSameDay));

    const singleTaken = [...takenDates, ...childTakenDates];
    let refTakenDates = differenceWith(singleTaken, takenDates, DateUtilities.isSameDay);
    refTakenDates = refTakenDates.concat(differenceWith(singleTaken, childTakenDates, DateUtilities.isSameDay));

    const tunrToMultiAvai = intersectionWith(availableDates, childAvailableDates, DateUtilities.isSameDay);
    const tunrToMultiTaken = intersectionWith(takenDates, childTakenDates, DateUtilities.isSameDay);

    let turnToMultiAvaiTaken = intersectionWith(refAvailableDates, refTakenDates, DateUtilities.isSameDay);
    if (!isEmpty(turnToMultiAvaiTaken)) {
      refAvailableDates = differenceWith(refAvailableDates, turnToMultiAvaiTaken, DateUtilities.isSameDay);
      refTakenDates = differenceWith(refTakenDates, turnToMultiAvaiTaken, DateUtilities.isSameDay);
    }

    !isEmpty(childMultipleNeeds) && Object.keys(childMultipleNeeds).forEach((childDayKey) => {
      const childObj = childMultipleNeeds[childDayKey];
      if (refMultipleNeeds[childDayKey]) {
        refMultipleNeeds[childDayKey].available += childObj.available;
        refMultipleNeeds[childDayKey].taken += childObj.taken;
      } else {
        refMultipleNeeds[childDayKey] = {
          available: childObj.available,
          taken: childObj.taken,
        };
      }
    });

    tunrToMultiAvai.length && tunrToMultiAvai.forEach((date) => {
      const dayKey = formatISODateOnly(date);
      if (refMultipleNeeds[dayKey]) {
        refMultipleNeeds[dayKey].available++;
      } else {
        refMultipleNeeds[dayKey] = {
          available: 2,
          taken: 0,
          date: DateUtilities.midDayDate(dayKey),
        };
      }
    });

    tunrToMultiTaken.length && tunrToMultiTaken.forEach((date) => {
      const dayKey = formatISODateOnly(date);
      if (refMultipleNeeds[dayKey]) {
        refMultipleNeeds[dayKey].taken++;
      } else {
        refMultipleNeeds[dayKey] = {
          available: 0,
          taken: 2,
          date: DateUtilities.midDayDate(dayKey),
        };
      }
    });

    turnToMultiAvaiTaken.length && turnToMultiAvaiTaken.forEach((date) => {
      const dayKey = formatISODateOnly(date);
      if (refMultipleNeeds[dayKey]) {
        refMultipleNeeds[dayKey].available++;
        refMultipleNeeds[dayKey].taken++;
      } else {
        refMultipleNeeds[dayKey] = {
          available: 1,
          taken: 1,
          date: DateUtilities.midDayDate(dayKey),
        };
      }
    });

    // Sum up date from single into multiple, then remove the single, ie: 2 multi avai + 1 single avai --> 3 multi avai
    const refAvailableDateKeys = refAvailableDates?.map(d => formatISODateOnly(d));
    const refTakenDateKeys = refTakenDates?.map(d => formatISODateOnly(d));

    !isEmpty(refMultipleNeeds) && Object.keys(refMultipleNeeds).forEach((dayKey) => {
      const refObj = refMultipleNeeds[dayKey];
      if (refAvailableDateKeys.indexOf(dayKey) > -1) {
        const removedAvaiDate = DateUtilities.midDayDate(dayKey);
        refObj.available++;
        refAvailableDates = refAvailableDates.filter(d => !DateUtilities.isSameDay(d, removedAvaiDate));
      }
      if (refTakenDateKeys.indexOf(dayKey) > -1) {
        refObj.taken++;
        const removedTakenDate = DateUtilities.midDayDate(dayKey);
        refTakenDates = refTakenDates.filter(d => !DateUtilities.isSameDay(d, removedTakenDate));
      }
    });

    this.setState({
      refAvailableDates,
      refTakenDates,
      refMultipleNeeds,
    }, () => {
      this.setState({ refCalendarDialog: true });
    });
  };

  onTimepickerChange = (field, value) => {
    if (field === 'startTime') {
      this.setState({ startTime: value, tempTitle: { ...this.state.tempTitle, startTime: value } });
    } else if (field === 'endTime') {
      this.setState({ endTime: value, tempTitle: { ...this.state.tempTitle, endTime: value } });
    }
  };

  renderJobPostingTime = (startTime, endTime) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
      <TimePicker
        label="Start time"
        isRequired
        defaultValue={startTime}
        onChange={value => this.onTimepickerChange('startTime', value)}
      />
      <TimePicker
        label="End time"
        isRequired
        defaultValue={endTime}
        onChange={value => this.onTimepickerChange('endTime', value)}
      />
    </div>
  );

  checkThriveBadge = (isEdit, badge) => {
    const isThrive = badge && badge.title === 'Thrive' && isEmpty(badge.deletedAt);
    const { badges } = this.state;

    if (!isEdit && isThrive) {
      if (badges && !badges.hasOwnProperty(badge.id)) {
        setTimeout(() => {
          this.setState({ badges: { ...badges, [badge.id]: true } });
        }, 50);
        return true;
      }
    }

    return false;
  };

  render() {
    const {
      loading, viewMoreLoading, edit, resources, history, classes, admin, match, theme, isDesktop,
    } = this.props;
    const { jobpostingId } = match.params;
    const { adminPractices, badges } = resources;
    const {
      prevTitle,
      title,
      id,
      practice_id,
      practice,
      talentType,
      typeOfEmployment,
      description,
      showDetails,
      isPrivate,
      isPublic,
      deletedAt,
      help,
      helpMsg,
      isCustom,
      staffList,
      archiveDialog,
      isBoosted,
      aggregatorId,
      availableDates,
      multipleNeeds,
      takenDates,
      pendingDates,
      initPendingDates,
      calendarInitDisplayDate,
      openConfirmation,
      tempTitle,
      manualTitle,
      automatedTitle,
      showCoronadoErDates,
      coronadoErDayColor,
      disabledRange,
      lifeCycleBadgeId,
      lifeCycleBadgeTitle,
      childPost,
      childAvailableDates,
      childTakenDates,
      childPendingDates,
      childMultipleNeeds,
      childCalendarInitDisplayDate,
      openChildConfirmation,
      rotationTypes,
    } = this.state;

    const isHQAdmin = admin && admin.adminRole === HQ_ADMIN_KEY;
    const filteredBadgesWithLocked = badges && badges.filter((badge) => {
      if (badge.aggregator_id === aggregatorId) {
        if (badge.title === 'Student') {
          if (badge.config && (isHQAdmin || (!isHQAdmin && admin.isExternshipAdmin)) && admin.isExternshipAdmin && typeOfEmployment === EXTERNSHIP) {
            return true;
          }
          return false;
        }

        if (badge.config && badge.config.defaultHidden) {
          const practice = adminPractices && adminPractices.find(x => x.id === practice_id);
          const checkTypeOfEmployment = typeOfEmployment && badge.config.forceForJobTypes && badge.config.forceForJobTypes.includes(typeOfEmployment);
          const practiceBadgeConfig = practice && practice.badgeConfig;
          const visibleBadges = practiceBadgeConfig && practiceBadgeConfig.visible_badges;
          const practiceBadge = visibleBadges && visibleBadges.find(x => x.id === badge.id);

          if ((isHQAdmin || (!isHQAdmin && admin.isExternshipAdmin)) && checkTypeOfEmployment && practiceBadge) {
            return true;
          }

          return false;
        }
        return true;
      }
      return this.isBadgeHidden(badge, aggregatorId);
    }).sort((a, b) => a.sortIndex - b.sortIndex);
    const filteredBadges = filteredBadgesWithLocked && filteredBadgesWithLocked.filter(badge => !badge.isLocked);
    const lockedBadges = filteredBadgesWithLocked && filteredBadgesWithLocked.filter(badge => badge.isLocked);
    const showLocked = lockedBadges && lockedBadges.length;
    const isNew = !prevTitle;
    const isDuplicate = prevTitle && !edit;
    const isEdit = id && edit;
    const archived = !!deletedAt && !isDuplicate;
    const extraTalentField = EXPERIENCE_CATEGORY_SPECIAL_FIELD_CONFIG[talentType];
    const isEditable = isEdit || isNew || isDuplicate;
    const activePractices = adminPractices && adminPractices.filter(practice => !practice.deletedAt && practice.active);
    const isInactivePractice = this.isInactivePractice();
    const avaiDateTextValues = this.getAllDateValues('available').map(date => `${moment(date).format('MMM.DD')} `).join('/ ');
    const takenDateTextValues = this.getAllDateValues('taken').map(date => `${moment(date).format('MMM.DD')} `).join('/ ');
    const pendingDateTextValues = this.getAllDateValues('pending').map(date => `${moment(date).format('MMM.DD')} `).join('/ ');

    const childAvaiDateTextValues = childPost ? this.getAllDateValues('available', true).map(date => `${moment(date).format('MMM.DD')} `).join('/ ') : '';
    const childTakenDateTextValues = childPost ? this.getAllDateValues('taken', true).map(date => `${moment(date).format('MMM.DD')} `).join('/ ') : '';

    const isDisabledExternshipPost = isEdit && !isHQAdmin && typeOfEmployment === EXTERNSHIP;
    const showPublicBadge = !isTPHCUser(admin);
    const employeeTypesJob = (!isEdit && !isHQAdmin) ? EMPLOYMENT_TYPES_OBJ.filter(x => x.key !== EXTERNSHIP) : EMPLOYMENT_TYPES_OBJ;
    let displayTitle = '';
    if (isNew || isDuplicate) {
      displayTitle = `${tempTitle.positionType} ${tempTitle.category} ${this.getPositionTypeText(tempTitle.positionType)} ${tempTitle.extraText}`;
    } else if (automatedTitle) { // edit - only change the extra text
      const autoTitleHead = automatedTitle.split('^')[0];
      displayTitle = `${autoTitleHead} ${tempTitle.extraText}`;
    } else { // edit - not editable
      displayTitle = manualTitle;
    }

    if (!isEmpty(tempTitle.startTime) && !isEmpty(tempTitle.endTime)) {
      displayTitle += ` ${tempTitle.startTime}-${tempTitle.endTime}`;
    }

    const stylesWithTheme = commonResourceStyle(theme);
    return (
      ((!!loading && viewMoreLoading === 0) || (jobpostingId && !this.filteredJobPosting))
        ? <LoadingBar/>
        : (
          <div style={{ ...stylesWithTheme.container, height: '100%' }}>
            <Box sx={isEditable ? stylesWithTheme.jobPositionResourceHeader : stylesWithTheme.resourceHeader}>
              <div className={isEditable ? classes.jobPositionResourceName : classes.resourceName}>
                {
                  this.checkQueryParam('signUp')
                  && (
                    <div style={{
                      marginBottom: '15px',
                      display: 'flex',
                    }}
                    >
                      {this.getDashlet('STEP 1', 'Create a Practice', { opacity: '0.5' })}
                      {this.getDashlet('STEP 2', 'Create a Postion')}
                    </div>
                  )
                }
                <Typography style={isEditable ? stylesWithTheme.jobPositionResourceName : stylesWithTheme.resourceName}>
                  {isNew && 'Create New Post'}
                  {isDuplicate && `Copy Of ${formatPostTitle(prevTitle)}`}
                  {isEdit && 'Certified Veterinary'}
                </Typography>
              </div>
              {isDesktop && this.renderActionButtons()}
            </Box>
            <Paper style={isEditable ? stylesWithTheme.jobPositionPaper : stylesWithTheme.jobPositionPaperNoEdit}>
              <div className={classes.paperContainer}>
                <div className={classes.jobFormTitleContainer}>
                  <Typography style={stylesWithTheme.postTitle}>
                    {displayTitle}
                  </Typography>
                  {/* {this.state.isEnterprise && (isHQAdmin || (!isHQAdmin && admin.isShiftAdmin && typeOfEmployment !== EXTERNSHIP))
                    && (
                    <Button
                      variant="contained"
                      sx={stylesWithTheme.formBtn}
                      onClick={() => this.setState({ isBoosted: !this.state.isBoosted })}
                    >
                      {isBoosted ? 'Remove Boost' : 'Show Boost'}
                    </Button>
                    )} */}
                </div>

                {/* {!isDesktop && isBoosted && this.renderBoostDescriptionBoxes()} */}
                <Grid container={isDesktop} spacing={2}>
                  <Grid item md={6}>
                    {this.renderAdditionalTitle(isDisabledExternshipPost, isEdit, tempTitle)}
                    {this.renderBadges(isCustom, classes, stylesWithTheme, showLocked, isDisabledExternshipPost, isPrivate, lockedBadges, isEdit, showPublicBadge, isPublic, staffList, filteredBadges, lifeCycleBadgeId, help, helpMsg, isDesktop)}
                    {this.renderDuplicateButton(isEdit, isHQAdmin, stylesWithTheme, isInactivePractice, practice_id, history, id)}
                    <Grid container={isDesktop} spacing={2}>
                      <Grid item md={6}>
                        {this.renderPracticeInput(isEdit, isDuplicate, practice_id, isInactivePractice, practice, isNew, activePractices)}
                      </Grid>
                      <Grid item md={6}>
                        {this.renerPositionType(isEdit, typeOfEmployment, employeeTypesJob)}
                      </Grid>
                    </Grid>
                    {/*{this.renderOldCalendars(showCoronadoErDates, lifeCycleBadgeTitle, childAvaiDateTextValues, childTakenDateTextValues, isCustom, classes, stylesWithTheme, avaiDateTextValues, takenDateTextValues, pendingDateTextValues, childPost, childAvailableDates, childTakenDates, childMultipleNeeds, childCalendarInitDisplayDate, disabledRange, coronadoErDayColor, initPendingDates, pendingDates, calendarInitDisplayDate, availableDates, takenDates, multipleNeeds, typeOfEmployment)}*/}
                    {this.renderRotationTypes(admin, typeOfEmployment, stylesWithTheme, rotationTypes)}
                    <Grid container={isDesktop} spacing={2}>
                      <Grid item md={6}>
                        {this.renderJobCategory(isEdit, talentType)}
                      </Grid>
                      <Grid item md={6}>
                        {this.renderExtraTalentField(extraTalentField, isEdit)}
                      </Grid>
                    </Grid>
                    {this.renderDescriptionEditor(description, isDesktop, stylesWithTheme, isDisabledExternshipPost)}
                  </Grid>

                  <Grid item md={6} sx={stylesWithTheme.rightPanel}>
                    {this.renderJobPostingTime(this.state.startTime, this.state.endTime)}
                    {this.renderCalendar(isDesktop, stylesWithTheme, classes, availableDates, takenDates, pendingDates, multipleNeeds, disabledRange, calendarInitDisplayDate, coronadoErDayColor, isEdit, isHQAdmin, typeOfEmployment, childPost, childAvailableDates, childTakenDates, childPendingDates, childMultipleNeeds, childCalendarInitDisplayDate)}
                    {this.renderMultipleNeeds(classes)}
                    {this.renderBoost(classes)}
                  </Grid>
                  {showDetails && (
                    <Grid item md={12} sx={stylesWithTheme.editorGrid}>
                      <TalentExperience
                        onClose={e => this.setState({ showDetails: false })}
                        state={this.state}
                        setState={e => this.setState(e)}
                        isDesktop={isDesktop}
                      />
                    </Grid>
                  )
                  }
                </Grid>
              </div>
            </Paper>
            {!isDesktop && this.renderActionButtons()}
            {
              archiveDialog !== null
              && (
                <ArchiveDialog
                  open={archiveDialog !== null}
                  handleOnClick={type => this.handleClickDialog(type)}
                  resource="post"
                  resourceName={(isEdit && formatPostTitle(title)) || Object.values(tempTitle).join(' ')}
                  promptText="Archive post below from the system?"
                />
              )}
            {/*{*/}
            {/*  openConfirmation*/}
            {/*  && (*/}
            {/*    <CalendarConfirmationDialog*/}
            {/*      open={openConfirmation}*/}
            {/*      handleCallback={(tempConfirmedData) => {*/}
            {/*        this.setState({ openConfirmation: false });*/}
            {/*        if (tempConfirmedData) {*/}
            {/*          this.setState({*/}
            {/*            tempConfirmedData: tempConfirmedData.map(item => ({ ...item, isConfirmed: true })),*/}
            {/*          });*/}
            {/*        }*/}
            {/*        this.setDates(this.state.childAvailableDates || [], this.state.childTakenDates || [], this.state.childPendingDates || [], this.state.childMultipleNeeds || {}, true);*/}
            {/*      }}*/}
            {/*      confirmTakenDates={this.state.confirmTakenDates}*/}
            {/*      talentList={this.state.talentList || []}*/}
            {/*      agencyList={this.state.agencyList || []}*/}
            {/*    />*/}
            {/*  )*/}
            {/*}*/}
            {
              openChildConfirmation
              && (
                <CalendarConfirmationDialog
                  open={openChildConfirmation}
                  handleCallback={(tempConfirmedData) => {
                    this.setState({ openChildConfirmation: false });
                    if (tempConfirmedData) {
                      this.setState({
                        childTempConfirmedData: tempConfirmedData.map(item => ({ ...item, isConfirmed: true })),
                        canSave: true,
                      }, () => { this.save() });
                    }
                  }}
                  confirmTakenDates={this.state.childConfirmTakenDates}
                  talentList={this.state.talentList || []}
                  agencyList={this.state.agencyList || []}
                  coronadoErDayColor={coronadoErDayColor}
                />
              )
            }

          </div>
        )
    );
  }

  renderBoost(classes) {
    if (this.state.typeOfEmployment && isReliefOrExternshipType(this.state.typeOfEmployment)) {
      return (
        <Accordion sx={{
          marginTop: '16px',
          boxShadow: 'none',
          border: 'none',
          '&:before': {
            display: 'none',
          },
        }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            // aria-controls="panel2-content"
            id="panel2-header"
            sx={{
              '& .MuiAccordionSummary-content': {
                alignItems: 'center',
                flexGrow: 0,
                margin: '20px 0',
              },
              '&.MuiAccordionSummary-root': {
                justifyContent: 'left',
                padding: 0,
              },
            }}>
            <img
              src={`${IMG_STORAGE_LINK}dollar-navy.png`}
              style={{ width: 16, height: 16 }}
              alt='Boost'
              title='Boost'
            />
            <Typography className={classes.boostLink}>
              Boost / Sx Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {this.renderBoostDetails()}
          </AccordionDetails>
        </Accordion>
      );
    }
  }

  handleBoostChange(item, value, field) {
    const { boostDetails, childBoostDetails } = this.state;
    boostDetails && boostDetails.filter(i => i.id && i.id === item.id || i.dateText === item.dateText).forEach(i => i[field] = value);
    childBoostDetails && childBoostDetails.filter(i => i.id && i.id === item.id || i.dateText === item.dateText).forEach(i => i[field] = value);
    this.setState({ boostDetails, childBoostDetails });
  }

  renderBoostDetails() {
    const { classes } = this.props;
    const { boostDetails, childBoostDetails } = this.state;

    const singleDatesInMonth = !isEmpty(boostDetails) ? boostDetails.filter(r => DateUtilities.isSameMonth(r.date, this.state.displayDate || new Date())) : [];
    if (!isEmpty(childBoostDetails)) {
      childBoostDetails.filter(r => DateUtilities.isSameMonth(r.date, this.state.displayDate || new Date()) &&
        singleDatesInMonth.filter(x => DateUtilities.isSameDay(x.date, r.date)).length === 0)
        .forEach((childBoost) => singleDatesInMonth.push(childBoost));
    }
    return !isEmpty(singleDatesInMonth) && (
      <Grid container className={classes.boostContainer} key="boost-details">
        <Grid item xs={3} className={classes.boostItem} key="boost-details-date">
          <Typography>Dates</Typography>
        </Grid>
        <Grid item xs={2} className={classes.boostItem} key="boost-details-boost">
          <Typography>Boost</Typography>
        </Grid>
        <Grid item xs={3} className={classes.boostItem} key="boost-details-amount">
          <Typography>Amount ($)</Typography>
        </Grid>
        <Grid item xs={4} className={classes.boostItem} key="boost-details-surgery">
          <Typography>Surgery Needed</Typography>
        </Grid>
        {!isEmpty(singleDatesInMonth) && singleDatesInMonth.map((item, index) => (
          <Fragment key={`boost-${index}_root`}>
            <Grid item xs={3} key={`boost-${index}_item`} className={classes.boostItem}>
              <Typography key={`boost-date-${index}`}>{moment(item.date).format('MMM DD')}</Typography>
            </Grid>

            <Grid item xs={2} key={`boost-checkbox-${index}_item`} className={classes.boostItem}>
              <Checkbox
                onChange={(e) => {
                  this.handleBoostChange(item, e?.target?.checked, 'isBoosted');
                }}
                checked={item.isBoosted}
                key={`boost-checkbox-${index}`}
              />
            </Grid>
            <Grid item xs={3} key={`boost-amt-${index}_item`} className={classes.boostItem}>
              <TextField
                onChange={(e) => {
                  this.handleBoostChange(item, e?.target?.value, 'boostAmount');
                }}
                variant="outlined"
                inputProps={{
                  type: 'number',
                  min: 0,
                  step: 1,
                  style: { height: '28px', padding: '0 8px', width: '80px' },
                }}
                autoComplete="off"
                key={`boost-amt-${index}`}
                value={item.boostAmount}
                disabled={!item.isBoosted}
              />
            </Grid>
            <Grid item xs={4} key={`boost-surgery-${index}_item`} className={classes.boostItem}>
              <Checkbox
                onChange={(e) => {
                  this.handleBoostChange(item, e?.target?.checked, 'surgeryNeeded');
                }}
                checked={item.surgeryNeeded}
                key={`surgery-${index}`}
                sx={{
                  '& .MuiButtonBase-root': {
                    '& .MuiCheckbox-root': {
                      padding: 0,
                    },
                  },
                }}
              />
            </Grid>
          </Fragment>
        ))}
      </Grid>
    );
  };

  renderMultipleNeeds(classes) {
    if (this.state.typeOfEmployment && isReliefOrExternshipType(this.state.typeOfEmployment)) {
      return (
        <div>
          <FormControlLabel
            control={(
              <GreenSwitch
                color="primary"
                // checked={enabledNeedsClick}
                disableRipple
                onChange={e => this.setState({ enabledMultipleNeeds: e.target.checked })}
              />
            )}
            label='Multiple needs'
            className={classes.formControlLabelRoot}
          />
        </div>
      );
    }
  }

  dayClicked(date, event) {
    const {
      jobPostingCalendar,
      childPost,
      disabledRange,
      enabledMultipleNeeds,
      availableDates,
      takenDates,
      pendingDates,
      multipleNeeds,
      childAvailableDates,
      childTakenDates,
      childPendingDates,
      childMultipleNeeds,
      showCoronadoErDates,
      talentList,
    } = this.state;
    if (isEmpty(talentList)) {
      this.fetchAndSetTalentList();
    }
    let isChild = event?.child;
    const inChildRange = (date - new Date()) / (1000 * 60 * 60 * 24) > parseInt(disabledRange);
    if (!event && showCoronadoErDates && inChildRange) {
      isChild = true;
    }
    if(!inChildRange){
      isChild = false;
    }
    const containsDate = (dates, date) => dates.find(d => DateUtilities.isSameDay(d, date));
    const dateStr = formatISODateOnly(date);
    const multipleNeedsObjForDate = isChild ? childMultipleNeeds[dateStr] : multipleNeeds[dateStr];
    if (enabledMultipleNeeds || multipleNeedsObjForDate) {
      const initObj = {
        isChild,
        selectedDate: date,
        available: 0,
        taken: 0,
        pending: 0,
      };
      const otherMultipleNeedsForDate = isChild ? multipleNeeds[dateStr] : childMultipleNeeds[dateStr];
      const needsOnSelectedDay = Object.assign(initObj, isChild ? childMultipleNeeds[dateStr] : multipleNeeds[dateStr]);
      if (isChild && containsDate(takenDates, date) || !isChild && containsDate(childTakenDates, date)) {
        needsOnSelectedDay.taken += 1;
      }
      if (otherMultipleNeedsForDate) {
        needsOnSelectedDay.taken += otherMultipleNeedsForDate.taken;
      }
      if (inChildRange && !isChild || !inChildRange && isChild) {
        needsOnSelectedDay.maxAvailable = needsOnSelectedDay.available;
      }
      const coronadoErDayColor = isChild ? yellowCoronado : greenBoosted;
      this.setState({ openMultipleNeedsDialog: true, coronadoErDayColor, needsOnSelectedDay });
    } else {
      const nameAvialable = isChild ? 'childAvailableDates' : 'availableDates';
      const availables = isChild ? childAvailableDates : availableDates;
      const nameTaken = isChild ? 'childTakenDates' : 'takenDates';
      const takens = isChild ? childTakenDates : takenDates;
      const nameBoostDetails = isChild ? 'childBoostDetails' : 'boostDetails';
      const multipleNeeds = isChild ? this.state.childMultipleNeeds : this.state.multipleNeeds;
      const initBoostSurgeryData = isChild ? this.state.initBoostSurgeryData : this.state.childInitBoostSurgeryData;
      const state = {};
      if (containsDate(availables, date)) {
        state[nameAvialable] = availables.filter(d => !DateUtilities.isSameDay(d, date));
        state[nameTaken] = [...takens, date];
      } else if (containsDate(takens, date)) {
        state[nameTaken] = takens.filter(d => !DateUtilities.isSameDay(d, date));
      } else {
        state[nameAvialable] = [...availables, date];
      }
      state[nameBoostDetails] = setBoostSurgeryDetails({
        availableDates: state[nameAvialable] || availables,
        initBoostSurgeryData,
        multipleNeeds,
        boostDetails: this.state[nameBoostDetails],
        isChild,
      });
      this.setState(state);
    }
  }

  updateMultipleNeeds(needsObj) {
    const state = { openMultipleNeedsDialog: false };
    if (needsObj.selectedDate) {
      const nameMultipleNeeds = needsObj.isChild ? 'childMultipleNeeds' : 'multipleNeeds';
      const nameMultipleNeedsOther = needsObj.isChild ? 'multipleNeeds' : 'childMultipleNeeds';
      const needsOther = needsObj.isChild ? this.state.multipleNeeds : this.state.childMultipleNeeds;
      const nameAvialable = needsObj.isChild ? 'childAvailableDates' : 'availableDates';
      const availables = needsObj.isChild ? this.state.childAvailableDates : this.state.availableDates;
      const nameTaken = needsObj.isChild ? 'childTakenDates' : 'takenDates';
      const nameTakenOther = needsObj.isChild ? 'takenDates' : 'childTakenDates';
      const takens = needsObj.isChild ? this.state.childTakenDates : this.state.takenDates;
      const takensOther = needsObj.isChild ? this.state.takenDates : this.state.childTakenDates;
      const nameBoostDetails = needsObj.isChild ? 'childBoostDetails' : 'boostDetails';
      const initBoostSurgeryData = needsObj.isChild ? this.state.initBoostSurgeryData : this.state.childInitBoostSurgeryData;

      const needsOtherObj = needsOther[formatISODateOnly(needsObj.selectedDate)];
      if (needsOtherObj) {
        const curDateCoronado = (needsObj.selectedDate - new Date()) / (1000 * 60 * 60 * 24) > 60;
        if (curDateCoronado && !needsObj.isChild) {
          needsOther[formatISODateOnly(needsObj.selectedDate)] = {
            date: needsObj.selectedDate,
            taken: needsObj.taken,
            available: needsOtherObj.available,
            pending: needsOtherObj.pending,
            isChild: true,
          };
          const coronadoErDayColor = yellowCoronado;
          state[nameMultipleNeedsOther] = Object.assign({}, this.state[nameMultipleNeedsOther], needsOther);
          state.coronadoErDayColor = coronadoErDayColor;
          needsObj.taken = 0;
        }
      }
      const multipleNeeds = {};
      multipleNeeds[formatISODateOnly(needsObj.selectedDate)] = Object.assign({ date: needsObj.selectedDate }, needsObj);
      state[nameMultipleNeeds] = Object.assign({}, this.state[nameMultipleNeeds], multipleNeeds);
      state[nameTaken] = takens.filter(d => !DateUtilities.isSameDay(d, needsObj.selectedDate));
      state[nameTakenOther] = takensOther.filter(d => !DateUtilities.isSameDay(d, needsObj.selectedDate));
      state[nameAvialable] = availables.filter(d => !DateUtilities.isSameDay(d, needsObj.selectedDate));
      state[nameBoostDetails] = setBoostSurgeryDetails({
        availableDates: state[nameAvialable],
        multipleNeeds: state[nameMultipleNeeds],
        initBoostSurgeryData,
        boostDetails: this.state[nameBoostDetails],
        isChild: needsObj.isChild,
      });
    }
    this.setState(state);
  }

  renderCalendar(isDesktop, stylesWithTheme, classes, availableDates, takenDates, pendingDates, multipleNeeds, disabledRange, calendarInitDisplayDate, coronadoErDayColor, isEdit, isHQAdmin, typeOfEmployment, childPost, childAvailableDates, childTakenDates, childPendingDates, childMultipleNeeds, childCalendarInitDisplayDate) {
    if (this.state.typeOfEmployment && isReliefOrExternshipType(this.state.typeOfEmployment)) {
      const showCoronado = this.state.showCoronadoErDates;
      return (
        <>
          <Grid container={isDesktop} spacing={2} justifyContent="center" alignItems="center">
            <Grid item md={6}>
              <Typography className={classes.shiftDates}>
                Shift Dates
              </Typography>
            </Grid>
            <Grid item md={6}>
              {showCoronado && (<><Typography className={classes.calendarHelper}>
                &lt;{disabledRange} Days - Available for All
              </Typography>
              <Typography className={classes.coronadoHelper}>
                > {disabledRange} Days - Reserved for Coronado ER for 7 days
              </Typography></>)}
            </Grid>
          </Grid>
          <MultipleNeedsDialog
            open={this.state.openMultipleNeedsDialog || false}
            handleCallback={needsObj => this.updateMultipleNeeds(needsObj)}
            needsOnSelectedDay={this.state.needsOnSelectedDay || {}}
            coronadoErDayColor={coronadoErDayColor}
          />
          <JobpostingCalendar
            availableDates={availableDates}
            takenDates={takenDates}
            pendingDates={pendingDates}
            multipleNeeds={multipleNeeds}
            childAvailableDates={childAvailableDates}
            childTakenDates={childTakenDates}
            childPendingDates={childPendingDates}
            childMultipleNeeds={childMultipleNeeds}
            disabledRange={disabledRange}
            calendarInitDisplayDate={calendarInitDisplayDate}
            isDesktop={isDesktop}
            showCoronado={showCoronado}
            isEdit={isEdit}
            isHQAdmin={isHQAdmin}
            typeOfEmployment={typeOfEmployment}
            selectedDateCallback={date => this.setState({ displayDate: date })}
            boostDetails={this.state.boostDetails}
            enabledMultipleNeeds={this.state.enabledMultipleNeeds}
            childBoostDetails={this.state.childBoostDetails}
            dateClickCallback={(day, event)=>this.dayClicked(day, event)}
          />
        </>
      );
    }
  }

  renderDescriptionEditor(description, isDesktop, stylesWithTheme, isDisabledExternshipPost) {
    return (
      <EditorInput
        value={description || ''}
        title="Description"
        onChange={description => this.setState({ description })}
        customFormControlStyle={isDesktop ? stylesWithTheme.editorFormControlStyle : stylesWithTheme.mobileRichTextEditorStyle}
        isRequired
        disabled={isDisabledExternshipPost}
      />
    );
  }

  renderJobCategory(isEdit, talentType) {
    return (
      <SingleSearchableSelect
        disabled={isEdit}
        value={talentType || ''}
        title="Category"
        items={VET_TYPES}
        onChange={(talentType) => {
          this.setState({ talentType });
          this.setTitle('category', categoryText[talentType] || 'Category');
        }}
        form
        isRequired
      />
    );
  }

  renderRotationTypes(admin, typeOfEmployment, stylesWithTheme, rotationTypes) {
    return (
      <>
        {admin?.isExternshipAdmin && typeOfEmployment && typeOfEmployment === EXTERNSHIP && (
          <FormControl fullWidth style={stylesWithTheme.formControl}>
            <div style={stylesWithTheme.formControlLabelContainer}>
              <FormLabel sx={stylesWithTheme.formControlLabel}>
                Rotation Type
              </FormLabel>
            </div>

            <MultipleSelection
              value={rotationTypes || []}
              title='Please Select'
              items={ROTATION_TYPES}
              customLabelConfig={customLabelConfig}
              type='rotation-types'
              unSelctableTitle
              onChange={(value) => {
                this.setState({ rotationTypes: value });
              }}
              form
            />
          </FormControl>
        )}
      </>
    );
  }

  // renderOldCalendars(showCoronadoErDates, lifeCycleBadgeTitle, childAvaiDateTextValues, childTakenDateTextValues, isCustom, classes, stylesWithTheme, avaiDateTextValues, takenDateTextValues, pendingDateTextValues, childPost, childAvailableDates, childTakenDates, childMultipleNeeds, childCalendarInitDisplayDate, disabledRange, coronadoErDayColor, initPendingDates, pendingDates, calendarInitDisplayDate, availableDates, takenDates, multipleNeeds, typeOfEmployment) {
  //   return (
  //     <>
  //       {
  //         this.state.typeOfEmployment === 'Relief'
  //         && showCoronadoErDates && (
  //           <CalendarSelect
  //             title={`${lifeCycleBadgeTitle} Dates`}
  //             onClick={() => {
  //               if (isEmpty(this.state.talentList)) {
  //                 this.fetchAndSetTalentList();
  //               }
  //               this.setState({ calendarCoronadoErDialog: true });
  //             }}
  //             value={`${childAvaiDateTextValues ? `Avai: ${childAvaiDateTextValues}` : ''}`
  //               + `${childTakenDateTextValues ? ` - Taken: ${childTakenDateTextValues}` : ''}`}
  //             isRequired={!this.anyDateSelected()}
  //           />
  //         )
  //       }
  //       {this.state.typeOfEmployment === 'Relief'
  //         && showCoronadoErDates && (
  //           <div className={isCustom ? classes.badgesNarrowContainer : classes.badgesContainer}>
  //             <div style={stylesWithTheme.badgeTextContainer}>
  //               <Typography style={stylesWithTheme.badgeText}>
  //                 Reference Calendar
  //               </Typography>
  //               <CalendarMonthIcon
  //                 style={stylesWithTheme.helpOutlineIcon}
  //                 onClick={() => {
  //                   this.setRefCalendar();
  //                 }}
  //               />
  //             </div>
  //           </div>
  //         )}
  //       {
  //         this.state.typeOfEmployment
  //         && (isReliefOrExternshipType(this.state.typeOfEmployment))
  //         && (
  //           <CalendarSelect
  //             title="Select Specific Shift Dates"
  //             onClick={() => {
  //               if (isEmpty(this.state.talentList)) {
  //                 this.fetchAndSetTalentList();
  //               }
  //               const {
  //                 availableDates,
  //                 initBoostSurgeryData,
  //                 multipleNeeds,
  //                 boostDetails,
  //               } = this.state;
  //               const boostDataArr = setBoostSurgeryDetails({
  //                 availableDates,
  //                 initBoostSurgeryData,
  //                 multipleNeeds,
  //                 boostDetails,
  //               });
  //               this.setState({ boostDetails: boostDataArr, calendarDialog: true });
  //             }}
  //             value={`${avaiDateTextValues ? `Avai: ${avaiDateTextValues}` : ''}`
  //               + `${takenDateTextValues ? ` - Taken: ${takenDateTextValues}` : ''}`
  //               + `${pendingDateTextValues ? ` - Pending: ${pendingDateTextValues}` : ''}`}
  //             isRequired={!this.anyDateSelected()}
  //           />
  //         )
  //       }
  //       {showCoronadoErDates && childPost && (
  //         <MultipleDatePicker
  //           open={this.state.calendarCoronadoErDialog}
  //           onSubmit={(dates, nodates, pendingDates, multipleNeeds) => {
  //             this.setDates(dates, nodates, pendingDates, multipleNeeds, true);
  //           }}
  //           selectedDates={childAvailableDates}
  //           notAvailableDates={childTakenDates}
  //           initPendingDates={[]}
  //           pendingDates={[]}
  //           multipleNeeds={childMultipleNeeds}
  //           initialDate={childCalendarInitDisplayDate}
  //           onCancel={(e) => {
  //           }}
  //           onBackdropClick={() => this.setState({ calendarCoronadoErDialog: false })}
  //           disabledRange={disabledRange}
  //           coronadoErDayColor={coronadoErDayColor}
  //           lifeCycleBadgeTitle={lifeCycleBadgeTitle}
  //         />
  //       )}
  //
  //       {this.state.refCalendarDialog && (
  //         <MultipleDatePicker
  //           open={this.state.refCalendarDialog}
  //           onSubmit={() => {
  //           }}
  //           selectedDates={this.state.refAvailableDates}
  //           notAvailableDates={this.state.refTakenDates}
  //           initPendingDates={initPendingDates}
  //           pendingDates={pendingDates}
  //           multipleNeeds={this.state.refMultipleNeeds}
  //           initialDate={calendarInitDisplayDate}
  //           onCancel={() => {
  //           }}
  //           onBackdropClick={() => this.setState({ refCalendarDialog: false })}
  //           referenceOnly
  //         />
  //       )}
  //       <MultipleDatePicker
  //         open={this.state.calendarDialog}
  //         onSubmit={(dates, nodates, pendingDates, multipleNeeds, boostData) => {
  //           this.setState({ boostDetails: boostData });
  //           this.setDates(dates, nodates, pendingDates, multipleNeeds);
  //         }}
  //         selectedDates={availableDates}
  //         notAvailableDates={takenDates}
  //         initPendingDates={initPendingDates}
  //         pendingDates={pendingDates}
  //         multipleNeeds={multipleNeeds}
  //         showAllButton={typeOfEmployment === EXTERNSHIP}
  //         initialDate={calendarInitDisplayDate}
  //         onCancel={() => this.setState({ boostDetails: [] })}
  //         onBackdropClick={() => {
  //           this.setState({ calendarDialog: false, boostDetails: [] });
  //         }}
  //         jobPostingCalendar={this.state.jobPostingCalendar}
  //         outerBoostData={this.state.boostDetails}
  //         isReliefPost={typeOfEmployment === 'Relief'}
  //       />
  //     </>
  //   );
  // }

  renerPositionType(isEdit, typeOfEmployment, employeeTypesJob) {
    return (
      <SingleSearchableSelect
        disabled={isEdit}
        value={typeOfEmployment || ''}
        title="Position Type"
        helpMessage={INFO_TEXT}
        items={filterJobTypes(employeeTypesJob, this.props.admin, isEdit)}
        form
        isRequired
        onChange={(typeOfEmployment) => {
          this.setState({ typeOfEmployment });
          this.setTitle('positionType', positionTypeText[typeOfEmployment] || 'Position Type');
          this.setTitle('shift', this.getPositionTypeText(typeOfEmployment));
        }}
      />
    );
  }

  renderPracticeInput(isEdit, isDuplicate, practice_id, isInactivePractice, practice, isNew, activePractices) {
    return (
      <>
        {isEdit && !isDuplicate && practice_id && isInactivePractice
          && (
            <TextInput
              title="Practice Name"
              value={practice?.name || ''}
              disabled
            />
          )}
        {(isNew || !(isEdit && !isDuplicate && practice_id && isInactivePractice))
          && (
            <SingleSearchableSelect
              disabled={isEdit}
              value={!isInactivePractice ? practice_id : ''}
              title="Practice Name"
              items={activePractices || []}
              onChange={practice_id => this.setState({ practice_id })}
              isRequired
            />
          )}
      </>
    );
  }

  renderDuplicateButton(isEdit, isHQAdmin, stylesWithTheme, isInactivePractice, practice_id, history, id) {
    return (
      <>
        {isEdit && isHQAdmin && (
          <Typography style={stylesWithTheme.msg}>
            To change the major fields below (make a new job), please
            <Button
              color="primary"
              style={stylesWithTheme.redButton}
              onClick={() => {
                this.setState({
                  availableDates: [],
                  takenDates: [],
                  multipleNeeds: {},
                  isBoosted: false,
                  staffDescription: '',
                  contractDescription: '',
                  calendarInitDisplayDate: null,
                  practice_id: isInactivePractice ? null : practice_id,
                  confirmedTakenDates: [],
                  confirmedMultipleNeeds: {},

                  childAvailableDates: [],
                  childTakenDates: [],
                  childMultipleNeeds: {},
                  childCalendarInitDisplayDate: null,
                  childConfirmedTakenDates: [],
                  childConfirmedMultipleNeeds: {},
                  rotationTypes: [],
                }, () => history.push(`/newposition/${id}`));
              }}
            >
              duplicate and edit
            </Button>
          </Typography>
        )}
      </>
    );
  }

  renderBadges(isCustom, classes, stylesWithTheme, showLocked, isDisabledExternshipPost, isPrivate, lockedBadges, isEdit, showPublicBadge, isPublic, staffList, filteredBadges, lifeCycleBadgeId, help, helpMsg, isDesktop) {
    return (
      <>
        <div className={isCustom ? classes.badgesNarrowContainer : classes.badgesContainer}>
          <div style={stylesWithTheme.badgeTextContainer}>
            <Typography style={stylesWithTheme.badgeText}>
              Badges
            </Typography>
            <HelpOutlineIcon
              style={stylesWithTheme.helpOutlineIcon}
              onClick={e => this.setState({
                help: e.target,
                helpMsg: BADGE_TEXT,
              })}
            />
          </div>
          {!showLocked && (
            <FormControlLabel
              disabled={isDisabledExternshipPost}
              control={(
                <Checkbox
                  checked={isPrivate || false}
                  style={stylesWithTheme.badgeCheckBox}
                  onChange={e => this.badgeSelected('Private-staff')}
                  value="isPrivate"
                />
              )}
              label={this.getBadge('Private-staff', null, null, isDisabledExternshipPost)}
            />
          )}
          {showLocked ? lockedBadges?.map(badge => (
            <FormControlLabel
              key={badge.id}
              control={(
                <Checkbox
                  checked={this.state.badges && !!this.state.badges[badge.id]
                    || (!this.state.isUnCheckThriveBadge && this.checkThriveBadge(isEdit, badge)) || false}
                  onChange={(e) => {
                    const isThrive = badge && badge.title === 'Thrive' && isEmpty(badge.deletedAt);
                    this.setState({ isUnCheckThriveBadge: isThrive && !e.target.checked });
                    this.badgeSelected(badge.id);
                  }}
                  style={stylesWithTheme.badgeCheckBox}
                  value={badge.title}
                />
              )}
              label={this.getBadge(badge.title, badge.id, badge.isLocked, isDisabledExternshipPost)}
            />
          )) : ''}
          {showPublicBadge && (
            <FormControlLabel
              disabled={isDisabledExternshipPost}
              control={(
                <Checkbox
                  checked={isPublic || false}
                  style={stylesWithTheme.badgeCheckBox}
                  onChange={e => this.badgeSelected('Public')}
                  value="isPublic"
                />
              )}
              label={this.getBadge('Public', null, null, isDisabledExternshipPost)}
            />
          ) || ''}
          {staffList && staffList.length > 0 && (
            <FormControlLabel
              disabled={isDisabledExternshipPost}
              control={(
                <Checkbox
                  checked={isCustom || false}
                  style={stylesWithTheme.badgeCheckBox}
                  onChange={e => !isDisabledExternshipPost && this.badgeSelected('Superhero')}
                  value="isCustom"
                />
              )}
              label={this.getBadge('Superhero', null, null, isDisabledExternshipPost)}
            />
          )
          }

          {filteredBadges && filteredBadges.map(badge => (
            <FormControlLabel
              key={badge.id}
              disabled={this.checkDisableBadge(badge)}
              control={(
                <Checkbox
                  checked={this.state.badges && !!this.state.badges[badge.id] || false}
                  onChange={e => this.badgeSelected(badge.id, lifeCycleBadgeId === badge.id && badgeColor(badge.title))}
                  style={stylesWithTheme.badgeCheckBox}
                  value={badge.title}
                />
              )}
              label={this.getBadge(badge.title, badge.id, false, this.checkDisableBadge(badge))}
            />
          ))}
          <Popover
            open={!!help}
            anchorEl={help}
            onClose={e => this.setState({ help: null })}
          >
            <Paper style={stylesWithTheme.helpPopover}>
              <Typography style={stylesWithTheme.helpPopoverText}>{helpMsg}</Typography>
            </Paper>
          </Popover>
        </div>
        {isCustom && staffList && staffList.length > 0 && (
          <FormAutocomplete
            title="Search users by name or email address"
            label="Superhero List"
            letterLimit={3}
            labelCount={isDesktop ? 3 : 2}
            helpMessage={SUPERHERO_TEXT}
            options={staffList}
            value={this.state.selectedCustomPrivateUser}
            handleStateChange={(val) => {
              this.setState({ selectedCustomPrivateUser: val });
            }}
            isRequired
            multiple
          />
        )}
      </>
    );
  }

  renderAdditionalTitle(isDisabledExternshipPost, isEdit, tempTitle) {
    return (
      <>
        {(!isDisabledExternshipPost
          && (!this.state.manualTitle || (this.state.manualTitle && !isEdit))) && (
          <TextInput
            value={tempTitle.extraText || ''}
            title="Additional Title Text"
            onChange={extraText => this.setTitle('extraText', extraText)}
            maxLength={100}
          />
        )}
      </>
    );
  }
}

JobPostingsFormCalendar.propTypes = {
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
};

JobPostingsFormCalendar.defaultProps = {};

function JobPostingsFormCalendarWrapper({ theme, ...rest }) {
  const isDesktop = theme ? useMediaQuery(theme.breakpoints.up('md')) : true;
  return <JobPostingsFormCalendar {...rest} theme={theme} isDesktop={isDesktop}/>;
}

export default withStyles(commonResourceStyle, { withTheme: true })(withRouter(JobPostingsFormCalendarWrapper));
