import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { withRouter, Link } from 'react-router-dom';
import {
  Typography, MenuList, MenuItem, ListItemIcon, Icon, Drawer, IconButton, Hidden, SvgIcon,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import useMediaQuery from '@mui/material/useMediaQuery';

import { IMG_STORAGE_LINK } from '../data/Constants';
import { appPageNavBarWidth, navy, orange } from '../css/style';
import LogoSvgIcon from './subcomponents/LogoSvgIcon';
import {hasPermission, VIEW_ER_CALENDAR_PERMISSION} from "../utils/Functions";


const Logo = `${IMG_STORAGE_LINK}Jobs_logo_horozontal.svg`;
const LogoEmployer = `${IMG_STORAGE_LINK}Jobs_Logo.svg`;

const menuDrawerStyle = theme => ({
  appBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    width: appPageNavBarWidth,
    padding: '0',
    backgroundColor: '#000',
    minWidth: '250px',
  },
  colorGradient: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '10px',
    [theme && theme.breakpoints.down('sm')]: {
      backgroundColor: '#ffffff',
    },
  },
  menuList: {
    flex: '1',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
  },
  menuItem: {
    marginTop: '0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '0px',
    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingLeft: '30px',
      height: '50px',
    },
  },
  listItemIcon: {
    color: '#fff',
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  listItemText: {
    width: '50px',
    flex: 'none',
  },
  listItemTypography: {
    color: '#ffffff',
    fontFamily: 'Roboto Condensed',
    fontStyle: 'italic',
    fontSize: '12px',
    [theme && theme.breakpoints.down('sm')]: {
      fontFamily: 'Asap',
      fontStyle: 'normal',
      fontSize: '16px',
    },
  },
  signoutBtn: {
    color: '#fff',
    fontFamily: 'Asap',
    fontSize: '12px',
    fontStyle: 'italic',
    marginBottom: '5px',
    marginTop: '5px',

    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      marginBottom: '30px',
    },
  },
  drawerPaperProps: {
    style: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0',
      border: 'none',
    },
    sx: {
      backgroundColor: navy,
    },
  },
  expandOrCollapseButton: {
    color: '#9a9a9a',
    border: '2px solid #9a9a9a',
    padding: '5px',
    margin: '5px',
  },
  openOrCloseText: {
    color: '#9a9a9a',
    fontFamily: 'Asap',
    fontSize: '12px',
  },
  adminName: {
    color: '#ffffff',
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '12px',
    textAlign: 'center',
    padding: '0 5px',
    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  dividerStyle: {
    backgroundColor: '#475cb0',
    height: '1px',
    width: '80%',
    marginTop: '5px',
    marginBottom: '5px',
    [theme && theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  '@media print': {
    nonPrint: {
      display: 'none',
    },
  },
  drawerOpen: {
    width: '260px',
    backgroundColor: `${navy}`,
    overflow: 'auto',
    transition: theme && theme.transitions.create('width', {
      easing: theme && theme.transitions.easing.sharp,
      duration: theme && theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: '85px',
    maxWidth: '85px !important',
    backgroundColor: `${navy}`,
    overflow: 'auto',
    transition: theme && theme.transitions.create('width', {
      easing: theme && theme.transitions.easing.sharp,
      duration: theme && theme.transitions.duration.leavingScreen,
    }),
  },
  logo: {
    '&:hover': {
      cursor: 'pointer',
    },
    [theme && theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px',
    },
  },
  mobileDivider: {
    [theme && theme.breakpoints.down('sm')]: {
      backgroundColor: '#475cb0',
      height: '1px',
      width: '90%',
      marginTop: '5px',
      marginBottom: '20px',
    },
  },
  drawerCloseButton: {
    position: 'absolute',
    right: '0',
    top: '5px',
  },
  openDrawerIcon: {
    marginRight: '-150px',
    zIndex: '5',
    marginLeft: '10px',
  },
  menuIcon: {
    color: '#ffffff',
  },
  animatedIcon: {
    animation: '$scale-effect 1.5s infinite',
    fontSize: '10px !important',
    color: `${orange}`,
    padding: '0',
    position: 'absolute',
    top: '5px',
    right: '25px',
  },
  '@keyframes scale-effect': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.5)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
});

// TODO: consider moving the pages to a global file
const pages = {
  Home: {
    path: '/connections',
    val: 0,
    icon: <Icon>home</Icon>,
    title: 'Home',
  },
  Positions: {
    path: '/jobpostings',
    val: 1,
    icon: <LogoSvgIcon />,
    title: 'Posts',
  },
  Applications: {
    path: '/applications',
    val: 2,
    icon: <InsertEmoticonIcon />,
    title: 'Applications',
  },
  OtherChannelChats: {
    path: '/otherchannelchats',
    val: 3,
    icon: <NotificationsActiveIcon />,
    title: 'Extern Chats',
  },
  Practices: {
    path: '/practices',
    val: 4,
    icon: <Icon>domain</Icon>,
    title: 'Practices',
    hideInMobile: true,
  },
  Directory: {
    path: '/users',
    val: 5,
    icon: <Icon>people</Icon>,
    title: 'Admin',
    hideInMobile: true,
  },
  PrivateUsers: {
    path: '/users/privatetalents',
    val: 6,
    icon:
      <SvgIcon style={{ marginLeft: '8px' }}>
        <path
          id="a"
          d="M18.6 14.157h-.201v-1.253c0-1.15-.898-2.087-2.001-2.087-1.104 0-2.002.937-2.002 2.087v1.253h-.2c-.773 0-1.401.655-1.401 1.46v2.922c0 .806.628 1.461 1.4 1.461H18.6c.773 0 1.401-.655 1.401-1.46v-2.923c0-.805-.628-1.46-1.4-1.46zm-3.003-1.253c0-.46.36-.834.8-.834.442 0 .801.374.801.834v1.253h-1.601v-1.253zm-4.008 2.71c0-1.127.66-2.093 1.594-2.503v-.208c0-.507.117-.983.312-1.414a3.648 3.648 0 0 0-2.105-.672H4.618c-2.087 0-3.785 1.778-3.785 3.962v2.92c0 .345.268.625.598.625h10.158v-2.71zm.77-10.627a4.154 4.154 0 1 1-8.308 0 4.154 4.154 0 0 1 8.308 0z"
        />
      </SvgIcon>,
    title: 'Staff',
    hideInMobile: true,
  },
  Badges: {
    path: '/badges',
    val: 7,
    icon: <VerifiedUserIcon />,
    title: 'Badges',
    hideInMobile: true,
  },
  ERCalendar: {
    path: '/er-calendar',
    val: 9,
    icon: <Icon>event_available</Icon>,
    title: 'ER Calendar',
    hideInMobile: true,
  },
  Settings: {
    path: '/settings',
    val: 8,
    icon: <Icon>settings</Icon>,
    title: 'Settings',
    hideInMobile: true,
  },
};

class MenuBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpen: false,
      totalUserThreads: 0,
    };
  }

  componentDidMount = () => {
    const {
      actions,
      apiToken,
      resources,
      admin,
    } = this.props;
    const { isExternshipAdmin, isShiftAdmin } = admin;
    const statistics = resources['connections/statistics'];

    if (isShiftAdmin && !isExternshipAdmin) {
      if (!statistics) {
        actions.getAllResources(apiToken, 'connections/statistics').then((resp) => {
          if (resp && resp.response) {
            this.setState({ totalUserThreads: resp.response.totalUserThreads });
          }
        });
      } else {
        this.setState({ totalUserThreads: statistics.totalUserThreads });
      }
    }
  };

  handleClickSignOut() {
    const { actions } = this.props;
    localStorage.clear();
    sessionStorage.clear();
    actions.signoutUser();
  }

  renderMenuLink = (props, ref, page) => {
    const { isDesktop } = this.props;
    return (
      <Link
        to={page.path}
        {...props}
        ref={ref}
        onClick={() => {
          this.setState({ isDrawerOpen: false });
          if (!isDesktop) {
            sessionStorage.removeItem('homeVerticalScroll');
            sessionStorage.removeItem('applVerticalScroll');
            sessionStorage.removeItem('otherApplVerticalScroll');
          }
        }}
      />
    );
  };

  drawer = () => {
    const {
      classes,
      admin,
      theme,
      isDesktop,
    } = this.props;
    const { totalUserThreads } = this.state;
    const privateAccess = admin && admin.privateAccess || '';
    const adminName = admin && admin.name;
    const jobsLogo = isDesktop ? Logo : LogoEmployer;
    const { isExternshipAdmin, isShiftAdmin } = admin;
    const showERCalendar = hasPermission(admin, VIEW_ER_CALENDAR_PERMISSION);
    const stylesWithTheme = menuDrawerStyle(theme);

    return (
      <Fragment>
        {
          !isDesktop
          && (
            <IconButton
              sx={stylesWithTheme.drawerCloseButton}
              onClick={() => this.setState({ isDrawerOpen: false })}
            >
              <Icon>close</Icon>
            </IconButton>
          )
        }
        <div className={`gradient ${classes.colorGradient}`}>
          <img className={classes.logo} src={jobsLogo} alt="Logo" onClick={() => window.open('https://www.jobsunleashed.com')} />
        </div>
        <div className={classes.dividerStyle} />
        <MenuList sx={stylesWithTheme.menuList}>
          {map(pages, (page, pageName) => {
            if (!isDesktop && page.hideInMobile) return;

            if (page.path === '/otherchannelchats' && (!isShiftAdmin || (isShiftAdmin && isExternshipAdmin) || !totalUserThreads)) return;
            if (page.path === '/er-calendar' && !showERCalendar) return;

            return (
              <MenuItem
                key={pageName}
                value={page.val}
                component={React.forwardRef((props, ref) => this.renderMenuLink(props, ref, page))}
                sx={stylesWithTheme.menuItem}
                style={{
                  borderRight: (this.props.location.pathname === page.path) && isDesktop && `6px solid ${orange}`,
                  paddingRight: (this.props.location.pathname === page.path) && '0px' || '6px',
                }}
              >
                <ListItemIcon
                  sx={stylesWithTheme.listItemIcon}
                  style={{ color: (this.props.location.pathname === page.path) && `${orange}` || '#fff' }}
                >
                  {page.icon}
                  {page.title === 'Settings' && admin.releaseNote && admin.releaseNote.id && !admin.isReadReleaseNote && (
                    <Icon
                      className={classes.animatedIcon}
                      style={{
                        right: (this.props.location.pathname === page.path) && isDesktop && '19px',
                      }}
                    >
                      lens
                    </Icon>
                  )}
                </ListItemIcon>
                <Typography
                  sx={stylesWithTheme.listItemTypography}
                  style={{ color: (this.props.location.pathname === page.path) && `${orange}` || '#fff' }}
                >
                  {page.title}
                </Typography>
              </MenuItem>
            );
          })}
        </MenuList>
        <div className={classes.mobileDivider} />
        <Typography sx={stylesWithTheme.adminName}>{adminName}</Typography>
        <Link to="/" style={stylesWithTheme.signoutBtn} onClick={() => this.handleClickSignOut()}>Sign out</Link>
      </Fragment>
    );
  };

  render() {
    const { classes, theme, isDesktop } = this.props;
    const stylesWithTheme = menuDrawerStyle(theme);
    return (
      <div>
        <Hidden mdUp>
          <IconButton
            sx={stylesWithTheme.openDrawerIcon}
            onClick={() => this.setState({ isDrawerOpen: !this.state.isDrawerOpen })}
          >
            <Icon sx={stylesWithTheme.menuIcon}>menu</Icon>
          </IconButton>
        </Hidden>
        {
          isDesktop
            ? (
              <Hidden smDown implementation="css">
                <Drawer
                  sx={stylesWithTheme.nonPrint}
                  PaperProps={stylesWithTheme.drawerPaperProps}
                  variant="permanent"
                  open
                  classes={{
                    root: classes.drawerClose,
                    paper: classes.drawerClose,
                  }}
                >
                  {this.drawer()}
                </Drawer>
              </Hidden>
            )
            : (
              // Mobile Drawer
              <Hidden smUp implementation="css">
                <Drawer
                  sx={stylesWithTheme.nonPrint}
                  PaperProps={stylesWithTheme.drawerPaperProps}
                  open={this.state.isDrawerOpen}
                  variant="temporary"
                  onClose={() => this.setState({ isDrawerOpen: !this.state.isDrawerOpen })}
                  classes={{ paper: classes.drawerOpen }}
                >
                  {this.drawer()}
                </Drawer>
              </Hidden>
            )
        }
      </div>
    );
  }
}

MenuBar.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

function MenuBarWrapper({ theme, ...rest }) {
  const isDesktop = theme ? useMediaQuery(theme.breakpoints.up('md')) : true;
  return <MenuBar {...rest} theme={theme} isDesktop={isDesktop} />;
}

export default withStyles(menuDrawerStyle, { withTheme: true })(withRouter(MenuBarWrapper));
