import {
  isNumber,
  isEmpty,
  isEqual,
  isNil,
  sortBy,
  difference,
} from 'lodash';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import {
  Paper, Typography, Divider, Icon, Popover, Box,
  Button, Dialog, DialogContent, DialogActions, IconButton,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ExitToAppOutlined } from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { messagePageStyle } from './styles/MessagesStyles';
import { isReliefOrExternshipType, formatPostTitle } from '../../utils/Functions';
import MessagesInputBox from '../subcomponents/MessagesInputBox';
import MessagesChatBox from '../subcomponents/MessagesChatBox';
import MessagesHeader from '../subcomponents/MessagesHeader';
import ContactInfo from '../subcomponents/ContactInfo';
import ExperienceDetailCard from '../subcomponents/ExperienceDetailCard';
import PingUserDialog from '../subcomponents/PingUserDialog';
import CalendarConfirmationDialog from '../subcomponents/CalendarConfirmationDialog';
import RefreshPageDialog from '../subcomponents/RefreshPageDialog';
import {
  PING_USER_HELP_TEXT,
  IMG_STORAGE_LINK,
  AGENCY_NAMES,
  EXTERNSHIP,
  HQ_ADMIN_KEY,
  BASE_URLS,
} from '../../data/Constants';
import DateUtilities, { setCalendarData } from '../Datepicker/utils';
import { formatISODateOnly } from '../Datepicker/dateUtils';

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      talentType: '',
      messages: [],
      newMessage: '',
      jobPostingTitle: '',
      jobPostingId: null,
      jobPostingType: '',
      jobPostingDeleted: false,
      isJobPostingDeleted: false,
      dateList: null,
      jobPostingCalendar: null,
      multipleNeeds: {},
      totalMessageCount: 0,
      invisibleToTalent: false,
      help: null,
      openPingUserDialog: false,
      admins: [],
      unreadMessage: [],
      disableLineBreak: false,
      openConfirmation: false,
      confirmedMultipleNeeds: {},
      openLeaveChat: false,
      vetSchools: [],
      agencyList: AGENCY_NAMES,
      talentUserId: null,
      requestTrackingDates: [],
      openRefreshDialog: false,
    };
  }

  componentDidMount = async () => {
    const {
      classes, match, actions, apiToken, admin, adminPractices,
    } = this.props;
    const { resourceId } = match.params; // resourceId is connection id

    // get vet schools
    await actions.getAllResources(apiToken, 'schools').then((schoolList) => {
      this.setState({ vetSchools: schoolList.response });
    });
    await actions.getOneResource(apiToken, 'connections', resourceId).then((res) => {
      const connection = res.response;
      if (connection) {
        const name = `${connection.talentPII.firstName} ${connection.talentPII.lastName}`;
        const talentType = connection.talent.type;
        const jobPostingTitle = formatPostTitle(connection.jobPosting.title);
        const jobPostingId = connection.jobPosting.id;
        const jobPostingParentId = connection.jobPosting.parent_job_posting_id;
        const jobPostingDeleted = Boolean(connection.jobPosting.deletedAt);
        const isJobPostingDeleted = Boolean(connection.jobPosting.isDeleted) && jobPostingDeleted;
        const jobPostingType = connection.jobPosting.typeOfEmployment;
        const invisibleToTalent = connection.invisibleToTalent;
        const phone = connection.talentPII.mobile;
        const email = connection.email;
        const address = connection.savedSearch.address;
        const talent = connection.talent;
        const talentProfileItems = connection.talentProfileItems;
        const talentUserId = connection.user && connection.user.id;

        if (adminPractices) {
          const practice = adminPractices.find(x => x.id === connection.jobPosting.practice_id);
          this.setState({ location: practice ? `${practice.location} - ${practice.city} - ${practice.state}` : '' });
        } else {
          actions.getAllResources(apiToken, BASE_URLS.getPractices).then((res) => {
            const practice = res.response.find(x => x.id === connection.jobPosting.practice_id);
            this.setState({ location: practice ? `${practice.location} - ${practice.city} - ${practice.state}` : '' });
          });
        }

        actions.getAllResources(apiToken, 'users/ping-candidate?count=1000&status=Active').then((resp) => {
          if (!resp.errors && resp.response && resp.response.list && resp.response.list.length > 0) {
            this.setState({
              admins: resp.response.list.filter(it => it.id !== admin.id),
            });
          }
        });

        // Get job_posting_calendar here and setState({multipleNeeds})
        if (isReliefOrExternshipType(connection.jobPosting.typeOfEmployment)) {
          actions.getAllResources(apiToken, `jobpostings/${jobPostingId}/calendar`).then((resp) => {
            if (resp && resp.response) {
              this.setState({
                jobPostingCalendar: resp.response,
              }, () => {
                if (connection.jobPosting.typeOfEmployment === 'Relief') {
                  // get date-request-trackings
                  this.getDateRequestTracking(jobPostingId);
                }
              });
            }
          });
        }

        this.setState({
          name,
          talentType,
          jobPostingTitle,
          jobPostingId,
          jobPostingParentId,
          jobPostingDeleted,
          jobPostingType,
          invisibleToTalent,
          isJobPostingDeleted,
          phone,
          email,
          address,
          talent,
          talentProfileItems,
          talentUserId,
        });
      }
    });
    actions.updateResource(apiToken, { adminViewDate: new Date() }, 'connections', resourceId);
    this.reloadMessages();

    actions.getAllResources(apiToken, 'connections', resourceId, 'message-thread-users').then((res) => {
      if (!res.error) {
        const userThreads = !isEmpty(res.response) ? res.response.filter(mtu => mtu.user_id === admin.id) : [];
        const isUserInThread = userThreads && userThreads.find(mtu => !mtu.left_at) ? true : false;

        this.setState({
          messageThreadUsers: res.response,
          leftChatUser: isEmpty(userThreads) || isUserInThread ? false : true,
          isUserInThread,
          isLastAdminInThread: res.response && res.response.filter(mtu => !mtu.left_at).length === 1,
        });
      }
    });
    this.fetchAndSetTalentList();
  };

  reloadMessages = () => {
    const {
      match, actions, apiToken,
    } = this.props;
    const { resourceId } = match.params; // resourceId is connection id
    actions.getAllResources(apiToken, 'connections', resourceId, 'messages').then((res) => {
      if (!res.error) {
        const messages = res.response;
        const unreadMsgs = messages.filter(msg => msg.isUnread && msg.creator_id === msg.talent.user_id).map(msg => msg.id);
        if (unreadMsgs.length > 0) { // set unread msgs to read
          actions.updateResource(apiToken, { message_ids: unreadMsgs }, 'messages', 'set-as-read');
        }
        const prunedMessages = messages.map(msg => ({
          body: msg.body,
          creator_id: msg.creator_id,
          is_sender: msg.creator_id !== msg.talent.user_id,
          updatedAt: msg.updatedAt,
          createdAt: msg.createdAt,
          creatorName: msg.creatorName,
          isUnread: msg.isUnread,
          id: msg.id,
        }));
        this.setState({
          messages: prunedMessages,
          totalMessageCount: prunedMessages.length,
        });
        const element = document.getElementById('messagesContainer');
        if (element && isNumber(element.scrollHeight) && isNumber(element.scrollTop)) {
          element.scrollTop = element.scrollHeight;
        }
      }
    });
  };

  getDateRequestTracking = (jobPostingId) => {
    const {
      actions, apiToken, admin,
    } = this.props;
    const { jobPostingCalendar } = this.state;

    actions.getAllResources(apiToken, `date-request-trackings/job-posting/${jobPostingId}`).then((dateRes) => {
      if (!dateRes.errors && !isEmpty(dateRes?.response)) {
        const avaiDates = jobPostingCalendar?.filter(c => c.status === 'available').map(c => c.date);

        this.setState({
          requestTrackingDates: dateRes.response || [],
        });
      }
    });
  };

  onClickLeaveButton = () => {
    const {
      match,
      actions,
      apiToken,
      admin,
    } = this.props;
    const { resourceId } = match.params;
    const { messages } = this.state;

    const element = document.getElementById('messagesContainer');
    const payload = {
      body: `${this.capitalizeFirstLetter(admin.name)} $$has left the chat.$$`,
      match_id: parseInt(resourceId),
      isUnread: true,
      leaveChat: {
        user_id: admin.id,
        last_read_message_id: !isEmpty(messages) ? messages[messages.length - 1].id : null,
        first_msg_in_thread_created_at: !isEmpty(messages) ? messages[0].createdAt : null,
      },
    };
    actions.addResource(apiToken, payload, 'messages').then((res) => {
      const sentMsg = res.response;
      messages.push({
        body: sentMsg.body,
        creator_id: sentMsg.creator_id,
        updatedAt: sentMsg.updatedAt,
        is_sender: sentMsg.creator_id !== sentMsg.talent.user_id,
        creatorName: sentMsg.creatorName,
      });
      actions.getAllResources(apiToken, 'connections', resourceId, 'message-thread-users').then((threadRes) => {
        if (!threadRes.error) {
          const userThreads = !isEmpty(threadRes.response) ? threadRes.response.filter(mtu => mtu.user_id === admin.id) : [];
          const isUserInThread = userThreads && userThreads.find(mtu => !mtu.left_at) ? true : false;

          this.setState({
            messageThreadUsers: threadRes.response,
            leftChatUser: isEmpty(userThreads) || isUserInThread ? false : true,
            isUserInThread,
            isLastAdminInThread: threadRes.response && threadRes.response.filter(mtu => !mtu.left_at).length === 1,
            messages,
            newMessage: '',
          });
          element.scrollTop = element.scrollHeight;
        }
      });
    });
  };

  onClickSendButton = () => {
    const { match, actions, apiToken } = this.props;
    const { resourceId } = match.params;
    const { messages, newMessage } = this.state;
    const element = document.getElementById('messagesContainer');
    if (!newMessage.trim()) {
      if (element && isNumber(element.scrollHeight) && isNumber(element.scrollTop)) {
        element.scrollTop = element.scrollHeight;
      }
      return; // prevent sending blank messages
    }
    const payload = {
      body: newMessage,
      match_id: parseInt(resourceId),
      isUnread: true,
    };

    actions.addResource(apiToken, payload, 'messages').then((res) => {
      actions.addResource(apiToken, { action: 'admin_message' }, 'users', 'log-event');
      const sentMsg = res.response;
      messages.push({
        body: sentMsg.body,
        creator_id: sentMsg.creator_id,
        updatedAt: sentMsg.updatedAt,
        is_sender: sentMsg.creator_id !== sentMsg.talent.user_id,
        creatorName: sentMsg.creatorName,
      });
      this.setState({
        messages,
        newMessage: '',
      });
      element.scrollTop = element.scrollHeight;
      const unreadMessage = cloneDeep(this.state.unreadMessage);
      actions.updateResource(apiToken, { adminViewDate: new Date() }, 'connections', resourceId);
      unreadMessage.forEach((msg) => {
        actions.updateResource(apiToken, { message_ids: msg }, 'messages', 'set-as-read')
          .then(() => this.setState({ unreadMessage: this.state.unreadMessage.filter(unread => unread != msg) }));
      });
    });
  };

  fetchAndSetTalentList = () => {
    const { actions, apiToken } = this.props;
    actions.getAllResources(apiToken, 'jobpostings/candidates').then((resp) => {
      if (resp.response) {
        let talentList = resp.response.map(it => ({
          id: it.id,
          name: `${it.name} (${it.email})`,
        }));
        this.setState({ talentList });
      }
    });
  };

  setConfirmTakenDates = (
    availableDates,
    takenDates,
    pendingDates,
    multipleNeeds,
    confirmedTakenDates,
    confirmedMultipleNeeds,
  ) => {
    const { jobPostingCalendar } = this.state;

    let newTakenDates = takenDates.filter(takenDate => !DateUtilities.dateIn(confirmedTakenDates, takenDate));
    let untakenDates = confirmedTakenDates.filter(confirmedTakenDate => !DateUtilities.dateIn(takenDates, confirmedTakenDate));

    // Not ask again the taken in multiple, and turns to be single
    // --> check if the newTakenDates were in confirmedMultipleNeeds and taken > 0 --> filter out
    newTakenDates = newTakenDates.filter(date => !(confirmedMultipleNeeds[formatISODateOnly(date)]
      && confirmedMultipleNeeds[formatISODateOnly(date)].taken > 0));

    let confirmTakenDates = newTakenDates.map(date => ({ date }));

    // Set data for untaken
    const calendarData = jobPostingCalendar ? jobPostingCalendar.map(item => ({ ...item, date: DateUtilities.midDayDate(item.date) })) : [];
    let confirmUntakenDates = [];

    untakenDates?.forEach((date) => {
      confirmUntakenDates.push({
        date,
        isUntaken: true,
        takenDataRows: calendarData.filter(row => row.status === 'taken' && DateUtilities.isSameDay(date, row.date)),
      });
    });

    confirmTakenDates = confirmTakenDates.concat(confirmUntakenDates);

    Object.keys(multipleNeeds).forEach((dayKey) => {
      const dateObj = multipleNeeds[dayKey];
      const confirmedDateObj = confirmedMultipleNeeds[dayKey];
      if (dateObj.taken > 0 && (!confirmedDateObj || (confirmedDateObj && confirmedDateObj.taken < dateObj.taken))) {
        let newTakenCount = confirmedDateObj ? dateObj.taken - confirmedDateObj.taken : dateObj.taken;

        // Not ask again the taken in confirmedTakenDates, turns from single to multiple
        // --> check if the dateObj.date were in confirmedTakenDates --> minus 1 taken shift
        const confirmTakenDate = confirmedTakenDates.filter(singleDate => DateUtilities.isSameDay(singleDate, dateObj.date));
        newTakenCount = !isEmpty(confirmTakenDate) ? newTakenCount - 1 : newTakenCount;

        if (newTakenCount > 0) {
          for (let i = 1; i <= newTakenCount; i++) {
            const confirmObj = {
              date: dateObj.date,
              shift: i,
              hasMultipleNeeds: true,
              hasMultipleTakensOnly: dateObj.available + (dateObj.pending || 0) > 0 ? false : true,
              available: dateObj.available,
              pending: dateObj.pending,
              taken: dateObj.taken,
            };
            confirmTakenDates.push(confirmObj);
          }
        }
        // Not ask again the untaken in confirmedTakenDates, turns from single to multiple
        confirmTakenDates = confirmTakenDates.filter(item => !(DateUtilities.isSameDay(item.date, dateObj.date) && item.isUntaken));
      }
    });

    Object.keys(confirmedMultipleNeeds).forEach((dayKey) => {
      const dateObj = multipleNeeds[dayKey] || {};
      const confirmedDateObj = confirmedMultipleNeeds[dayKey];

      // Calculation for untaken
      if (confirmedDateObj && confirmedDateObj.taken > (dateObj?.taken || 0)) { // taken decreases
        let untakenCount = confirmedDateObj.taken - (dateObj?.taken || 0);

        // Not ask again the taken in takenDates, turns from multiple to single
        // --> check if the date were in takenDates --> minus 1 untaken shift
        const takenDate = takenDates ? takenDates.filter(singleDate => DateUtilities.isSameDay(singleDate, confirmedDateObj.date)) : [];
        untakenCount = !isEmpty(takenDate) ? untakenCount - 1 : untakenCount;

        // The date turns from multiple to single avai
        const avaiDate = availableDates ? availableDates.filter(singleDate => DateUtilities.isSameDay(singleDate, confirmedDateObj.date)) : [];

        if (untakenCount > 0) {
          // Single untaken should be displayed as multiple
          if (takenDate.length && confirmTakenDates.length) {
            confirmTakenDates = confirmTakenDates.filter(obj => !DateUtilities.isSameDay(obj.date, takenDate[0]));
          }

          const takenDataRows = calendarData.filter(row => row.status === 'taken' && DateUtilities.isSameDay(confirmedDateObj.date, row.date));
          for (let i = 1; i <= untakenCount; i++) {
            const confirmedObj = {
              date: confirmedDateObj.date,
              shift: i,
              hasMultipleNeeds: true,
              hasMultipleTakensOnly: (dateObj.available || avaiDate.length) + (dateObj.pending || 0) > 0 ? false : true,
              available: dateObj.available || avaiDate.length,
              taken: dateObj.taken || takenDate.length,
              pending: dateObj.pending,
              isUntaken: true,
              takenDataRows,
            };
            confirmTakenDates.push(confirmedObj);
          }
        }
      }
    });

    confirmTakenDates = sortBy(confirmTakenDates, ['date', 'shift']);

    this.setState({
      openConfirmation: confirmTakenDates.length > 0,
      confirmTakenDates,
      availableDates,
      takenDates,
      pendingDates,
      multipleNeeds,
    }, () => {
      if (!confirmTakenDates.length) return this.saveDates();
    });
  };

  saveDates() {
    const { actions, apiToken, admin } = this.props;
    const {
      jobPostingId,
      jobPostingCalendar,
      talent,
      availableDates,
      takenDates,
      pendingDates,
      multipleNeeds,
      tempConfirmedData,
      talentUserId,
      initMultipleNeeds = {},
      boostDetails,
      jobPostingParentId,
    } = this.state;

    const tempUntakenConfirmedData = !isEmpty(tempConfirmedData) ? tempConfirmedData.filter(c => c.isUntaken) : [];

    let calendarData = setCalendarData({
      jobPostingCalendar,
      availableDates,
      takenDates,
      pendingDates,
      multipleNeeds,
      tempUntakenConfirmedData,
      boostDetails,
    });

    // Only set surgery_needed = true for all available dates of a conorado job
    if (jobPostingParentId) {
      calendarData = calendarData.map((c) => {
        if (c.status === 'available') {
          c.surgery_needed = true;
          c.is_boosted = false;
          c.boost_amount = 0;
        }
        return c;
      });
    }

    const takenIndexOnDate = {};
    const confirmationDates = [];

    const tempTakenConfirmedData = !isEmpty(tempConfirmedData) ? tempConfirmedData.filter(c => !c.isUntaken) : [];

    let removedMultiDates = [];
    if (!isEmpty(initMultipleNeeds)) {
      const removedMultiDaykeys = difference(Object.keys(initMultipleNeeds), Object.keys(multipleNeeds));
      removedMultiDates = !isEmpty(removedMultiDaykeys) ? removedMultiDaykeys.map(daykey => initMultipleNeeds[daykey].date) : [];
    }

    calendarData = calendarData.map((item) => {
      let returnObj = {
        ...item,
        admin_id: admin.id,
        job_posting_id: jobPostingId,
      };
      // Set confirmation values for the newly added taken shitfs
      if (tempTakenConfirmedData.length && item.status === 'taken' && !item.confirmation_category) {
        const takenConfirmRows = tempTakenConfirmedData.filter(c => DateUtilities.isSameDay(c.date, item.date));
        if (takenConfirmRows.length) {
          takenIndexOnDate[item.date] = !isNil(takenIndexOnDate[item.date]) ? takenIndexOnDate[item.date] + 1 : 0;
          const takenConfirmRow = takenIndexOnDate[item.date] < takenConfirmRows.length ? takenConfirmRows[takenIndexOnDate[item.date]] : {};
          returnObj = {
            ...returnObj,
            talent_user_id: takenConfirmRow.talentUserId,
            confirmation_category: takenConfirmRow.confirmationCategory,
            confirmation: takenConfirmRow.confirmation,
          };

          if (takenConfirmRow.isConfirmed) {
            returnObj.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            returnObj.confirmation_date = new Date();
            returnObj.isConfirmed = true;

            if (talentUserId === takenConfirmRow.talentUserId) {
              confirmationDates.push(takenConfirmRow.date);
            }
          }
        }
      }

      // Set confirmation values for the untaken shitfs
      if (tempUntakenConfirmedData.length && item.status === 'untaken') {
        const untakenConfirmRow = tempUntakenConfirmedData.find(c => c.untakenRowId === item.id);
        if (untakenConfirmRow) {
          returnObj = {
            ...returnObj,
            talent_user_id: untakenConfirmRow.talentUserId,
            confirmation_category: untakenConfirmRow.confirmationCategory,
            confirmation: untakenConfirmRow.confirmation,
          };
        }
      }

      // Set unavailable the rows used to be avai in a multineed, but now the multineed is removed
      if (!isEmpty(removedMultiDates)
        && item.status === 'available'
        && !DateUtilities.dateIn(availableDates, item.date)
        && DateUtilities.dateIn(removedMultiDates, item.date)) {
        returnObj = {
          ...returnObj,
          status: 'unavailable',
        };
      }

      return { ...returnObj, date: formatISODateOnly(item.date) };
    });

    this.setState({ jobDatesDisabled: true });
    actions.updateResource(apiToken, { jobPostingCalendar: calendarData, isMessagePage: true }, 'jobpostings', jobPostingId).then(() => {
      const dates = confirmationDates.map(d => moment(d).format('MM/DD')).join(',');
      if (dates) {
        this.setState({
          newMessage: `${admin.name} has confirmed you for ${dates.split(',').join(', ')}. A summary email will follow at the end of the day.`,
        }, () => this.onClickSendButton());
      }
      actions.addResource(apiToken, { jobPostingId, calendarData }, 'date-request-trackings');
      actions.addResource(apiToken, { action: 'admin_calendar' }, 'users', 'log-event');

      setTimeout(() => {
        this.refreshCalendarData(); // refresh calendar
        this.reloadMessages();
        this.setState({ jobDatesDisabled: false });
      }, 2000);
    });
  }

  refreshCalendarData = async () => {
    const { actions, apiToken } = this.props;
    const { jobPostingId } = this.state;

    const calendarResponse = await actions.getAllResources(apiToken, `jobpostings/${jobPostingId}/calendar`);
    if (calendarResponse && calendarResponse.response) {
      this.getDateRequestTracking(jobPostingId);
      this.setState({
        jobPostingCalendar: calendarResponse.response,
      });
    }
  };

  handleDateMsgConfirm = async (editedRow, action) => {
    const { actions, apiToken, admin } = this.props;
    const {
      talent,
      jobPostingId,
      requestTrackingDates,
      jobPostingCalendar,
      talentList,
    } = this.state;

    if (!editedRow) return;

    const calendarResponse = await actions.getAllResources(apiToken, `jobpostings/${jobPostingId}/calendar`);
    if (calendarResponse && calendarResponse.response && !isEqual(calendarResponse.response, jobPostingCalendar)) {
      this.setState({ openRefreshDialog: true });
      return;
    }

    if (action === 'approved') { // Approve the shift
      const requestedShiftsRows = requestTrackingDates.filter(row => row.job_posting_id === editedRow.job_posting_id
        && row.status === 'requested'
        && row.date === editedRow.date);

      // Check if the date is still available
      const avaiShiftRows = jobPostingCalendar ? jobPostingCalendar.filter(c => c.status === 'available'
        && c.job_posting_id === editedRow.job_posting_id && c.date === editedRow.date) : [];
      const avaiShiftsCount = avaiShiftRows.length;

      //  Assign shift successfully, then we save change into database
      if (avaiShiftsCount > 0) {
        // Save job posting calendar, request date tracking
        const talentInfo = talentList && talentList.find(t => t.id === talent?.user_id);
        let takenShift = 0;
        const calendarData = [...jobPostingCalendar].map((row) => {
          if (takenShift === 0 && row.date === editedRow.date
              && row.job_posting_id === editedRow.job_posting_id
              && row.status === 'available') {
            takenShift += 1;
            return {
              ...row,
              status: 'taken',
              talent_user_id: talent?.user_id,
              confirmation_category: 'known_taken',
              confirmation: talentInfo?.name,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              confirmation_date: new Date(),
            };
          }
          return row;
        });

        actions.updateResource(apiToken, { jobPostingCalendar: calendarData, isMessagePage: true }, 'jobpostings', jobPostingId).then(() => {
          const date = moment(editedRow.date).format('MM/DD');
          this.setState({
            newMessage: `${admin.name} has confirmed you for ${date}. A summary email will follow at the end of the day.`,
          }, async () => {
            this.onClickSendButton(); // send a message
            await this.refreshCalendarData(); // refresh calendar
          });

          actions.addResource(apiToken, { action: 'admin_calendar' }, 'users', 'log-event');

          let dataRequestTrackingDates = [];

          // If we have multiple available shifts --> do not decline
          if (avaiShiftsCount > 1) {
            dataRequestTrackingDates = [...requestTrackingDates].map((row) => {
              if (row.id === editedRow.id) {
                return {
                  ...row,
                  status: 'approved',
                  isSentMessage: true,
                };
              } else {
                return row;
              }
            });
          } else { // avaiShiftsCount == 1  --> decline other requests
            const totalDeclinedCount = requestedShiftsRows?.length - avaiShiftsCount;
            let declineCount = 0;
            dataRequestTrackingDates = [...requestTrackingDates].map((row) => {
              if (row.id === editedRow.id) {
                return {
                  ...row,
                  status: 'approved',
                  isSentMessage: true,
                };
              } else if (totalDeclinedCount > 0 && declineCount < totalDeclinedCount && row.status === 'requested'
                && row.date === editedRow.date && row.job_posting_id === editedRow.job_posting_id) {
                declineCount += 1;
                return {
                  ...row,
                  status: 'declined',
                  isSentMessage: false,
                };
              }
              return row;
            });
          }
          actions.addResource(apiToken, dataRequestTrackingDates, 'date-request-trackings', 'update-trackings').then(() => {
            this.setState({
              requestTrackingDates: [...dataRequestTrackingDates],
            });
          });
        });
      }
    }

    if (action === 'declined' && editedRow.status === 'requested') {
      const data = [...requestTrackingDates].map((row) => {
        if (row.id === editedRow.id) {
          return {
            ...row,
            status: 'declined',
            isSentMessage: true,
          };
        }
        return row;
      });

      // Call API to decline the requested date
      actions.addResource(apiToken, data, 'date-request-trackings', 'update-trackings').then(() => {
        const date = moment(editedRow.date).format('MM/DD');
        this.setState({
          requestTrackingDates: [...data],
          newMessage: `We have decided to pursue another worker for ${date}.`,
        }, () => this.onClickSendButton());
      });
    }
  };

  onClickSetUnreadMessage(unreadMsg) {
    const { actions, apiToken } = this.props;
    const message = { isUnread: true };

    this.setState({ unreadMessage: [...this.state.unreadMessage, unreadMsg] });
    actions.updateResource(apiToken, message, 'messages', unreadMsg);
  }

  onChangeTextField = (event) => {
    const { disableLineBreak, newMessage } = this.state;
    this.setState({
      newMessage: disableLineBreak ? newMessage : event.target.value,
      disableLineBreak: false,
    });
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.setState({ disableLineBreak: true });
      this.onClickSendButton();
    }
  };

  onClickMoreInfoButton = () => {
    const { match, history } = this.props;
    const { resourceId } = match.params;
    history.push(`/connections/${resourceId}/view`);
  };

  renderLinkToTalentProfile = () => {
    const { classes, isDesktop, theme } = this.props;
    const { fullDetailText, fullDetailIcon } = messagePageStyle(theme);
    return (
      <div className={classes.fullDetailContainer} onClick={this.onClickMoreInfoButton}>
        <Typography
          sx={fullDetailText}
        >
          {isDesktop ? 'FULL PROFILE' : 'TALENT PROFILE'}
        </Typography>
        <Icon sx={fullDetailIcon}>trending_flat</Icon>
      </div>
    );
  };

  render() {
    const {
      classes, admin, apiToken, actions, history, theme, isDesktop,
    } = this.props;
    const {
      name,
      talentType,
      messages,
      newMessage,
      jobPostingTitle,
      jobPostingId,
      jobPostingParentId,
      jobPostingDeleted,
      dateList,
      jobPostingCalendar,
      totalMessageCount,
      invisibleToTalent,
      phone, email, address,
      talent,
      talentProfileItems,
      help,
      openPingUserDialog,
      admins,
      openConfirmation,
      isJobPostingDeleted,
      leftChatUser,
      isUserInThread,
      isLastAdminInThread,
      jobPostingType,
      openLeaveChat,
      location,
      requestTrackingDates,
      talentList,
      jobDatesDisabled,
    } = this.state;
    const { isExternshipAdmin, isShiftAdmin } = admin;
    const isHQAdmin = admin && admin.adminRole === HQ_ADMIN_KEY;

    const disablePostIcon = jobPostingType === EXTERNSHIP && !isHQAdmin && isShiftAdmin && !isExternshipAdmin;
    const stylesWithTheme = messagePageStyle(theme);

    return (
      <Box>
        <div className={classes.headerContainer}>
          <Typography sx={stylesWithTheme.pageTitle}>MESSAGE</Typography>
          <div className={classes.actionContainer}>
            <Button
              sx={stylesWithTheme.pingButton}
              onClick={e => this.setState({ openPingUserDialog: true })}
              disabled={leftChatUser}
            >
              PING ADMIN USER
            </Button>
            <HelpOutlineIcon
              sx={stylesWithTheme.helpOutlineIcon}
              onClick={e => this.setState({
                help: e.target,
              })}
            />
            <Popover
              open={!!help}
              anchorEl={help}
              onClose={e => this.setState({ help: null })}
            >
              <Paper sx={stylesWithTheme.helpPopover}>
                <Typography sx={stylesWithTheme.helpPopoverText}>{PING_USER_HELP_TEXT}</Typography>
              </Paper>
            </Popover>
          </div>
        </div>
        <div className={classes.twoPapersContainer}>
          <Paper sx={stylesWithTheme.messagesContainer}>
            <MessagesHeader
              name={name}
              jobTitle={jobPostingTitle}
              jobPostingId={jobPostingId}
              jobPostingParentId={jobPostingParentId}
              setDates={(available,
                taken,
                pendingDates,
                multipleNeeds,
                confirmedTakenDates,
                confirmedMultipleNeeds,
                initMultipleNeeds,
                boostData) => {
                this.setState({ boostDetails: boostData });
                this.setConfirmTakenDates(available, taken, pendingDates, multipleNeeds, confirmedTakenDates, confirmedMultipleNeeds);
                this.setState({ initMultipleNeeds });
              }}
              hideJobPostingLink={jobPostingDeleted || disablePostIcon}
              readOnlyCalendar={leftChatUser}
              talentType={talentType}
              jobPostingCalendar={jobPostingCalendar}
              refreshCalendarData={this.refreshCalendarData.bind(this)}
              address={location}
              history={history}
              jobPostingType={jobPostingType}
              actions={actions}
              apiToken={apiToken}
              jobDatesDisabled={jobDatesDisabled}
            />
            <MessagesChatBox
              messages={messages}
              loggedInUser={admin}
              onClickSetUnreadMessage={this.onClickSetUnreadMessage.bind(this)}
              requestTrackingDates={requestTrackingDates}
              handleDateMsgConfirm={this.handleDateMsgConfirm.bind(this)}
            />
            <MessagesInputBox
              onClickSendButton={this.onClickSendButton}
              onClickLeaveButton={() => {
                this.setState({ openLeaveChat: true });
              }}
              onChangeTextField={this.onChangeTextField}
              onKeyPress={this.handleKeyPress}
              textFieldValue={newMessage}
              disableSending={(invisibleToTalent && totalMessageCount <= 1) || (isJobPostingDeleted) || leftChatUser}
              showLeaveBtn={isUserInThread && !isLastAdminInThread && !isHQAdmin}
            />
          </Paper>
          <RefreshPageDialog
            open={this.state.openRefreshDialog}
            handleClose={() => this.setState({ openRefreshDialog: false })}
          />
          {isDesktop
          && (
            <Paper sx={stylesWithTheme.connectionPreviewContainer}>
              <div className={classes.userIconContainer}>
                <img width="70" height="80" src={`${IMG_STORAGE_LINK}user-icon-outline.svg`} />
              </div>
              <div>
                <Typography sx={stylesWithTheme.userName}>{name}</Typography>
                <Typography sx={stylesWithTheme.subtitle}>{talentType}</Typography>
              </div>
              <Divider sx={stylesWithTheme.divider} />
              <ContactInfo
                phone={phone}
                email={email}
                address={address}
                color='#243060'
              />
              {
                !['certified-vet-tech', 'vet-assistant'].includes(talentType)
                && (
                  <ExperienceDetailCard
                    classes={classes}
                    talent={talent}
                    talentProfileItems={talentProfileItems}
                    isPreview
                    vetSchools={this.state.vetSchools}
                  />
                )
              }
              {this.renderLinkToTalentProfile()}
            </Paper>
          )
          }
        </div>
        <PingUserDialog
          open={openPingUserDialog}
          admin={admin}
          admins={admins}
          apiToken={apiToken}
          handleCloseClick={() => this.setState({ openPingUserDialog: false })}
          handleSaveClick={(e, s, a, m) => this.handleSaveClick(e, s, a, m)}
          actions={actions}
        />
        <Dialog
          open={openLeaveChat}
          onClose={e => this.setState({ openLeaveChat: false })}
        >
          <DialogContent sx={stylesWithTheme.userDialog}>
            <div className={classes.dialogClose}>
                <IconButton onClick={e => this.setState({ openLeaveChat: false })}>
                    <Icon>close</Icon>
                </IconButton>
            </div>
            <div className={classes.deleteIcon}>
                <ExitToAppOutlined style={{ fontSize: '30px' }} />
            </div>
            <Typography sx={stylesWithTheme.userDialogTitle} gutterBottom>
              LEAVE CHAT
            </Typography>
            <Typography gutterBottom>
                Are you sure you want to leave the chat?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ openLeaveChat: false });
                this.onClickLeaveButton();
              }}
              fullWidth
              variant="contained"
              sx={stylesWithTheme.deleteUserBtn}
              disableRipple
            >
              YES
            </Button>
          </DialogActions>
        </Dialog>
        {!isDesktop && this.renderLinkToTalentProfile()}
        {
          openConfirmation
          && (
            <CalendarConfirmationDialog
              open={openConfirmation}
              handleCallback={(tempConfirmedData) => {
                this.setState({
                  openConfirmation: false,
                  tempConfirmedData: tempConfirmedData.map(item => ({ ...item, isConfirmed: true })),
                }, () => this.saveDates());
              }}
              confirmTakenDates={this.state.confirmTakenDates}
              talentList={this.state.talentList || []}
              agencyList={this.state.agencyList || []}
              talentUserId={talent.user_id}
              setDefaultOnCancel
            />
          )
        }
      </Box>
    );
  }

  handleSaveClick = (admins, message) => {
    if (admins && admins.length > 0) {
      const {
        match, actions, apiToken, admin,
      } = this.props;
      const { resourceId } = match.params;
      const { messages } = this.state;
      const element = document.getElementById('messagesContainer');
      let names = admins.map(it => this.capitalizeFirstLetter(it.name));
      const hideInfo = admin.adminRole === HQ_ADMIN_KEY || (admin.isExternshipAdmin && this.state.jobPostingType === 'Externship') || this.state.jobPostingType === 'Relief';
      const payload = {
        body: `${this.capitalizeFirstLetter(admin.name)} $$has pinged$$ ${names.join(',')} to join the chat.`,
        match_id: parseInt(resourceId),
        isUnread: true,
        pingeeAdmins: admins,
        pingeeMessage: message,
        firstMsgInThreadCreatedAt: !isEmpty(messages) ? messages[0].createdAt : null,
      };
      actions.addResource(apiToken, payload, 'messages').then((res) => {
        const sentMsg = res.response;
        const info = !hideInfo && sentMsg.body && sentMsg.body.indexOf('$$has pinged$$') !== -1
          && 'Messages prior to the ping invitation are private and not visible to the pinged party.';
        messages.push({
          body: sentMsg.body,
          info,
          creator_id: sentMsg.creator_id,
          updatedAt: sentMsg.updatedAt,
          is_sender: sentMsg.creator_id !== sentMsg.talent.user_id,
          creatorName: sentMsg.creatorName,
        });
        this.setState({
          messages,
          newMessage: '',
          isLastAdminInThread: false,
        });
        element.scrollTop = element.scrollHeight;
      });
      this.setState({ openPingUserDialog: false });
    }
  };

  capitalizeFirstLetter = str => str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

Messages.propTypes = {
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
};

Messages.defaultProps = {};

function MessagesWrapper({ theme, ...rest }) {
  const isDesktop = theme ? useMediaQuery(theme.breakpoints.up('md')) : true;
  return <Messages {...rest} theme={theme} isDesktop={isDesktop} />;
}

export default withStyles(messagePageStyle, { withTheme: true })(withRouter(MessagesWrapper));
