import React from 'react';
import { Drawer, Box, IconButton, Divider, Typography, Snackbar, Alert, Grid, Tooltip, Icon } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const ERCalendarDrawer = (props) => {
  const {
    sideDrawerOpen,
    classes,
    sxStyles,
    selectedDate,
    dateText,
    filteredShiftItemsByDate,
    dateTrackingItems,
    IMG_STORAGE_LINK,
    history,
    setSideDrawerOpen,
    renderItem,
    handleDateMsgConfirm,
    admin,
    openSnackbar,
    sucessMessage,
    errorMessage,
  } = props;

  return (
    <>
      <Drawer
        className={classes.drawerNoDog}
        variant="persistent"
        anchor="right"
        open={sideDrawerOpen}
        classes={{ paper: classes.drawerPaperNoDog }}
      >
        {sideDrawerOpen && (
          <Box className={classes.rightDrawerCloseBtn}>
            <IconButton onClick={setSideDrawerOpen} size="small">
              <ChevronRightIcon style={{ fontSize: '16px' }} />
            </IconButton>
          </Box>
        )}
        <div
          className={classes.drawerHeader}
          style={{
            justifyContent: 'space-between',
            padding: '20px 10px',
            backgroundColor: '#f0f0f0', // assuming bgGray value
            display: 'flex',
          }}
        >
          <Typography color="secondary" sx={{ width: '40%' }}>
            {dateText}
          </Typography>
          <Divider sx={{ width: '60%' }} />
        </div>
        <div className={classes.drawerContainer}>
          {filteredShiftItemsByDate?.map((item, index) => {
            const dateItems = item.jobPostingCalendar.filter(x => x.date === selectedDate);
            const filterRequestDates = dateTrackingItems
              ? dateTrackingItems.filter(i => i.job_posting_id === item.id && i.status === 'requested' && i.date === selectedDate)
              : [];
            return dateItems.map((dateItem, dateIndex) => {
              const isRequested = dateItem.status === 'available' && filterRequestDates.find(i => i.date === dateItem.date);
              const requestedCountOnDate = filterRequestDates.filter(i => i.date === dateItem.date)?.length;
              return isRequested ? (
                <div style={sxStyles.requestDiv} key={`${index}_${dateIndex}_div`}>
                  {renderItem(item, dateItem, index, dateIndex, isRequested, requestedCountOnDate)}
                  {filterRequestDates.map((requestItem, requestIndex) => (
                    <Grid
                      container
                      sx={{
                        margin: '10px 0',
                        flexDirection: 'row',
                        backgroundColor: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '20px',
                        padding: '12px 20px',
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                        '& .MuiButtonBase-root': { padding: '0 4px' },
                      }}
                      key={`${requestIndex}_req_box`}
                    >
                      <Grid item xs={2} md={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Message" placement="top">
                          <IconButton
                            onClick={() => history.push(`/messages/${requestItem.match_id}`)}
                            key={`req-chat-icon-${requestIndex}`}
                            disableRipple
                          >
                            {requestItem.unreadMessagesCount > 0 && (
                              <>
                                <Icon sx={sxStyles.msgLensIcon}>lens</Icon>
                              </>
                            )}
                            <Icon sx={sxStyles.icon}>chat_bubble</Icon>
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={10} md={8} key={`${requestIndex}_req`}>
                        <Typography sx={sxStyles.requestText}>{requestItem.name}</Typography>
                      </Grid>
                      <Grid item xs={12} md={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Tooltip title="Approve" placement="top">
                          <IconButton key={`approve-btn-${requestIndex}`} onClick={() => handleDateMsgConfirm(requestItem, 'approved', item)}>
                            <CheckCircleIcon sx={{ width: '24px', height: '24px', cursor: 'pointer', color: '#00aa00' }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Decline" placement="top">
                          <IconButton key={`decline-btn-${requestIndex}`} onClick={() => handleDateMsgConfirm(requestItem, 'declined', item)}>
                            <CancelIcon sx={{ width: '24px', height: '24px', cursor: 'pointer', color: '#ff0033' }} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              ) : (
                renderItem(item, dateItem, index, dateIndex, isRequested)
              );
            });
          })}
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackbar}
        onClose={() => {}}
        autoHideDuration={6000}
      >
        <Alert variant="outlined" severity={sucessMessage ? 'success' : 'error'} style={{ backgroundColor: '#fff' }}>
          {sucessMessage || errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ERCalendarDrawer;
