import React, { Component } from 'react';
import {
  Divider,
  Icon,
  Paper,
  Typography,
  Button,
  Grid,
  Tooltip,
  ButtonBase,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { withRouter } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { IMG_STORAGE_LINK } from '../../data/Constants';
import { PracticesViewStyles } from './styles/PracticesViewStyles';
import GreenSwitch from "../subcomponents/GreenSwitch";
import {hasPermission, MANAGE_PRACTICE_PRIORITY_HOSPITAL, MANAGE_SAVED_SEARCH_PERMISSION} from "../../utils/Functions";

class PracticesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      practice: null,
      practiceImages: null,
      socialMedias: null,
      avaiShifts: 0,
      takenShifts: 0,
    };
  }

  componentDidMount = async () => {
    const { match, apiToken, actions } = this.props;
    const { resourceId } = match.params;
    await actions.getOneResource(apiToken, `practices/${resourceId}`).then((res) => {
      if (res && res.response) {
        this.setState({ priorityHospital: res.response?.isPriorityHospital });
      }
    });
    await actions.getAllResources(apiToken, 'socialmedias');
    await actions.getAllResources(apiToken, 'practiceimages', 'practice', resourceId);

    let queryParams = `reports/overview?practiceId=${resourceId}`;
    await actions.getOneResource(apiToken, queryParams).then((res) => {
      if (res && res.response) {
        const reportData = res.response;
        this.setState({
          avaiShifts: reportData.available || 0,
          takenShifts: reportData.taken || 0,
        });
      }
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { resources, match } = nextProps;
    const { resourceId } = match.params;
    const practice = resources[`practices/${resourceId}`];
    const socialMedias = resources.socialmedias && resources.socialmedias
      .filter(media => media.practice_id === Number(resourceId));

    return {
      practice,
      socialMedias,
      practiceImages: resources.practiceimages,
    };
  }

  renderHeader = () => {
    const {
      classes,
      history,
      match,
      theme,
    } = this.props;
    const { practice, avaiShifts, takenShifts } = this.state;

    if (!practice) {
      return null;
    }
    const stylesWithTheme = PracticesViewStyles(theme);

    return (
      <Grid container>
        <Grid item xs={12} md={10}>
          <div className={classes.row}>
            <div
              style={{ backgroundImage: `url(${practice?.logo || null})` }}
              className={classes.imageContainer}
            />
            <div className={classes.titleContainer}>
              <Typography sx={stylesWithTheme.title}>
                {practice?.name}
              </Typography>
              <div className={classes.container}>
                <div className={classes.iconContainer}>
                  <Icon sx={stylesWithTheme.icon}>
                    room
                  </Icon>
                  <Typography sx={stylesWithTheme.iconText}>
                    {`${practice?.location} - ${practice?.city} - ${practice?.state}`}
                  </Typography>
                </div>
                <div className={classes.contactPointContainer}>
                  {
                    practice.phoneNumber && (
                      <div className={classes.iconContainer}>
                        <Icon sx={stylesWithTheme.icon}>
                          phone
                        </Icon>
                        <Typography sx={stylesWithTheme.iconText}>
                          {practice.phoneNumber}
                        </Typography>
                      </div>
                    )
                  }
                  {
                    practice.faxNumber && (
                      <div className={classes.iconContainer}>
                        <Icon sx={stylesWithTheme.icon}>
                          print
                      </Icon>
                        <Typography sx={stylesWithTheme.iconText}>
                          {practice.faxNumber}
                        </Typography>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={2} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Tooltip title='Click to see reports'>
            <ButtonBase onClick={() => history.push(`/practices/${match.params.resourceId}/reports`)} disableRipple>
              <Grid container sx={stylesWithTheme.dashlet}>
                <Grid item xs={11}>
                  <div className={classes.dashletTitle}>
                    <span className={classes.dashletValue}>{avaiShifts}</span>
                      &nbsp;shifts offered
                  </div>
                  <div className={classes.dashletTitle}>
                    <span className={classes.dashletValue}>{takenShifts}</span>
                      &nbsp;shifts taken
                  </div>
                  <div className={classes.dashletCaption}>
                    this month
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div className={classes.dashletIcon}>
                    <DoubleArrowIcon />
                  </div>
                </Grid>
              </Grid>
            </ButtonBase>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  getLogo = (socialMedia) => {
    const { classes } = this.props;
    switch (socialMedia.toLowerCase()) {
      case 'facebook':
        return <img className={classes.image} src={`${IMG_STORAGE_LINK}facebook-logo.svg`} alt="Facebook" />;
      case 'twitter':
        return <img src={`${IMG_STORAGE_LINK}twitter.svg`} alt="Twitter" />;
      case 'instagram':
        return <img src={`${IMG_STORAGE_LINK}instagram.svg`} alt="Instagram" />;
      default:
        return <img src={`${IMG_STORAGE_LINK}youtube.svg`} alt="YouTube" />;
    }
  };

  renderSubHeader = () => {
    const {
      classes,
      admin,
      match,
      history,
      theme,
      actions,
      apiToken,
    } = this.props;
    const { practice, socialMedias } = this.state;

    if (!practice || !socialMedias) {
      return null;
    }

    const half = Math.round(socialMedias.length / 2);
    const firstColumn = socialMedias.slice(0, half);
    const secondColumn = socialMedias.slice(half, socialMedias.length);

    const stylesWithTheme = PracticesViewStyles(theme);

    return (
      <div className={classes.subHeaderContainer}>
        <div className={classes.column}>
          <div className={classes.row}>
            <div className={classes.columnContainer}>
              {
                practice.email && (
                  <div className={classes.iconContainer}>
                    <Icon sx={stylesWithTheme.icon}>
                      email
                    </Icon>
                    <Typography sx={stylesWithTheme.iconText}>
                      {practice.email}
                    </Typography>
                  </div>
                )
              }
            </div>
            <div className={classes.columnContainer}>
              {
                practice.webpage && (
                  <div className={classes.iconContainer}>
                    <Icon sx={stylesWithTheme.icon}>
                      print
                    </Icon>
                    <Typography sx={stylesWithTheme.iconText}>
                      {practice.webpage}
                    </Typography>
                  </div>
                )
              }
            </div>
          </div>
          <div>
            <Typography sx={stylesWithTheme.socialMedia}>
              Default Relief Range to Share Staff (miles)
            </Typography>
            <div className={classes.row}>
              <div className={classes.columnContainer}>
                {practice?.defaultRange}
              </div>
            </div>
          </div>
          <div>
            <Typography sx={stylesWithTheme.socialMedia}>
              Priority Hospital
            </Typography>
            <div className={classes.row}>
              <GreenSwitch
                checked={this.state.priorityHospital}
                disableRipple
                onChange={e => {
                  if (hasPermission(admin, MANAGE_PRACTICE_PRIORITY_HOSPITAL)) {
                    this.setState({ priorityHospital: e.target.checked });
                    actions.updateResource(apiToken, {
                      ...practice,
                      isPriorityHospital: e.target.checked
                    }, 'practices', practice?.id);
                  }
                }}
              />
            </div>
          </div>
          {
            socialMedias?.length > 0 && (
              <div>
                <Typography sx={stylesWithTheme.socialMedia}>
                  SOCIAL MEDIA
                </Typography>
                <div className={classes.row}>
                  <div className={classes.columnContainer}>
                  {
                      firstColumn?.map(socialMedia => (
                        <div key={socialMedia.id} className={classes.iconContainer}>
                          <Icon sx={stylesWithTheme.icon}>
                            {this.getLogo(socialMedia.socialMedia)}
                          </Icon>
                          <Typography sx={stylesWithTheme.iconText}>
                            {socialMedia.url}
                          </Typography>
                        </div>
                      ))
                    }
                  </div>
                  <div className={classes.columnContainer}>
                    {
                      secondColumn?.map(socialMedia => (
                        <div key={socialMedia.id} className={classes.iconContainer}>
                          <Icon sx={stylesWithTheme.icon}>
                            {this.getLogo(socialMedia.socialMedia)}
                          </Icon>
                          <Typography sx={stylesWithTheme.iconText}>
                            {socialMedia.url}
                          </Typography>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            )
          }
        </div>
        <div className={classes.column}>
          {admin?.isShiftAdmin && (
          <div className={classes.row} style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button
              sx={stylesWithTheme.reportButton}
              onClick={() => {
                history.push(`/regionalneeds/${match.params.resourceId}`);
              }}
            >
              Show Regional Needs
            </Button>
          </div>
          )}
          <div className={classes.row} style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button
              sx={stylesWithTheme.reportButton}
              onClick={() => {
                history.push(`/fastpassstaff/${match.params.resourceId}`);
              }}
            >
              FastPass Staff
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderImageCarousel = () => {
    const { classes } = this.props;
    const { practiceImages } = this.state;

    if (!practiceImages) {
      return null;
    }

    return (
      <div
        className={classes.carouselContainer}
        style={practiceImages?.length === 0 ? { display: 'flex' } : null}
      >
        {
          practiceImages?.length === 0
            ? <Typography style={{ margin: 'auto' }}>No Practice Image</Typography>
            : (
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlay={false}
                centerMode={false}
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite={false}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderDotsOutside
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 1,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 1,
                  },
                }}
                showDots
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                {
                  practiceImages.map(image => (
                    <img
                      key={image.id}
                      src={image.image}
                      alt="image"
                      className={classes.practiceImage}
                    />
                  ))
                }
              </Carousel>
            )
        }
      </div>
    );
  };

  renderDescription = () => {
    const { practice } = this.state;
    if (!practice) {
      return null;
    }
    return (
      <div dangerouslySetInnerHTML={{ __html: practice.description }} />
    );
  };

  render() {
    const {
      classes,
      history,
      match,
      theme,
    } = this.props;
    const stylesWithTheme = PracticesViewStyles(theme);

    return (
      <div className={classes.container}>
        <div className={classes.resourceHeader}>
          <div className={classes.resourceNameLarge}>
            <Typography sx={stylesWithTheme.resourceNameLarge}>
              PRACTICE DETAILS
            </Typography>
            <Button
              sx={stylesWithTheme.headerButton}
              onClick={() => history.push(`/editpractice/${match.params.resourceId}`)}
            >
              EDIT
            </Button>
          </div>
        </div>

        <Paper sx={stylesWithTheme.paper}>
          <div className={classes.container}>
            {this.renderHeader()}
            {this.renderSubHeader()}
          </div>
          <Divider style={{ height: '2px' }} />
          <div style={{ marginTop: '50px' }}>
            {this.renderImageCarousel()}
            {this.renderDescription()}
          </div>
        </Paper>
      </div>
    );
  }
}

export default withStyles(PracticesViewStyles, { withTheme: true })(withRouter(PracticesView));
